.#{$ns}dropdown-with-groups {
    label {
        width: 100%;
    }
}
.multiselect {
    position: relative;
    width: 100%;
    height: 30px;
    box-sizing: border-box;
    border: 1px solid $color-gray3;
    background-color: $color-white;
    border-radius: 15px;
    @include respond(small) {
        height: 40px;
        border-radius: 20px;
    }

    &--active {
        z-index: 2;
    }

    &__select {
        position: absolute;
        display: inline-block;
        width: 100%;
        height: 100%;

        &:before {
            content: ' ';
            position: absolute;
            right: 15px;
            top: calc(50% - 7px);
            box-sizing: border-box;
            height: 10px;
            width: 10px;
            border-width: 2px 2px 0 0;
            border-style: solid;
            -webkit-transform: rotate(315deg);
            transform: rotate(133deg);
            transition: all 0.15s cubic-bezier(1, -0.115, 0.975, 0.855);
            transition-timing-function: cubic-bezier(1, -0.115, 0.975, 0.855);
            color: $dropdown-tag-color;
        }
    }
    &--active > &__select {
        &:before {
            top: 50%;
            -webkit-transform: rotate(315deg);
            transform: rotate(315deg);
        }
    }

    &__tags,
    &__placeholder {
        max-width: 100%;
        margin: 2px 1px 2px 0;
        padding-left: 10px;
        padding-right: 30px;
        line-height: 25px;
        font-family: $body-font-family;
        font-size: 14px;
        font-weight: 400;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: $form-input-color;
        pointer-events: none;

        @include respond(small) {
            line-height: 33px;
            font-size: 16px;
        }

        input {
            height: 100%;
            border: none;
            padding: 0 0 0 10px;

            &:focus {
                outline: none;
                &::placeholder {
                    color: transparent;
                    opacity: 1;
                }
            }
        }
    }

    &__content {
        display: inline-block;
        width: 100%;
        max-height: 500px;
        border: 1px $color-gray3 solid;
        border-radius: 15px;
        overflow-y: auto;
        background-color: $color-white;

        @include respond(small) {
            margin-top: 2px;
            border-radius: 20px;
        }
    }
    &__option {
        display: block;
        height: 100%;
        padding-top: 4px;
        padding-bottom: 4px;
        padding-left: 23px;
        font-family: $body-font-family;
        font-size: 14px;
        font-weight: 400;
        white-space: normal;
        color: $dropdown-tag-color;

        @include respond(small) {
            max-width: 100%;
            font-size: 16px;
            white-space: normal;
        }
        &:hover {
            cursor: pointer;
            background-color: $dropdown-highlighted-tag-background-color;
            color: $color-white;
        }
        &--highlight {
            background-color: $dropdown-highlighted-tag-background-color;
            color: $color-white;
        }
        &--selected,
        &--highlight.multiselect__option--selected {
            background-color: transparent;
            color: $dropdown-active-tag-color;
        }
    }
    &__option.multiselect__option--group {
        padding-left: 15px;
        font-weight: bold;
        cursor: default;
        color: $dropdown-tag-color;

        &:hover {
            cursor: default;
            background-color: transparent;
        }
    }
}
