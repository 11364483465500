@import '../settings';

.ag-theme-alpine {
    font-family: $body-font-family !important;
    font-weight: $font-weight-bold;
    color: $color-gray4;

    .ag-cell-focus,
    .ag-cell {
        border: none !important;
    }

    .ag-cell-value:has(.ag-checkbox),
    .ag-cell-value:has(.cm-radio) {
        display: flex;
        height: 36px;
        justify-content: center;
        align-items: center;
    }

    .ag-checkbox {
        position: relative;
        padding: 0;
        .o-ci {
            &::after {
                position: absolute;
                width: 15px;
                height: 15px;
                top: 0;
                left: 0;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            &::before {
                position: absolute;
                top: 0;
            }
        }
    }
    .cm-radio {
        position: relative;
        padding: 0;
        .o-ci {
            &::before {
                position: absolute;
                top: 0;
            }
        }
    }

    .ag-header-cell {
        &-comp-wrapper:has(.ag-checkbox) {
            display: flex;
            height: 36px;
            justify-content: center;
            align-items: center;

            .ag-checkbox {
                position: relative;
                padding: 0;
                .o-ci {
                    &::after {
                        position: absolute;
                        width: 15px;
                        height: 15px;
                        top: 0;
                        left: 0;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }

                    &::before {
                        position: absolute;
                        top: 0;
                    }
                }
            }
        }
        &-menu-button {
            color: $link-color;
            &:hover {
                color: $link-hover-color;
            }
        }
    }

    .ag-header-row:not(:first-child) .ag-header-cell:not(.ag-header-span-height.ag-header-span-total),
    .ag-header-row:not(:first-child) .ag-header-group-cell.ag-header-group-cell-with-group {
        border: $table-dark;
    }

    .ag-root-wrapper {
        border: none;
    }

    .ag-root-wrapper-body {
        border: 1px solid $table-dark;
    }

    .ag-header-group-cell.ag-header-group-cell-with-group,
    .ag-header-group-cell.ag-header-group-cell-with-group:hover {
        background-color: $table-dark !important;
    }

    .ag-header,
    .ag-advanced-filter-header {
        background-color: $color-white;
        border-bottom: 1px solid $table-dark;
    }

    .ag-header-cell:has(.o-checkbox),
    .ag-header-group-cell:has(.o-checkbox) {
        padding: 0;
    }

    .cm-tooltip__button {
        color: $link-color;

        &:hover {
            color: $link-hover-color;
        }
    }

    .ag-paging-panel {
        border-top: none;
    }

    .ag-body-viewport {
        background: none;
        .ag-row {
            //background-color: $table-tr-stripped-color-even;

            &.ag-row-odd {
                background-color: $table-tr-stripped-color-odd !important;
            }

            &.ag-row-even {
                background-color: $table-tr-stripped-color-even !important;
            }

            &:before {
                background-color: $table-td-hover-background;
            }
            &.is-clickable {
                cursor: pointer;
            }

            .ag-cell {
                font-size: size($font-size-base);
                button {
                    background: none;
                    border: none;
                    padding: 0;
                    color: $link-color;
                    text-decoration: underline;
                    outline-offset: -2px;
                    &:not(:first-child) {
                        margin-left: 20px;
                    }
                    &:hover {
                        color: $link-hover-color;
                    }
                }
            }
        }
    }

    .ag-filter .ag-set-filter-list {
        height: auto;
        max-height: 200px;
    }
    .ag-filter {
        z-index: 100;
        input[type='checkbox'] {
            mix-blend-mode: luminosity;
            display: block;
        }
        &-header-container {
            border-bottom: 1px solid darkgrey;
            padding-bottom: 2px;
        }
        &-value {
            margin-left: 5px;
        }
    }

    .ag-header-select-all-container {
        flex-direction: column;
    }
}

// Fixing unclickable input in filters

.ag-radio-button-input-wrapper > .ag-radio-button-input {
    display: block;
    z-index: 10;
}

.ag-radio-button-input {
    accent-color: $custom-radio-checked-background;
    cursor: pointer;
}

.ag-checkbox-input {
    accent-color: $custom-checkbox-icon-checked-color;
    cursor: pointer;
}

.ag-limit-height {
    max-height: calc(100vh - 64px);
}

.periodic-col {
    color: #3f39b2;
    .g-icon--arrow_down,
    .g-icon--arrow_up {
        padding-right: 5px;
    }
}
