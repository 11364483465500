.#{$ns}filters {
    $self: &;

    a {
        text-decoration: none;
        color: $color-white;
        outline: none;
    }

    .expander__trigger {
        border: unset;
        background: unset;
        padding: unset;
    }

    &__content {
        padding: 0 0 10px;
    }

    &__header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        padding: 20px 20px 0;
    }

    &__title {
        margin-bottom: 20px;

        &--no-data {
            margin-top: 50px;
        }
    }

    &__arrow-down {
        margin-right: 13px;
    }

    &__select-actions {
        padding-left: 25px;
        margin-top: 15px;
        display: flex;
        align-items: center;

        &__item + &__item {
            margin-left: 10px;
        }
    }

    &__button {
        &--action {
            float: right;
            @include respond-gte(large) {
                display: none;
                .g-icon--edit,
                .g-icon--close {
                    &:before {
                        font-size: 10px;
                    }
                }
            }
        }
    }

    &__field {
        padding-left: 25px;
        margin: 10px 0 20px;
    }

    &__item {
        display: block;
        font-weight: $font-weight-normal;

        span {
            color: $sidebar-color;
        }

        & + & {
            margin-top: 15px;
        }

        @include respond(small) {
            & + & {
                margin-top: 25px;
            }
        }

        &--single {
            margin-right: 0;
            @include respond-gte(medium) {
                float: left;
                width: 50%;
                box-sizing: border-box;
                padding-right: 20px;
                & + & {
                    margin-top: 0;
                }
                &:nth-child(n + 3) {
                    margin-top: 20px;
                }
                &:nth-child(2n + 1) {
                    clear: both;
                }
            }
        }
    }

    &__group {
        padding: 10px 20px 15px;
        margin-bottom: 5px;

        &.is-expanded {
            background-color: $sidebar-roll-indicator-background;
        }

        .item-header:not(.handle) {
            .cm-filters__button--action {
                display: none;
            }
        }

        .handle:hover {
            .cm-filters__button--action {
                display: block;
            }
        }
    }

    &__box {
        margin: 20px 0 30px;
        padding: 0 20px;

        &__head {
            display: flex;
            justify-content: space-between;
            align-items: baseline;
            margin-bottom: 20px;

            #{$self}__title {
                margin-bottom: 0;
            }
        }
    }

    &__list__content {
        overflow: hidden;
        word-break: break-all;
        padding: 3px 0 3px 3px;
    }

    &__list {
        margin-top: 15px;
        padding-left: 25px;
        max-height: 211px;
        clear: both;
        box-sizing: border-box;
        margin-left: -3px;
        overflow: auto;
        @include scrollbar($sidebar-color, transparent, 4px, 2px);
        @include respond(medium) {
            @include clearfix;
        }
        @include respond(small) {
            max-height: 375px;
        }
    }

    &__toggle-group {
        @include respond-gte(large) {
            position: sticky;
            top: 20px;
            z-index: 1;
            margin-top: -40px;
        }

        &--mobile {
            position: fixed;
            width: 100%;
            height: 70px;
            padding: 0 20px;
            box-sizing: border-box;
            bottom: 0;
            left: 0;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            z-index: 7;
            white-space: nowrap;
            box-shadow: (0 -4px 8px rgba(23, 44, 54, 0.15));
            background-color: $color-white;
        }

        .cm-orb--m .cm-orb__inner--icon::before {
            font-size: 18px;
        }
    }

    &__toggle-group-wrapper {
        @include respond-gte(large) {
            position: absolute;
            right: 20px;

            &--filter-lock {
                right: 500px;
            }
        }
    }

    &__toggle-button {
        margin-left: 10px;
        display: inline-block;
    }

    &--master {
        padding-top: 0;
    }
}
