/*
 * hy.frontend.core
 *
 * NOTE: this file is not subject to copyright transfer
 *
 * UWAGA: plik nie objęty przekazaniem praw autorskich
 *
 *
 */





/*------------------------------------*\
    #COLORS
\*------------------------------------*/

////
/// COLORS
/// @group colors
/// @author marcin.krasowski
////

/// enable generating color classes
$enable-color-classes: true !default;

/// Defines default white color
$color-white:  #fff !default;
/// Defines default black color
$color-black:  #000 !default;

/// Defines first gray color
$color-gray1: #ccc !default;
/// Defines second gray color
$color-gray2: #aaa !default;
/// Defines third gray color
$color-gray3: #4e4e50 !default;

/// Defines first brand color
$color-brand1: $color-gray1 !default;
/// Defines second brand color
$color-brand2: $color-gray2 !default;
/// Defines third brand color
$color-brand3: $color-gray3 !default;

/// Defines info color
$color-info:  #189dba !default;
/// Defines alert color
$color-alert: #c74a00 !default;
/// Defines error color
$color-error: #b81717 !default;
/// Defines success color
$color-success: #167940 !default;

/// outline color for focused element
$acc-color: $color-brand1 !default;

/// List with colors and shorthands, in order of:
/// * name
/// * shorthand
/// * color
/// * opacity
/// @see {function} get-color-type
$color-array: white1 c  $color-white 1,
              white1 bg $color-white 1
              !default;

/// Default color array with some base colors
$color-array-default: white1 c  $color-white 1,
              white1 bg $color-white 1,
              black1 c  $color-black 1,
              black1 bg $color-black 1;


/// translates a shorthand to corresponding style:
/// * **bg** into **background**
/// * **bgc** into **background-color**
/// * **c** into **color**
/// @param {String} $type - shorthand3
/// @see $color-array
@function get-color-type($type) {
    $style: '';
    @if $type == bg {
        $style: background;
    }
    @else if $type == bgc {
        $style: background-color;
    }
    @else if $type == c {
        $style: color;
    }
    @return $style;
}

/// generates classes for settings color/background/background-color
/// @param {List} $table [$color-table] - list with colors and shorthands
@mixin generate-colors($array: $color-array) {
    @if $array != '' {
        $style: '';

        // for each color set
        @each $color-set in $array {

            // get property type
            $style: get-color-type(nth($color-set, 2));

            // generate property
            @if $style != '' {
                $name: '' + nth($color-set, 1);
                $shorthand: nth($color-set, 2);
                $color: nth($color-set, 3);
                $opacity: 1;

                @if length($color-set) > 3 {
                    $opacity: nth($color-set, 4);
                }

                // extendable class %COLOR_NAME-TYPE
                %#{$name}-#{$shorthand} {
                    #{$style}: rgba($color, $opacity) !important;
                }

                // css classes
                @if $enable-color-classes == true {
                    .g-#{$name}-#{$shorthand} {
                        @extend %#{$name}-#{$shorthand};
                    }
                }
            }
        }

    }
}

@include generate-colors($color-array-default);
@include generate-colors;
