/*
 * hy.frontend.core
 * 
 * NOTE: this file is not subject to copyright transfer
 * 
 * UWAGA: plik nie objęty przekazaniem praw autorskich
 * 
 * 
 */





/*------------------------------------*\
    #Buttons
\*------------------------------------*/
////
/// @group buttons
/// @author marcin.krasowski
////





/*------------------------------------*\
    NORMAL STATE
\*------------------------------------*/

/// Defines button font size
$button-font-size:              $font-size-base !default;
/// Defines button font weight
$button-font-weight:            normal       !default;
/// Defines button background color
$button-background:             $color-white !default;
/// Defines button text color
$button-color:                  $body-color  !default;
/// Defines transparent button text color
$button-transparent-color:      $link-color  !default;
/// Defines button text align
$button-text-align:             center       !default;
/// Defines button border width
$button-border-width:           1            !default;
/// Defines button border color
$button-border-color:           $color-black !default;
/// Defines button border radius
$button-border-radius:          3            !default;
/// Defines button shadow
$button-shadow:                 none         !default;
/// Defines button paddings
$button-padding:                10 10        !default;
/// Defines button height
$button-height:                 auto         !default;
/// Defines button width
$button-width:                  auto         !default;
/// Defines transparent button width
$button-transparent-width:      auto         !default;
/// Defines button line height
$button-line-height:            inherit     !default;

/*------------------------------------*\
    DISABLED STATE
\*------------------------------------*/

/// Defines disabled button background color
$button-disabled-background:        darken($button-background, 5%) !default;
/// Defines disabled button text color
$button-disabled-color:             lighten($button-color, 50%)    !default;
/// Defines disabled transparent button text color
$button-transparent-disabled-color: lighten($button-color, 50%)    !default;
/// Defines disabled button border width
$button-disabled-border-width:      $button-border-width !default;
/// Defines disabled button border color
$button-disabled-border-color:      $button-border-color !default;

/*------------------------------------*\
    HOVER STATE
\*------------------------------------*/

/// Defines button on hover background color
$button-hover-background:           $button-background   !default;
/// Defines button on hover text color
$button-hover-color:                $button-color        !default;
/// Defines transparent button on hover text color
$button-transparent-hover-color:    $link-hover-color    !default;
/// Defines button on hover border width
$button-hover-border-width:         $button-border-width !default;
/// Defines button on hover border color
$button-hover-border-color:         $button-border-color !default;

/*------------------------------------*\
    FOCUS STATE
\*------------------------------------*/

/// Defines focused button background color
$button-focus-background:           $button-hover-background   !default;
/// Defines focused button text color
$button-focus-color:                $button-hover-color        !default;
/// Defines focused transparent button text color
$button-transparent-focus-color:    $button-hover-color        !default;
/// Defines focused button border width
$button-focus-border-width:         $button-hover-border-width !default;
/// Defines focused button border color
$button-focus-border-color:         $button-hover-border-color !default;





/// Changes button style for different states
/// @param {String} $background - background color
/// @param {String} $color - text color
/// @param {Number} $border-width - border width
/// @param {String} $border-color - border color
@mixin change-button-style($background: $button-background, $color: $button-color, $border-width: $button-border-width, $border-color: $button-border-color) {
    background: $background;
    color: $color;
    
    @if $border-width != $button-border-width {
        border-width: size($border-width);
    }
    @if $border-color != $button-border-color {
        border-color: $border-color;
    }
}





/*------------------------------------*\
    ELEMENT STATES
\*------------------------------------*/

%button-hover {
    @include change-button-style(
        $button-hover-background,
        $button-hover-color,
        $button-hover-border-width,
        $button-hover-border-color
    );
}

%button-disabled {
    @include change-button-style(
        $button-disabled-background,
        $button-disabled-color,
        $button-disabled-border-width,
        $button-disabled-border-color
    );
}

%button-focus {
    @include change-button-style(
        $button-focus-background,
        $button-focus-color,
        $button-focus-border-width,
        $button-focus-border-color
    );
}





.o-btn {
    display: inline-block;
    font-size: size($button-font-size, $rel-unit);
    font-weight: $button-font-weight;
    color: $button-color;
    text-align: $button-text-align;
    text-decoration: none;
    background: $button-background;
    border: size($button-border-width) solid $button-border-color;
    border-radius: size($button-border-radius);
    box-shadow: $button-shadow;
    padding: spacing($button-padding);
    width: $button-width;
    min-height: size($button-height);
    box-sizing: border-box;
    vertical-align: middle;
    @include transition-standard-fast(background, color, border-color);
    @if ($button-line-height == inherit or $button-line-height == normal or $button-line-height == initial) {
        line-height: $button-line-height;
    }
    @else {
        line-height: size($button-line-height, $rel-unit);
    }

    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;

   // @include transition-standard-fast(background, color);

    @include placeholder() {
        color: gray;
        font-style: italic;
        font-weight: lighter;
    }

    //&:not(.o-btn--transparent) {
        
    &:hover, &:active  {
        @extend %button-hover;
        text-decoration: none;
    }

    &:disabled {
        @extend %button-disabled;
    }

    &:focus {
        @extend %button-focus;
        text-decoration: none;

        // if radius is big enough, outline (without radius) starts to be separate from border
        // so we disable it and instead border-color has to change
        @if($button-border-radius > 10) {
            outline: none !important;
        }
        
        &:hover {
            @extend %button-hover;
        }
    }
    //}
    
    &--transparent {
        width: $button-transparent-width;
        border-color: transparent;
        border-left: none;
        border-right: none;
        background: none;
        color: $button-transparent-color;
        padding-left: 0;
        padding-right: 0;
        box-shadow: none;

        &:hover, &:active  {
            color: $button-transparent-hover-color;
            background: none;
            border-color: transparent;
        }

        &:disabled {
            color: $button-transparent-disabled-color;
            background: none;
            border-color: transparent;
        }

        &:focus {
            color: $button-transparent-focus-color;
            background: none;
            border-color: transparent;
            
            &:hover {
                color: $button-transparent-hover-color;
                background: none;
                border-color: transparent;
            }
        }
    }

    &--link {
        width: auto;
        border: none;
        background: none;
        padding: 0;
        color: $link-color;
        text-decoration: $link-decoration;
        font-weight: $link-weight;
        font-size: size($font-size-base, $rel-unit);
        box-shadow: none;

        &:hover, &:active  {
            color: $link-hover-color;
            text-decoration: $link-hover-decoration;
            background: none;
            border: none;
        }

        &:disabled {
            color: $button-transparent-disabled-color;
            background: none;
            border: none;
        }

        &:focus {
            color: $link-color;
            background: none;
            border: none;
            text-decoration: $link-decoration;

            &:hover {
                color: $link-hover-color;
                text-decoration: $link-hover-decoration;
                background: none;
                border: none;
            }
        }
    }
}

/*------------------------------------*\
    WRAPPED BUTTONS
\*------------------------------------*/

.o-btn__wrapper {
        display: table;
        margin: 0 auto;
        &--text {
            display: table-cell;
            height: 40px;
            vertical-align: middle;
        }
    }

