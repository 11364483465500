.#{$ns}btn {
    &--secondary {
        &.o-btn {
            @include change-button-style(
                    $color-white,
                    $button-secondary-color,
                    2,
                    $button-secondary-hover-background-color
            );

            &:hover,
            &:active,
            &:focus {
                color: $color-white;
                background: $button-secondary-hover-background-color;
                border-color: $button-secondary-hover-background-color;
            }

            &:disabled {
                color: $button-secondary-disabled-color;
                background-color: $button-disabled-background;
                border-color: $button-disabled-background;

                &:hover,
                &:active,
                &:focus {
                    color: $button-secondary-disabled-hover;
                    background-color: $button-disabled-background;
                    border-color: $button-disabled-background;
                }
            }
        }
    }

    &--third {
        &.o-btn {
            @include change-button-style(
                    $button-third-background-color,
                    $button-third-color,
                    2,
                    $button-third-hover-background-color
            );

            &:hover,
            &:active,
            &:focus {
                color: $button-third-color-hover;
                background: $button-third-hover-background-color;
                border-color: $button-third-hover-background-color;
            }

            &:disabled {
                color: $button-third-disabled-color;
                background-color: $color-gray3;
                border-color: $color-gray3;

                &:hover,
                &:active,
                &:focus {
                    color: $button-third-disabled-hover;
                    background-color: $color-gray3;
                    border-color: $color-gray3;
                }
            }
        }
    }

    &--border {
        &.o-btn {
            @include change-button-style(transparent, white, 1, white);

            &:hover,
            &:active,
            &:focus {
                color: $body-color;
                background: white;
            }

            &:disabled {
                color: $color-gray3;
                border-color: $color-gray3;

                &:hover,
                &:active,
                &:focus {
                    color: $color-gray3;
                    background-color: transparent;
                    border-color: $color-gray3;
                }
            }
        }
    }
}

.o-btn {
    line-height: 20px;
    font-family: $button-font-family;

    &:not(&--link):not(.cm-btn--secondary):not(:disabled):not(.cm-btn--third):not(.cm-btn--border) {
        color: $primary-button-color;

        &:hover {
            color: $primary-button-color;
        }
    }

    &:not(&--link):not(.cm-btn--secondary):not(:disabled):not(.cm-btn--third).o-btn--transparent {
        color: $body-color;

        &:hover {
            color: $body-color;
        }
    }

    @include respond-gt(small) {
        max-width: 240px;
        &--link {
            max-width: 999em;
        }
        .#{$ns}subfilters__field & {
            max-width: 999em;

            .u-right & {
                margin-top: 0;
            }
        }
    }
    @include respond(small) {
        &:not(.o-btn--link) {
            font-size: 16px;
            height: 40px;
            border-radius: 20px;
        }
    }

    &.btn-short {
        line-height: 20px;
        padding: 3px 10px;
        max-height: 30px;
        @include respond-gt(small) {
            white-space: nowrap;
        }
    }
    &--auto {
        width: auto;
        max-width: 99em;
    }
    &--min-240 {
        min-width: 240px;
    }
}
