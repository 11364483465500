.#{$ns}trace-file {
    &__filters {
        display: flex;
        flex-direction: row;

        @include respond-lte(small) {
            flex-direction: column;
            margin-bottom: 20px;
        }
    }
}
