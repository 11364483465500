/*------------------------------------*\
    #NAMESPACES
\*------------------------------------*/

$ns: 'cm-';

/*------------------------------------*\
    #HELPERS
\*------------------------------------*/

$u-space: 20px;

/*------------------------------------*\
    #LAYOUT
\*------------------------------------*/

$page-width: 999em;
$grid-columns: 12;

$grid-gutter: 10px;
$page-padding: 30px;
$page-padding-medium: 30px;
$page-padding-small: 20px;

/*------------------------------------*\
    #RWD
\*------------------------------------*/
$decobar-width: 24px;

$rwd-breakpoint-large: 1920px;
$rwd-breakpoint-medium: 1280px;
$rwd-breakpoint-small: 768px;

/*------------------------------------*\
    #COLORS
\*------------------------------------*/

// base colors
$color-white: #fff;
$color-black: #000;

// red colors
$color-red: #ce2020;
$color-red2: #ea7676;

//orange colors
$color-orange: #ff6700;

// green colors
$color-green: #4dbd5a;

//blue colors
$color-blue1: #1c343f;
$color-blue2: #325565;
$color-blue3: #286887;
$color-blue4: #5ea1c1;
$color-blue5: #bac2d7;
$color-blue6: #dce7eb;
$color-blue7: #f6f9fa;
$color-blue8: #172c36;
$color-blue9: #edf3f5;

// yellow colors
$color-yellow1: #ffd30f;
$color-yellow2: #d1aa0a;
$color-yellow3: #f4c030;

// gray colors
$color-gray1: #f6f6f6;
$color-gray2: #e8e8e8;
$color-gray3: #ccc;
$color-gray4: #575757;
$color-gray5: #bbbfc1;
$color-gray6: #999;
$color-gray7: #d6d6d6;
$color-gray8: #e7e7e7;
$color-gray9: #f5f5f5;

// brand colors
$color-custom-1: #D10077;
$color-custom-2: #F0EEEC;
$color-custom-3: #0D121E;

@import 'variables';

$color-info: $color-third;
$color-alert: $color-third;
$color-error: $color-error;
$color-success: $color-third;

// colors with CSS classes
$color-array: brand1 c $color-first 1, brand1 bg $color-first 1, brand2 c $color-third 1, brand2 bg $color-custom-1 1,
brand5 c $color-error 1, brand5 bg $color-error 1, brand6 c $color-positive 1, brand6 bg $color-positive 1,
brand7 bg $color-links-on-white 1, white c $color-white 1, white bg $color-white 1, green1 c $color-green 1,
green1 bg $color-green 1, orange1 c $color-orange 1, orange1 bg $color-orange 1, red1 c $color-error 1,
red1 bg $color-error 1, gray5 c $color-gray5 1, gray4 c $color-gray4 1, gray6 c $color-gray6 1,
gray7 bg $color-gray7 1, gray8 bg $color-gray8 1, gray9 bg $color-gray9 1, blue3 c $color-links-on-white 1,
blue3 bg $color-links-on-white 1, blue4 c $color-menu-2nd-lvl-hover 1, blue4 bg $color-menu-2nd-lvl-hover 1,
    // custom colors with CSS classes
custom1 bg $color-first,
custom2 c $color-white;

$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 700;

$acc-color: $color-menu-2nd-lvl-hover;
$acc-width: 2;
$acc-offset: 1;
$body-color: $color-first;
$link-color: $color-links-on-white;
$link-hover-color: $color-menu-3rd-lvl-hover;
$link-hover-color-secondary: $color-white;
$link-weight: $font-weight-bold;
$link-decoration: underline;

/*------------------------------------*\
    #TYPOGRAPHY
\*------------------------------------*/

$body-font-family: 'Nunito', Arial, sans-serif;
$heading-font-family: $body-font-family;
$heading-font-weight: $font-weight-bold;

$font-size-base: 12.6;
$ms-ratio: 1.14;

$h1-scale: 8;
$h1-scale-small: 6;
$h2-scale: 6;
$h2-scale-small: 4;
$h3-scale: 4;
$h3-scale-small: 3;
$h4-scale: 2;
$h4-scale-small: 2;
$h5-scale: 1;
$h5-scale-small: 1;

$small-scale: -1;
$margin-bottom-scale: -1;

/*------------------------------------*\
    #FONTS
\*------------------------------------*/

$custom-font-regular: 'Nunito';
$custom-font-bold: 'Nunito';
$custom-font-regular-italic: 'Nunito';
$custom-font-bold-italic: 'Nunito';

$custom-fonts: $custom-font-regular 'Nunito-Regular' 400 normal cf_regular,
$custom-font-regular-italic 'Nunito-Italic' 400 italic cf_regularitalic,
$custom-font-bold 'Nunito-Bold' 700 normal cf_xbold,
$custom-font-bold-italic 'Nunito-BoldItalic' 700 italic cf_bolditalic;

$font-face-svg: false;
$font-face-ttf: false;
$font-face-eot: false;

$custom-font-path: '../assets/fonts/web/';

/*------------------------------------*\
    #ICONS
\*------------------------------------*/
$icon-xxsmall-size: 7;
$icon-xsmall-size: 10;
$icon-small-size: 12;
$icon-medium-size: 14;
$icon-size: 20;

/*------------------------------------*\
    #OFF-CANVAS
\*------------------------------------*/

$off-canvas-margin-horizontal: 0;
$off-canvas-background: $color-first;
$off-canvas-max-width: 1280px;

/*------------------------------------*\
    CUSTOM CHECKBOX
\*------------------------------------*/

$custom-checkbox-height: 15;
$custom-checkbox-width: 15;
$custom-checkbox-tick: 'checkmark';
$custom-checkbox-tick-size: 9px;
$custom-checkbox-tick-offset-top: 3;
$custom-checkbox-tick-offset-left: 3;
$custom-checkbox-tick-color: $color-first;
$custom-checkbox-border-color: $body-color;
$custom-checkbox-focus-border-color: $color-third;

$custom-checkbox-icon-checked-color: $color-second;
$custom-checkbox-icon-unchecked-color: $color-third;
$custom-checkbox-checked-border-color: $color-first;
$custom-checkbox-any-child-color: $color-menu-2nd-lvl-hover;

$custom-checkbox-canvas-unchecked-background-color: $color-white;
$custom-checkbox-canvas-unchecked-border-color: $color-white;
$custom-checkbox-canvas-checked-border-color: $color-custom-1;
$custom-checkbox-canvas-checked-background-color: $color-custom-1;
$custom-checkbox-canvas-tick-color: $color-first;

$custom-checkbox-light-unchecked-background-color: $color-white;
$custom-checkbox-light-unchecked-border-color: $color-white;
$custom-checkbox-light-checked-border-color: $color-custom-1;
$custom-checkbox-light-checked-background-color: $color-custom-1;
$custom-checkbox-light-tick-color: $color-white;

/*------------------------------------*\
    CUSTOM RADIO
\*------------------------------------*/

$custom-radio-height: 15;
$custom-radio-width: 15;
$custom-radio-border-color: $color-third;
$custom-radio-tick-size: 5;
$custom-radio-border-width: 1;
$custom-radio-checked-border: $color-custom-1;
$custom-radio-checked-background: $color-custom-1;
$custom-radio-default-checked-background: $color-links-on-white;

/*------------------------------------*\
    RADIO
\*------------------------------------*/

$radio-color: $color-first;

/*------------------------------------*\
    #FORMS
\*------------------------------------*/

$form-input-font-weight: $font-weight-normal;
$form-input-font-size: 14;
$form-input-color: $body-color;
$form-input-border-color: $color-third;
$form-input-border-radius: 15;
$form-input-padding: 5 10 4 10;
$form-input-margin: 0;
$form-input-margin-medium: 0;
$form-input-margin-small: 0;
$form-input-readonly-color: $color-third;
$form-input-disabled-background: $color-table-dark;
$form-input-disabled-color: $color-third;

/*------------------------------------*\
        LABELS
\*------------------------------------*/

$form-label-font-weight: $font-weight-bold;

/*------------------------------------*\
    PLACEHOLDERS
\*------------------------------------*/

$form-placeholder-font-style: normal;

/*------------------------------------*\
    BUTTONS
\*------------------------------------*/
$button-width: 100%;
$button-font-family: $body-font-family;
$button-background: $color-menu-3rd-lvl-hover;
$button-border-width: 2;
$button-border-color: $color-menu-3rd-lvl-hover;
$button-border-radius: 30px;
$button-padding: 3 30;
$button-font-weight: $font-weight-bold;
$button-font-size: 14px;
$button-hover-background: $color-menu-2nd-lvl-hover;
$button-hover-border-color: $color-menu-2nd-lvl-hover;
$primary-button-color: $color-white;
$button-secondary-color: $color-first;
$button-secondary-hover-background-color: $color-first;
$button-secondary-disabled-color: $color-third;
$button-secondary-disabled-hover: $color-third;
$button-third-color: $color-custom-3;
$button-third-color-hover: $color-custom-3;
$button-third-background-color: $color-custom-2;
$button-third-hover-background-color: $color-third-hover;
$button-third-disabled-color: $color-third;
$button-third-disabled-hover: $color-third;

$button-disabled-background: $color-table-dark;
$button-disabled-color: $color-third;
$button-disabled-border-color: $color-grey-elements;

/*------------------------------------*\
    #SIGN IN PAGE
    for page with default theme and custom sign in page
\*------------------------------------*/

$sign-in-button-background: $button-background;
$sign-in-button-border-color: $button-border-color;
$sign-in-button-hover-background: $button-hover-background;
$sign-in-button-hover-border-color: $button-hover-border-color;
$sign-in-background-opacity: 0.35;

/*------------------------------------*\
    SEPARATOR
\*------------------------------------*/

$o-separator-color: $color-third;
$o-separator-spacing: size(30) 0;
$o-separator-size--xs: 1;
$separator-brand1-border-color: $color-first;

/*------------------------------------*\
    CONTEXT MESSAGE
\*------------------------------------*/

$msg-font-weight: $font-weight-bold;
$msg-margin: size(5) 0 0 0;
$error-text-color: $color-error;

/*------------------------------------*\
    MODAL
\*------------------------------------*/

$modal-bg-color: $color-white;
$modal-width: 50vw;
$modal-width-l: 1200px;
$modal-width-xs: 300px;

/*------------------------------------*\
    TABS
\*------------------------------------*/

$tab-border-width: 1px;
$tab-width: 70px;
$tab-height: 40px;
$tab-border: $tab-border-width solid $color-grey-elements;
$tab-font-size: 16px;
$active-tab-font-weight: $font-weight-bold;

/*------------------------------------*\
    TABLE
\*------------------------------------*/

$table-td-color: $body-color;
$table-td-background-color: $color-white;
$table-th-color: $color-custom-3;
$table-thead-th-background-color: $color-white;
$table-tfoot-th-background-color: $color-first;
$table-group-summary-color: $body-color;
$table-group-summary-background-color: $color-third;
$table-between-row-border-color: $color-table-dark;
$table-tr-stripped-color-odd: $color-white;
$table-tr-stripped-color-even: $color-table-dark;
$table-stripped-color-total-group: $body-color;
$table-stripped-color-thead-group: $color-grey-elements;
$scrollable-table-td-background-color: $color-table-dark;
$scrollable-table-th-color: $color-custom-3;
$scrollable-table-group-name-color: $color-first;
$scrollable-table-thead-th-background-color: $color-white;
$scrollable-table-tfoot-th-background-color: $color-first;
$scrollable-table-tfoot-th-border-bottom-color: $color-table-dark;
$scrollable-table-tfoot-th-color: $color-white;
$scrollable-table-th-font-weight: $font-weight-bold;
$scrollable-table-td-font-weight: $font-weight-normal;
$table-simplebar-color: $color-first;
$table-th-mobile-group: $color-first;
$table-th-font-weight: $font-weight-bold;
$table-td-font-weight: $font-weight-normal;
$periodic-column-color: $color-menu-2nd-lvl-hover;
$periodic-column-footer-color: $color-third;
$table-th-draggable-color: $color-custom-3;
$table-td-hover-background: $color-grey-elements;
$table-th-hover-background: $color-grey-elements;
$table-bar-link-color: $color-custom-2;
$table-summary-background-color: $color-first;
$table-extended-footer-background-color: $color-first;
$table-footer-background-color: $color-first;
$table-fixed-expand-selected-background-color: $table-td-hover-background;

/*------------------------------------*\
    SWITCH
\*------------------------------------*/

$switch-color: $body-color;
$switch-checked-background-color: $color-menu-2nd-lvl-hover;
$switch-checked-color: $color-white;

$switch-color-bright: $color-white;
$switch-unchecked-color-bright: $color-first;
$switch-border-bright: $color-grey-elements;
$switch-background-bright: $color-white;

/*------------------------------------*\
    DROPDOWN
\*------------------------------------*/

$dropdown-tag-color: $color-first;
$dropdown-hover-tag-background-color: $color-menu-3rd-lvl-hover;
$dropdown-highlighted-tag-background-color: $color-menu-3rd-lvl-hover;
$dropdown-active-tag-color: $color-menu-3rd-lvl-hover !default;
$dropdown-active-tag-background-color: transparent !default;
$dropdown-dark-active-tag-color: $color-third;
$dropdown-dark-active-background-color: transparent;
$dropdown-toggle-open-indicator-border-color: $color-first;
$dropdown-dark-open-indicator-color: $color-first;
$dropdown-dark-open-indicator-border-color: $color-third;
$dropdown-dark-menu-background-color: $color-first;
$dropdown-dark-menu-color: $color-white;
$dropdown-placeholder-color: $color-first;

$sidebar-dropdown-element-active: $color-menu-3rd-lvl-hover;
$sidebar-dropdown-element-hover: $color-menu-3rd-lvl-hover;

/*------------------------------------*\
    CHECKBOX
\*------------------------------------*/

$custom-checkbox-disabled-background: $color-table-dark;
$custom-checkbox-disabled-border-color: $color-third;
$custom-checkbox-disabled-color: $color-third;
$custom-checkbox-disabled-label-color: inherit;

/*------------------------------------*\
    SIDEBAR FILTER
\*------------------------------------*/
$sidebar-bg-color: $color-first;
$sidebar-color: $color-white;

$sidebar-filters__footer-color: $color-first;
$sidebar-filters__button--clear-color: $color-third;
$sidebar-filters__button--clear-hover-color: $color-third-hover;
$sidebar-roll-indicator-background: $color-second;
$sidebar-filters__checkbox-background: $color-custom-1;
$sidebar-filters__checkbox-tick-color: $color-white;
$sidebar-filters__box-shadow-color: $color-menu-shadow;
$sidebar-filters__button-background: $color-menu-3rd-lvl-hover;
$sidebar-filters__button-background-hover: $color-menu-2nd-lvl-hover;
$sidebar-filters__button-text-color: $color-white;
$sidebar-filters__button-border-color: $color-white;
$sidebar-filters__button-border-background: $color-first;
$sidebar-filters__toggle-icon-color: $color-white; //g-custom2-c
$sidebar-filters__toggle-icon-background: $color-menu-3rd-lvl-hover; //g-brand2-bg
$sidebar-filters__toggle-icon-background-hover: $color-menu-2nd-lvl-hover;
$sidebar-filters__menu-item-background: $color-menu-3rd-lvl-hover;
$sidebar-filters__menu-item-color: $color-white;
$sidebar-filters__menu-item-disabled-color: $color-first;
$sidebar-filters__menu-item-disabled-background: $color-white;
$sidebar-filters__input-placeholder-color: $color-third;

/*------------------------------------*\
    FILTERS TREE
\*------------------------------------*/

$filters-tree-color-active: $color-first;
$filters-tree-color-disabled: $color-third;
$filters-tree-color-active-bg: $color-table-dark;
$filters-tree-icon-size: 15;
$filters-tree-bg: $color-table-dark;
$filters-tree-border: $color-table-dark;
$filters-tree-separator: $separator-brand1-border-color;

/*------------------------------------*\
    NAVIGATION
\*------------------------------------*/

$navigation__user-icon-background: $color-menu-2nd-lvl-hover;
$navigation__user-icon-color: $color-white;
$navigation--user-info-color: $color-custom-3;
$navigation__counter-icon-background: $color-custom-1;
$navigation__counter-icon-color: $color-white;
$navigation__menu-top-shadow: $color-menu-shadow;
$navigation__button-color: $color-first;
$navigation__button-hover: $color-white;

$navigation__lvl0-background: $color-first;
$navigation__lvl0-text-color: $color-white;

$navigation__lvl1-background: $color-second;
$navigation__lvl1-background-hover: $color-menu-2nd-lvl-hover;
$navigation__lvl1-text-color: $color-white;
$navigation__lvl1-text-color-hover: $color-white;
$navigation__lvl1-arrow-color: $color-white;
$navigation__lvl1-arrow-color-hover: $color-white;

$navigation__lvl2-background: $color-menu-2nd-lvl-hover;
$navigation__lvl2-background-hover: $color-menu-3rd-lvl-hover;
$navigation__lvl2-text-color: $color-white;
$navigation__lvl2-text-color-hover: $color-white;

$navigation__mobile-arrow-color: $color-white;
$navigation__mobile__lvl0-background-expanded: $color-second;
$navigation__mobile__lvl1-text-color: $color-white;

/*------------------------------------*\
    ARROW ICON
\*------------------------------------*/

$arrow-icon-color: $link-color;

/*------------------------------------*\
    SELECTED FILTER
\*------------------------------------*/

$selected-filter-background: $color-first;
$selected-filter-button-hover: $color-third;
$selected-filter-off-canvas-item-extra-color: $color-first;

/*------------------------------------*\
    DETAIL BOX
\*------------------------------------*/

$detail-box-bg-color: $color-table-dark;
$detail-box-content-bg-color: $color-table-dark;
$detail-box-bg-color-longer: $color-table-dark;

/*------------------------------------*\
    BOXES
\*------------------------------------*/

$boxes-bg-color: $color-white;
$boxes-button-close-color: $color-first;

/*------------------------------------*\
    DATEPICKER
\*------------------------------------*/

$datepicker-input-icon-color: $color-first;
$datepicker-input-exit-color: $color-first;
$datepicker-side-header-color: $color-first;
$datepicker-side-header-hover: $color-first;
$datepicker-side-header-button-hover: $color-first;
$datepicker-side-header-label: $color-first;
$double-datepicker-current-background: $color-first;
$datepicker-current-background: $color-links-on-white;
$datepicker-item-hover-background: $color-grey-elements;

/*------------------------------------*\
    VUEBAR
\*------------------------------------*/

$vuebar-dragger-styler: $color-first;

/*------------------------------------*\
    FILTERS PRESETS
\*------------------------------------*/

$filters-presets-tooltip-background-color: $color-first;

/*------------------------------------*\
    SIMPLEBAR
\*------------------------------------*/

$simplebar-scrollbar-background-color: $color-first;

/*------------------------------------*\
    FORM TOOLTIP
\*------------------------------------*/

$form-tooltip-content-background-color: $color-first;

/*------------------------------------*\
    HORIZONTAL LIST
\*------------------------------------*/

$horizontal-list-detail-value-color: $color-links-on-white;

/*------------------------------------*\
    MOBILE EXP TABLE
\*------------------------------------*/

$mobile-exp-table-background: $color-white;
$mobile-exp-table-th-background: $color-table-dark;
$mobile-exp-table-th-color: $table-th-mobile-group;
$mobile-exp-table-tr-border-color: $color-third;


/*------------------------------------*\
    CHARTS
\*------------------------------------*/

$chart-legend-title-color: $color-menu-2nd-lvl-hover;

/*------------------------------------*\
    ICONS
\*------------------------------------*/

$icons-with-background-background-color: $color-custom-1; //g-brand2-bg
$icons-with-background-color: $color-white; //$body-color || $white-color <- overwritten styles
