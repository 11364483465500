.#{$ns}horizontal-list {
    &__item {
        width: 243px;
        padding: 15px 15px 15px 0;
        box-sizing: border-box;
        vertical-align: top;
        display: inline-block;
    }

    &__detail-value {
        color: $horizontal-list-detail-value-color;
    }

    &__column-item {
        min-width: 33%;
        padding: 5px 5px 15px 0;
    }
    li:nth-of-type(3n) > div {
        //    float: right;
    }
}
