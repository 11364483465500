/*
 * hy.frontend.core
 * 
 * NOTE: this file is not subject to copyright transfer
 * 
 * UWAGA: plik nie objęty przekazaniem praw autorskich
 * 
 * 
 */





/*------------------------------------*\
    #FORMS
\*------------------------------------*/

////
/// @group forms
/// @author marcin.krasowski
////


/*------------------------------------*\
    NORMAL STATE
\*------------------------------------*/

/// Defines input font size
$form-input-font-size:              $font-size-base !default;
/// Defines input font weight
$form-input-font-weight:            normal       !default;
/// Defines input background color
$form-input-background:             $color-white !default;
/// Defines input text color
$form-input-color:                  $body-color  !default;
/// Defines input border width
$form-input-border-width:           1            !default;
/// Defines input border color
$form-input-border-color:           $color-black !default;
/// Defines input border radius
$form-input-border-radius:          3            !default;
/// Defines input shadow
$form-input-shadow:                 none         !default;
/// Defines input paddings
$form-input-padding:                10 10        !default;
/// Defines input margins
$form-input-margin:                 0 0 10       !default;
/// Defines input margins
$form-input-margin-medium:          0 0 10       !default;
/// Defines input margins
$form-input-margin-small:           0 0 10       !default;
/// Defines input minimal height
$form-input-height:                 floor(get-size(top, $form-input-padding) + get-size(bottom, $form-input-padding) +
                                          $form-input-border-width * 2 +
                                          (if($body-line-height != 'normal', $form-input-font-size * $body-line-height, $form-input-font-size)))
                                    !default;

/*------------------------------------*\
    DISABLED STATE
\*------------------------------------*/

/// Defines disabled input background color
$form-input-disabled-background:    darken($form-input-background, 5%) !default;
/// Defines disabled input text color
$form-input-disabled-color:         lighten($form-input-color, 50%)    !default;
/// Defines disabled input border width
$form-input-disabled-border-width:  $form-input-border-width !default;
/// Defines disabled input border color
$form-input-disabled-border-color:  $form-input-border-color !default;

/*------------------------------------*\
    READONLY STATE
\*------------------------------------*/

/// Defines readonly input background color
$form-input-readonly-background:    $form-input-background   !default;
/// Defines readonly input text color
$form-input-readonly-color:         $form-input-color        !default;
/// Defines readonly input border width
$form-input-readonly-border-width:  $form-input-border-width !default;
/// Defines readonly input border color
$form-input-readonly-border-color:  $form-input-border-color !default;
/// Defines readonly input paddings
$form-input-readonly-padding:       $form-input-padding      !default;

/*------------------------------------*\
    HOVER STATE
\*------------------------------------*/

/// Defines input on hover background color
$form-input-hover-background:       $form-input-background   !default;
/// Defines input on hover text color
$form-input-hover-color:            $form-input-color        !default;
/// Defines input on hover border width
$form-input-hover-border-width:     $form-input-border-width !default;
/// Defines input on hover border color
$form-input-hover-border-color:     $form-input-border-color !default;

/*------------------------------------*\
    FOCUS STATE
\*------------------------------------*/

/// Defines focused input background color
$form-input-focus-background:       $form-input-background   !default;
/// Defines focused input text color
$form-input-focus-color:            $form-input-color        !default;
/// Defines focused input border width
$form-input-focus-border-width:     $form-input-border-width !default;
/// Defines focused input border radius
$form-input-focus-border-radius:     $form-input-border-radius !default;
/// Defines focused input border color
$form-input-focus-border-color:     $form-input-border-color !default;
/// Defines if focus state should have outline
$form-input-focus-outline:          true                     !default;

/*------------------------------------*\
    INVALID STATE
\*------------------------------------*/

/// Defines invalid input class
$form-input-invalid-class:          'is-invalid'             !default;
/// Defines invalid input background color
$form-input-invalid-background:     $form-input-background   !default;
/// Defines invalid input text color
$form-input-invalid-color:          $form-input-color        !default;
/// Defines invalid input border width
$form-input-invalid-border-width:   $form-input-border-width !default;
/// Defines invalid input border color
$form-input-invalid-border-color:   red                      !default;

/*------------------------------------*\
    LABELS
\*------------------------------------*/

/// Defines fake label class
$form-label-class:                  'label'         !default;
/// Defines label font size
$form-label-font-size:              $font-size-base !default;
/// Defines label font weight
$form-label-font-weight:            normal          !default;
/// Defines label padding top
$form-label-padding-top:            get-size('top', $form-input-padding) + $form-input-border-width          !default;
/// Defines label padding bottom
$form-label-padding-bottom:         0               !default;

/*------------------------------------*\
    PLACEHOLDERS
\*------------------------------------*/

/// Defines input placeholder color
$form-placeholder-color:            gray                !default;
/// Defines input placeholder font style
$form-placeholder-font-style:       italic              !default;
/// Defines input placeholder font weight
$form-placeholder-font-weight:      lighter             !default;

/*------------------------------------*\
    FIELDSET
\*------------------------------------*/

/// Defines fieldset margin
$form-fieldset-margin:              0                   !default;
/// Defines fieldset padding
$form-fieldset-padding:             0                   !default;
/// Defines fieldset border style
$form-fieldset-border-style:           solid            !default;
/// Defines fieldset border width
$form-fieldset-border-width:           0                !default;
/// Defines fieldset border color
$form-fieldset-border-color:           $color-black     !default;
/// Defines fieldset border radius
$form-fieldset-border-radius:          0                !default;
/// Defines fieldset background
$form-fieldset-background:              transparent     !default;


/// Changes input style for different states
/// @param {String} $background - background color
/// @param {String} $color - text color
/// @param {Number} $border-width - border width
/// @param {String} $border-color - border color
@mixin change-input-style($background, $color, $border-width, $border-color) {
    background: $background;
    color: $color;
    border-width: size($border-width);
    border-color: $border-color;
}





/*------------------------------------*\
    ELEMENT STATES
\*------------------------------------*/

%input-hover {
    @include change-input-style(
        $form-input-hover-background,
        $form-input-hover-color,
        $form-input-hover-border-width,
        $form-input-hover-border-color
    );
}

%input-invalid {
    @include change-input-style(
            $form-input-invalid-background,
            $form-input-invalid-color,
            $form-input-invalid-border-width,
            $form-input-invalid-border-color
    );
}

%input-disabled {
    @include change-input-style(
        $form-input-disabled-background,
        $form-input-disabled-color,
        $form-input-disabled-border-width,
        $form-input-disabled-border-color
    );
}

%input-focus {
    @include change-input-style(
        $form-input-focus-background,
        $form-input-focus-color,
        $form-input-focus-border-width,
        $form-input-focus-border-color
    );
}

%input-readonly {
    @include change-input-style(
            $form-input-readonly-background,
            $form-input-readonly-color,
            $form-input-readonly-border-width,
            $form-input-readonly-border-color
    );
}





/*------------------------------------*\
    GENERAL INPUT STYLING
\*------------------------------------*/

input[type='text'], input[type='password'], input[type='date'], input[type='datetime'], input[type='email'], input[type='number'], input[type='tel'], input[type='time'], input[type='url'],
textarea,
select {
    display: block;
    font-size: size($form-input-font-size, $rel-unit);
    font-weight: $form-input-font-weight;
    color: $form-input-color;
    background: $form-input-background;
    border: size($form-input-border-width) solid $form-input-border-color;
    border-radius: size($form-input-border-radius);
    box-shadow: $form-input-shadow;
    padding: spacing($form-input-padding);
    margin: spacing($form-input-margin);
    width: 100%;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    line-height: $body-line-height;
    @if($form-input-height != 'auto') {
        min-height: size($form-input-height);
    }
    
    @if ($form-input-margin-medium != $form-input-margin) {
        @include respond-lte(medium) {
            margin: spacing($form-input-margin-medium);
        }
    }

    @if ($form-input-margin-small != $form-input-margin and $form-input-margin-small != $form-input-margin-medium) {
        @include respond-lte(small) {
            margin: spacing($form-input-margin-small);
        }
    }
    
    @include transition-standard-fast(background, color, border-color);

    @include placeholder() {
        color: $form-placeholder-color;
        font-style: $form-placeholder-font-style;
        font-weight: $form-placeholder-font-weight;
    }

    &:hover {
        @extend %input-hover;
    }

    &.#{$form-input-invalid-class} {
        @extend %input-invalid;
    }

    &:disabled {
        @extend %input-disabled;
    }
    &[disabled] {
        @extend %input-disabled;
    }

    &[readonly] {
        @extend %input-readonly;
        @if($form-input-readonly-padding != $form-input-padding) {
            padding: spacing($form-input-readonly-padding);
        }
    }

    &:focus {
        @extend %input-focus;
        
        @if($form-input-focus-outline == false) {
            outline: none !important;
        }
        
        @if($form-input-focus-border-width > $form-input-border-width) {
            margin-top: size(get-size(top, $form-input-margin) - ($form-input-focus-border-width - $form-input-border-width));
            margin-bottom: size(get-size(bottom, $form-input-margin) - ($form-input-focus-border-width - $form-input-border-width));
            padding-left: size(get-size(left, $form-input-padding) - ($form-input-focus-border-width - $form-input-border-width));
            padding-right: size(get-size(right, $form-input-padding) - ($form-input-focus-border-width - $form-input-border-width));
            border-radius: size($form-input-focus-border-radius);

            @if ($form-input-margin-medium != $form-input-margin) {
                @include respond-lte(medium) {
                    margin-top: size(get-size(top, $form-input-margin-medium) - ($form-input-focus-border-width - $form-input-border-width));
                    margin-bottom: size(get-size(bottom, $form-input-margin-medium) - ($form-input-focus-border-width - $form-input-border-width));
                }
            }

            @if ($form-input-margin-small != $form-input-margin and $form-input-margin-small != $form-input-margin-medium) {
                @include respond-lte(small) {
                    margin-top: size(get-size(top, $form-input-margin-small) - ($form-input-focus-border-width - $form-input-border-width));
                    margin-bottom: size(get-size(bottom, $form-input-margin-small) - ($form-input-focus-border-width - $form-input-border-width));
                }
            }
        }
    }
}

textarea {
    height: auto;
}

select {
    &:-moz-focusring {
        color: transparent;
        text-shadow: 0 0 0 #000;
    }
}


/*------------------------------------*\
    RADIOS & CHECKBOXES
\*------------------------------------*/

input {
    &[type='radio'], &[type='checkbox'] {
        width: auto;
        display: inline;
    }
}





/*------------------------------------*\
    LABELS
\*------------------------------------*/

label, .#{$form-label-class}, .o-#{$form-label-class} {
    cursor: pointer;
    font-size: size($form-label-font-size, $rel-unit);
    font-weight: $form-label-font-weight;
    padding-top: size($form-label-padding-top);
    padding-bottom: size($form-label-padding-bottom);
    display: inline-block;
}





/*------------------------------------*\
    FIELDSET
\*------------------------------------*/

fieldset {
    padding: $form-fieldset-padding;
    margin: $form-fieldset-margin;
    border: size($form-fieldset-border-width) $form-fieldset-border-style $form-fieldset-border-color;
    border-radius: size($form-fieldset-border-radius);
    background: $form-fieldset-background;
    
    // http://stackoverflow.com/questions/17408815/fieldset-resizes-wrong-appears-to-have-unremovable-min-width-min-content
    min-width: 0;           // webkit width fix
}




