/*------------------------------------*\
    #bubble
\*------------------------------------*/
////
/// @author
////

@mixin bubble-icon($bubble-icon-size) {
    color: $color-white;
    &::before {
        font-size: $bubble-icon-size;
    }
}

@mixin bubble-size($radius) {
    width: $radius;
    height: $radius;
    //border: 1px solid transparent;
    border-radius: $radius;
    box-sizing: border-box;
}

@mixin bubble-image($bubble-image-size) {
    width: $bubble-image-size;
    height: $bubble-image-size;
    border-radius: $bubble-image-size;
}
@mixin bubble-shadow {
    box-shadow: 0 4px 9px 0 rgba(0, 0, 0, 0.21);
}

@mixin bubble($radius, $bg: $color-white, $shadow: true) {
    display: table;
    background: $bg;
    border: 1px solid transparent;
    //border: 0 $bg solid; //high contrast mode fix
    @include bubble-size($radius);
    @include transition-standard(background);
    @if $shadow == true {
        @include bubble-shadow;
    }

    @content;
}

$bubble-size-xsmall: size(24, px);
$bubble-size-small: size(45, px);
$bubble-size-medium: size(110, px);
$bubble-size-large: size(130, px);
$bubble-size-xlarge: size(150, px);
$bubble-size-xxlarge: size(335, px);

$bubble-size-small-tablet: size(40, px);
$bubble-size-medium-tablet: size(95, px);
$bubble-size-large-tablet: size(113, px);
$bubble-size-xlarge-tablet: size(130, px);

$bubble-icon-size-xsmall: 11px;
$bubble-icon-size-small: 21px;
$bubble-icon-size-medium: 30px;
$bubble-icon-size-large-mobile: 40px;
$bubble-icon-size-large: 60px;
$bubble-icon-size-xlarge: 90px;

.#{$ns}bubble {
    @include bubble($bubble-size-large);
    &__inner {
        &--icon {
            @include bubble-icon($bubble-icon-size-large);
        }
        .#{$ns}bubble__picture {
            @include bubble-image($bubble-size-large);
        }
    }
    &--xs {
        @include bubble-size($bubble-size-xsmall);
        .#{$ns}bubble__inner--icon {
            @include bubble-icon($bubble-icon-size-xsmall);
        }
        .#{$ns}bubble__picture {
            @include bubble-image($bubble-size-xsmall);
        }
    }
    &--s {
        @include bubble-size($bubble-size-small);
        .#{$ns}bubble__inner--icon {
            @include bubble-icon($bubble-icon-size-small);
        }
        .#{$ns}bubble__picture {
            @include bubble-image($bubble-size-small);
        }
        @include respond(medium) {
            @include bubble-size($bubble-size-small-tablet);
        }
    }

    &--m {
        @include bubble-size($bubble-size-medium);
        .#{$ns}bubble__inner--icon {
            @include bubble-icon($bubble-icon-size-medium);
        }
        .#{$ns}bubble__picture {
            @include bubble-image($bubble-size-medium);
        }
        @include respond(medium) {
            @include bubble-size($bubble-size-medium-tablet);
        }
    }

    &--l {
        @include bubble-size($bubble-size-large);
        .#{$ns}bubble__inner--icon {
            @include bubble-icon($bubble-icon-size-large);
            @include respond(small) {
                @include bubble-icon($bubble-icon-size-large-mobile);
            }
        }
        .#{$ns}bubble__picture {
            @include bubble-image($bubble-size-large);
        }
        @include respond(medium) {
            @include bubble-size($bubble-size-large-tablet);
        }
    }

    &--xl {
        @include bubble-size($bubble-size-xlarge);
        @include respond-lte(medium) {
            @include bubble-size($bubble-size-xlarge-tablet);
        }
        .#{$ns}bubble__inner--icon {
            @include bubble-icon($bubble-icon-size-xlarge);
            @include respond(small) {
                @include bubble-icon($bubble-icon-size-large);
            }
        }

        .#{$ns}bubble__picture {
            @include bubble-image($bubble-size-xxlarge);
            @include respond-lte(medium) {
                @include bubble-image($bubble-size-xlarge);
            }
        }
    }

    &__inner {
        display: table-cell;
        vertical-align: middle;
        text-align: center;
    }

    &-double {
        display: inline-flex;
        justify-content: center;
        width: 33%;
        &:nth-child(4) {
            padding-left: 17%;
        }
        &:nth-child(6) {
            padding-left: 33%;
        }
        .#{$ns}bubble {
            position: relative;
            animation-duration: 1.2s;
            -webkit-animation-duration: 1s;

            animation-timing-function: ease;
            -webkit-animation-timing-function: ease;

            &__0,
            &__3 {
                animation-duration: 1.6s;
                animation-name: bubble-anim-0;
                -webkit-animation-name: bubble-anim-0;
            }
            &__1 {
                animation-name: bubble-anim-1;
                -webkit-animation-name: bubble-anim-1;
            }
            &__2,
            &__4 {
                animation-duration: 1.3s;
                animation-name: bubble-anim-2;
                -webkit-animation-name: bubble-anim-2;
            }
            &__5 {
                animation-name: bubble-anim-3;
                -webkit-animation-name: bubble-anim-3;
            }
            &__6 {
                animation-duration: 2s;
                animation-name: bubble-anim-4;
                -webkit-animation-name: bubble-anim-4;
            }

            .#{$ns}bubble {
                position: absolute;
                &--s {
                    .#{$ns}bubble__inner--icon {
                        @include bubble-icon($bubble-icon-size-small);
                    }
                    .#{$ns}bubble__picture {
                        @include bubble-image($bubble-size-small);
                    }
                }

                &--m {
                    .#{$ns}bubble__inner--icon {
                        @include bubble-icon($bubble-icon-size-medium);
                    }
                    .#{$ns}bubble__picture {
                        @include bubble-image($bubble-size-medium);
                    }
                }
                &--l {
                    .#{$ns}bubble__inner--icon {
                        @include bubble-icon($bubble-icon-size-large);
                    }
                    .#{$ns}bubble__picture {
                        @include bubble-image($bubble-size-large);
                    }
                }
            }
        }
        &--right {
            .#{$ns}bubble {
                &--xl {
                    .#{$ns}bubble {
                        left: 105px;
                        top: 0;
                        @include respond-lte(medium) {
                            left: 95px;
                        }
                    }
                }
                &--l {
                    .#{$ns}bubble {
                        left: 85px;
                        top: 0;
                        @include respond-lte(medium) {
                            left: 75px;
                        }
                    }
                }
                &--m {
                    .#{$ns}bubble {
                        left: 75px;
                        top: 0;
                        @include respond-lte(medium) {
                            left: 70px;
                        }
                    }
                }
            }
        }
        &--left {
            .#{$ns}bubble {
                .#{$ns}bubble {
                    right: 115px;
                    top: 0;
                }
            }
        }
    }

    &--image {
        position: relative;
    }

    &__picture {
        position: absolute;
        top: 0;
        left: 0;
    }
    &--no-shadow {
        box-shadow: none;
    }
    &--centered {
        margin: 0 auto;
        @include respond(small) {
            margin: 0;
        }
    }
}
.#{$ns}bubbles {
    @include respond-gte(medium) {
        height: 450px;
    }
    .fadingElement {
        opacity: 0;
        &.visible {
            opacity: 1;
            transition: opacity 0.5s ease;
        }
    }
    @include respond(small) {
        .cm-bubble--mobile {
            display: flex;
            align-items: center;
            height: 36px;
            padding: 2px;
            border-radius: 32px;
            margin-bottom: 20px;
            box-sizing: border-box;
            animation-name: bubble-anim-mobile;
            animation-duration: 0.8s;
            -webkit-animation-duration: 0.6s;
            animation-timing-function: ease;
            -webkit-animation-timing-function: ease;

            .cm-bubble--s {
                height: 29px;
                width: 29px;
                border-radius: 29px;
                margin-right: 15px;
                margin-left: 1px;
            }
        }
    }
}
@keyframes bubble-anim-0 {
    0% {
        visibility: visible;
        transform: translateY(-50%) translateX(-100%);
    }
    50% {
        transform: translateY(15%) translateX(15%);
    }
    65% {
        transform: translateY(-20%) translateX(-20%);
    }
    80% {
        transform: translateY(4%) translateX(4%);
    }
    95% {
        transform: translateY(-2%) translateX(-2%);
    }
    100% {
        transform: translateY(0%) translateX(0%);
    }
}
@keyframes bubble-anim-1 {
    0% {
        visibility: visible;
        transform: translateY(-100%);
    }
    50% {
        transform: translateY(8%);
    }
    65% {
        transform: translateY(-4%);
    }
    80% {
        transform: translateY(4%);
    }
    95% {
        transform: translateY(-2%);
    }
    100% {
        transform: translateY(0%);
    }
}
@keyframes bubble-anim-2 {
    0% {
        visibility: visible;
        transform: translateX(100%);
    }
    50% {
        transform: translateX(-70%);
    }
    65% {
        transform: translateX(10%);
    }
    80% {
        transform: translateX(-5%);
    }
    95% {
        transform: translateX(2%);
    }
    100% {
        transform: translateX(0%);
    }
}
@keyframes bubble-anim-3 {
    0% {
        visibility: visible;
        transform: translateY(100%);
    }
    50% {
        transform: translateY(-8%);
    }
    65% {
        transform: translateY(4%);
    }
    80% {
        transform: translateY(-4%);
    }
    95% {
        transform: translateY(2%);
    }
    100% {
        transform: translateY(0%);
    }
}
@keyframes bubble-anim-4 {
    0% {
        visibility: visible;
        transform: translateY(100%) translateX(50%);
    }
    50% {
        transform: translateY(-8%) translateX(-8%);
    }
    65% {
        transform: translateY(4%) translateX(4%);
    }
    80% {
        transform: translateY(-4%) translateX(-4%);
    }
    95% {
        transform: translateY(2%) translateX(2%);
    }
    100% {
        transform: translateY(0%) translateX(0%);
    }
}

@keyframes bubble-anim-mobile {
    0% {
        width: 0%;
    }
    10% {
        width: 10%;
    }
    20% {
        width: 20%;
    }
    30% {
        width: 30%;
    }
    40% {
        width: 40%;
    }
    50% {
        width: 50%;
    }
    60% {
        width: 60%;
    }
    70% {
        width: 70%;
    }
    80% {
        width: 80%;
    }
    90% {
        width: 90%;
    }
    100% {
        width: 100%;
    }
}

_::-webkit-full-page-media,
.#{$ns}bubble {
    border: 1px solid white;
} // Safari fix
