/*
 * hy.frontend.core
 * 
 * NOTE: this file is not subject to copyright transfer
 * 
 * UWAGA: plik nie objęty przekazaniem praw autorskich
 * 
 * 
 */





/*------------------------------------*\
    #Messages
\*------------------------------------*/
////
/// @group messages
/// @author sebastian.nowak
////





/*------------------------------------*\
    CONTEXT MESSAGE
\*------------------------------------*/

/// Defines message font-size
$msg-font-size: $font-size-base !default;
/// Defines message header font-size
$msg-header-font-size: $font-size-base !default;
/// Defines message font weight
$msg-font-weight:        normal !default;
/// Defines message header font weight
$msg-header-font-weight: bold   !default;
/// Defines message padding
$msg-padding:            0  !default;
/// Defines message margin
$msg-margin:             0  !default;
/// Defines message content align
$msg-align: left  !default;
/// Defines message text vertical align
$msg-text-vertical-align: middle !default;
/// Defines message icon vertical align
$msg-icon-vertical-align: top !default;
/// Defines message icon size
$msg-icons-size: $icon-size !default;
/// Defines space between icon and message
$msg-icon-space:   $icon-space  !default;

/// Defines info message text color
$info-text-color:  $color-info !default;
/// Defines success message text color
$success-text-color:  $color-success !default;
/// Defines alert message text color
$alert-text-color: $color-alert !default;
/// Defines error message text color
$error-text-color: $color-error !default;

/// Defines info message with icon text color
$info-message-with-icon-text-color:  $color-info !default;
/// Defines success message with icon text color
$success-message-with-icon-text-color:  $color-success !default;
/// Defines alert message with icon text color
$alert-message-with-icon-text-color: $color-alert !default;
/// Defines error message with icon text color
$error-message-with-icon-text-color: $color-error !default;

/// Defines info message icon color
$info-icon-color:  $color-info !default;
/// Defines success message icon color
$success-icon-color:  $color-success !default;
/// Defines alert message icon color
$alert-icon-color: $color-alert !default;
/// Defines error message icon color
$error-icon-color: $color-error !default;

/*------------------------------------*\
    BOX MESSAGE
\*------------------------------------*/

/// Defines box message font-size
$msg-box-font-size:             $msg-font-size !default;
/// Defines box message header font-size
$msg-box-header-font-size:      $msg-header-font-size !default;
/// Defines box message font weight
$msg-box-font-weight:           normal !default;
/// Defines box message header font weight
$msg-box-header-font-weight:    bold   !default;
/// Defines box message padding
$msg-box-padding:               10 !default;
/// Defines box message margin
$msg-box-margin:                 0 !default;

/// Defines box message content align
$msg-box-align: left  !default;
/// Defines box message content vertical align
$msg-box-text-vertical-align: middle !default;
/// Defines box message icon vertical align
$msg-box-icon-vertical-align: top !default;
/// Defines box message icon size
$msg-box-icons-size: $icon-size !default;
/// Defines space between icon and message in box
$msg-box-icon-space:   $icon-space  !default;

/// Defines info box message text color
$info-box-text-color:  $color-white !default;
/// Defines success box message text color
$success-box-text-color:  $color-white !default;
/// Defines alert box message text color
$alert-box-text-color: $color-white !default;
/// Defines error box message text color
$error-box-text-color: $color-white !default;

/// Defines info box message icon color
$info-box-icon-color:  $color-white !default;
/// Defines success box message icon color
$success-box-icon-color:  $color-white !default;
/// Defines alert box message icon color
$alert-box-icon-color: $color-white !default;
/// Defines error box message icon color
$error-box-icon-color: $color-white !default;

/// Defines info box message background color
$info-box-color:  $color-info !default;
/// Defines success box message background color
$success-box-color:  $color-success !default;
/// Defines alert box message background color
$alert-box-color: $color-alert !default;
/// Defines info box message background color
$error-box-color: $color-error !default;

/// Defines box message border width
$msg-box-border-width: 0 !default;
/// Defines info box message icon color
$info-box-border-color:  darken($color-info,       10%) !default;
/// Defines success box message icon color
$success-box-border-color:  darken($color-success, 10%) !default;
/// Defines alert box message icon color
$alert-box-border-color: darken($color-alert,      10%) !default;
/// Defines info box message icon color
$error-box-border-color: darken($color-error,      10%) !default;



// Helper mixin for _messages.scss file.
@mixin msg-context-set-type-and-style($type, $text-color: '', $icon-color: '', $with-icon-text-color: '') {

    &.o-msg--#{$type} {
        @include optional('color', $text-color);

        .o-msg__icon {
            @include optional('color', $icon-color);
        }

        &.o-msg--icon {
            .o-msg__text {
                @include optional('color', $with-icon-text-color);
            }
        }
    }

}

// Helper mixin for _messages.scss file.
@mixin msg-box-set-type-and-style($type, $box-color: '', $box-text-color: '', $box-border-color: '', $box-icon-color: '') {

    &.o-msg--#{$type} {
        background: $box-color;
        color: $box-text-color;
        border-color: $box-border-color;

        .o-msg__icon {
            color: $box-icon-color;
        }
    }

}



.o-msg {

    &.o-msg--context {
        position: relative;
        padding: spacing($msg-padding);
        margin: spacing($msg-margin);
        font-weight: $msg-font-weight;
        min-height: size($msg-icons-size, $rel-unit);
        font-size: size($msg-font-size, $rel-unit);

        @include msg-context-set-type-and-style('info', $info-text-color, $info-icon-color, $info-message-with-icon-text-color);

        @include msg-context-set-type-and-style('success', $success-text-color, $success-icon-color, $success-message-with-icon-text-color);

        @include msg-context-set-type-and-style('alert', $alert-text-color, $alert-icon-color, $alert-message-with-icon-text-color);

        @include msg-context-set-type-and-style('error', $error-text-color, $error-icon-color, $error-message-with-icon-text-color);

        .o-msg__content {
            text-align: $msg-align;

            @if $msg-align=='center' {
                .l-group { margin: 0 auto; }
            }

            @if $msg-align=='right' {
                .l-group__element.o-msg__text { width: 100%; }
            }
        }

        .o-msg__icon {
            vertical-align: $msg-icon-vertical-align;

            .g-icon--before:before,
            .g-icon--after:after {
                font-size: size($msg-icons-size);
            }

            .g-icon--before:before { margin-right: size($msg-icon-space); }
            .g-icon--after:after   { margin-left:  size($msg-icon-space); }
        }

        .o-msg__text {
            font-weight: $msg-font-weight;
            vertical-align: $msg-text-vertical-align;
        }

        .o-msg__header {
            font-weight: $msg-header-font-weight;
        }
    }

    &.o-msg--box {
        font-size: size($msg-box-font-size, $rel-unit);
        padding: spacing($msg-box-padding);
        margin: spacing($msg-box-margin);
        border: size($msg-box-border-width) solid $color-black;

        @include msg-box-set-type-and-style('info', $info-box-color, $info-box-text-color, $info-box-border-color, $info-box-icon-color);

        @include msg-box-set-type-and-style('success', $success-box-color, $success-box-text-color, $success-box-border-color, $success-box-icon-color);

        @include msg-box-set-type-and-style('alert', $alert-box-color, $alert-box-text-color, $alert-box-border-color, $alert-box-icon-color);

        @include msg-box-set-type-and-style('error', $error-box-color, $error-box-text-color, $error-box-border-color, $error-box-icon-color);

        .o-msg__content {
            text-align: $msg-box-align;

            @if $msg-box-align=='center' {
                .l-group { margin: 0 auto; }
            }

            @if $msg-box-align=='right' {
                .l-group__element.o-msg__text { width: 100%; }
            }
        }

        .o-msg__icon {
            vertical-align: $msg-box-icon-vertical-align;

            .g-icon--before:before,
            .g-icon--after:after {
                font-size: size($msg-box-icons-size);
            }

            .g-icon--before:before { margin-right: size($msg-box-icon-space); }
            .g-icon--after:after  { margin-left: size($msg-box-icon-space); }
        }

        .o-msg__text {
            font-weight: $msg-box-font-weight;
            vertical-align: $msg-box-text-vertical-align;
        }

        .o-msg__header {
            font-weight: $msg-box-header-font-weight;
        }
    }

    &--validation {
        display: none;

        &.is-visible, .no-js .#{$form-input-invalid-class} + & {
            display: block;
        }

        input:disabled ~ &, textarea:disabled ~ &, select:disabled ~ & {
            display: none;
        }
    }

    input ~ &, textarea ~ &, select ~ &, .o-select ~ &, .o-custom-label ~ &, .o-sliding-label ~ & {
        top: size(-(get-size(bottom, $form-input-margin))) + get-size(top, $msg-margin);

        @if ($form-input-margin-medium != $form-input-margin) {
            @include respond-lte(medium) {
                top: size(-(get-size(bottom, $form-input-margin-medium))) + get-size(top, $msg-margin);
            }
        }

        @if ($form-input-margin-small != $form-input-margin and $form-input-margin-small != $form-input-margin-medium) {
            @include respond-lte(small) {
                top: size(-(get-size(bottom, $form-input-margin-small))) + get-size(top, $msg-margin);
            }
        }
    }
}
