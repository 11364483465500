.cm-tooltip {
    display: none;
    &__icon {
        display: inline-block;
    }
    &__button {
        padding: 0 5px;
        background-color: transparent;
        border: none;
        display: inline-block;
    }
    &__container {
        background-color: white;
        position: absolute;
        z-index: 6;
        height: auto;
        width: 200px;
        padding: 10px;
        box-shadow: 0 0 33px -17px rgba(0, 0, 0, 0.34);
        li {
            margin: 5px 0;
        }
    }
    &--visible {
        display: inline-block;
    }
}

.icon-tooltip {
    .cm-tooltip {
        display: inline-block;
        position: absolute;
        top: 14px;
        &__container {
            padding: 5px 10px;
            border-radius: 5px;
            right: -3px;
            top: -12px;
            width: 110px;
            box-shadow: 0 5px 10px rgba(86, 86, 86, 0.15);
        }
        .o-btn--link {
            overflow: hidden;
            text-overflow: ellipsis;
            width: 100%;
            text-align: left;
        }
    }
    &--right {
        .cm-tooltip {
            right: 13px;
        }
    }
}

.ag-row-odd .ag-grid-icon-tooltip .cm-tooltip {
    background: $table-tr-stripped-color-odd;
}

.ag-row-even .ag-grid-icon-tooltip .cm-tooltip {
    background: $table-tr-stripped-color-even;
}

.ag-row-hover .ag-grid-icon-tooltip .cm-tooltip {
    background: $table-td-hover-background;
}

.ag-grid-icon-tooltip {
    .cm-tooltip {
        display: inline-block;
        position: absolute;
        right: 0;
        padding-right: 10px;
        padding-left: 10px;

        &__container {
            padding: 5px 10px;
            border-radius: 5px;
            right: -3px;
            top: -12px;
            width: 110px;
            box-shadow: 0 5px 10px rgba(86, 86, 86, 0.15);
        }
        .o-btn--link {
            overflow: hidden;
            text-overflow: ellipsis;
            width: 100%;
            text-align: left;
        }

        .cm-tooltip__button {
            .g-icon--dots {
                &:before {
                    color: $body-color;
                }
            }
        }
    }
    &--right {
        .cm-tooltip {
            right: 13px;
        }
    }
}
