.#{$ns}selected-filter {
    $self: &;
    display: flex;
    align-items: baseline;
    &__li {
        margin: 2px 10px 2px 0;
        display: inline-block;
    }
    &__item {
        background-color: $selected-filter-background;
        color: $color-white;
        padding: 1px 10px;
        border-radius: 15px;
        position: relative;
        border: 1px solid $selected-filter-background;
        box-sizing: border-box;

        &.is-disabled {
            background: $color-gray4;
            border-color: $color-gray4;
        }
    }
    &__button {
        padding: 0 10px;
        color: $color-white;
        .g-icon:before {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }

        &:hover,
        &:focus {
            color: $color-white;
        }
        &:hover:focus {
            color: $selected-filter-button-hover;
        }
    }
    &__header {
        font-weight: $font-weight-bold;
        margin-right: 25px;
    }
    .o-off-canvas__content & {
        flex-wrap: wrap;
        #{$self}__li {
            margin: 5px 10px 0 0;
        }
        #{$self}__item {
            background-color: $color-blue2;
            &--extra {
                background-color: $color-white;
                color: $selected-filter-off-canvas-item-extra-color;
            }
        }
        #{$self}__header {
            margin-bottom: 10px;
            width: 100%;
        }
    }
}
