/*
 * hy.frontend.core
 * 
 * NOTE: this file is not subject to copyright transfer
 * 
 * UWAGA: plik nie objęty przekazaniem praw autorskich
 * 
 * 
 */





/*------------------------------------*\
    #SEPARATORS
\*------------------------------------*/

////
/// @group separators
/// @author sebastian.nowak
////

/// defines separator size
$separator-size: 1 !default;
/// defines separator spacing
$separator-spacing: $font-size-base 0 !default;
/// defines separator default background color
$separator-color: $body-color !default;
/// defines separator default width
$separator-width: 100% !default;

hr {
    border: none;
    border-bottom: size($separator-size) solid $separator-color;
    width: $separator-width;
    margin: spacing($separator-spacing);
}
