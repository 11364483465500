/*
 * hy.frontend.core
 * 
 * NOTE: this file is not subject to copyright transfer * 
 * UWAGA: plik nie objęty przekazaniem praw autorskich * 
 * 
 */





/*------------------------------------*\
    #HELPERS MQ
\*------------------------------------*/

////
/// UTILS [U]
/// Utilities classes for RWD
/// @group utilities
/// @author marcin.krasowski
////

// hide classes

.u-small-hide {
     @include respond-lte(small) {
         display: none !important;
     }
 }

.u-medium-hide {
    @include respond(medium) {
        display: none !important;
    }
}

.u-large-hide {
    @include respond-gte(large) {
        display: none !important;
    }
}

.u-small-block {
    @include respond-lte(small) {
        display: block !important;
    }
}

.u-medium-block {
    @include respond(medium) {
        display: block !important;
    }
}

.u-large-block {
    @include respond-gte(large) {
        display: block !important;
    }
}


// float classes

.u-small-left {
    @include respond-lte(small) {
        float: left;
    }
}

.u-small-right {
    @include respond(medium) {
        float: right;
    }
}

.u-medium-left {
    @include respond(medium) {
        float: left;
    }
}

.u-medium-right {
    @include respond(medium) {
        float: right;
    }
}

.u-large-left {
    @include respond-gte(large) {
        float: left;
    }
}

.u-large-right {
    @include respond-gte(large) {
        float: right;
    }
}
