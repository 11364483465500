/*------------------------------------*\
    #Breadcrumbs
\*------------------------------------*/
////
/// @group breadcrumbs
/// @author marcin.krasowski
////





/// Defines breadcrumbs wrapper margins
$breadcrumbs-margin:            0       !default;
/// Defines paddings on each breadcrumb item
$breadcrumb-item-padding:       10 0    !default;
/// Defines paddings on each breadcrumb separator
$breadcrumb-separator-padding:  0 10    !default;

/// Defines paddings on each breadcrumb separator
$breadcrumb-font-color:         inherit !default;
/// Defines paddings on each breadcrumb separator
$breadcrumb-font-size:          inherit !default;
/// Defines paddings on each breadcrumb separator
$breadcrumb-font-weight:        inherit !default;

/// defines breadcrumb link decoration
$breadcrumb-link-decoration:        none        !default;
/// defines breadcrumb link decoration on hover/focus/active
$breadcrumb-link-hover-decoration:  underline   !default;




.o-breadcrumbs {
    margin: spacing($breadcrumbs-margin);
    
    &__item {
        float: left;
        padding: spacing($breadcrumb-item-padding);
    }
    
    &__content {
        text-decoration: $breadcrumb-link-decoration;
        
        @if $breadcrumb-font-color != 'inherit' {
            color: $breadcrumb-font-color;
        }
        @if $breadcrumb-font-size != 'inherit' {
            font-size: $breadcrumb-font-size;
        }
        @if $breadcrumb-font-weight != 'inherit' {
            font-weight: $breadcrumb-font-weight;
        }
    }
    
    &__separator {
        padding: spacing($breadcrumb-separator-padding);
    }
}
