.#{$ns}modal {
    &__mask {
        position: fixed;
        z-index: 9998;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        display: table;
        transition: opacity 0.6s ease;
        overflow: hidden;
        -webkit-overflow-scrolling: touch;
    }

    &__wrapper {
        display: block;
        position: fixed;
        left: 50%;
        top: 60%;
        transform: translate(-50%, -60%);
        max-height: 100%;
        min-height: 60%;
        width: 100%;
        padding: 0 35px 0 35px;

        &--overflow-visible {
            overflow: visible;
        }
    }

    &__content {
        overflow: auto;
        overscroll-behavior: contain;
        padding: 3px 0 3px 3px;
        @include scrollbar($body-color, transparent, 4px, 15px, 2px);
    }

    &__container {
        max-width: $modal-width;
        box-sizing: border-box;
        margin: 0 auto;
        padding: 25px;
        background-color: $modal-bg-color;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
        font-family: $body-font-family;
        position: relative;
        @include respond(medium) {
            max-width: 75vw;
        }
        @include respond(small) {
            padding: 15px;
            margin: 0 20px;
            max-width: unset;
        }

        .#{$ns}modal--close & {
            padding-top: 50px;
            @include respond(small) {
                padding-top: 40px;
            }
        }

        &--no-background {
            @include respond-gt(medium) {
                margin-left: 278px;
                margin-right: 30px;
            }
        }

        .#{$ns}modal--l & {
            max-width: $modal-width-l;
        }

        .#{$ns}modal--xs & {
            max-width: $modal-width-xs;
        }

        .#{$ns}modal--80vw & {
            max-width: 80vw;
        }
    }

    &__close {
        width: auto;
        position: absolute;
        right: 20px;
        top: 15px;
        background-color: transparent;
        border-width: 0;

        &:active,
        &:focus,
        &:hover,
        &:focus:hover {
            background: transparent;
        }
    }

    &__bottom {
        min-height: 150px;
    }

    .el-picker-panel {
        position: absolute !important;
        top: 30px !important;
        left: 0 !important;
    }

    &--info {
        .cm-orb__inner--icon {
            position: absolute;
            top: 5px;
            left: 7px;
        }
    }

    &--tableBulkAction {
        .cm-select .vs__dropdown-menu {
            //static width for IE
            width: 480px;

            //other browsers
            width: max-content;
        }
    }
}
