/*
 * hy.frontend.core
 * 
 * NOTE: this file is not subject to copyright transfer
 * 
 * UWAGA: plik nie objęty przekazaniem praw autorskich
 * 
 * 
 */





/*------------------------------------*\
    #GRID [G]
\*------------------------------------*/

////
/// GRID [G]
/// @group layout
/// @author marcin.krasowski
////

/// Defines total grid column count
$grid-columns: 12 !default;
/// Defines grid column gutter
$grid-gutter: 15px !default;
/// Defines grid column gutter on tablets
$grid-gutter-medium: $grid-gutter !default;
/// Defines grid column gutter on mobile
$grid-gutter-small: $grid-gutter !default;
/// Defines grid row class
$grid-row-class: 'row' !default;
/// Defines grid row margin-bottom
$grid-row-space:  $grid-gutter !default;
/// Defines small grid row margin-bottom
$grid-row-space-small: calc($grid-row-space / 2) !default;
/// Defines large grid row margin-bottom
$grid-row-space-large: calc($grid-row-space * 2) !default;
/// Defines grid column class
$grid-column-class: 'col' !default;
/// Defines if mobile-first grid should be used
$grid-mobile-first: true !default;





/// Initialize Susy layout
$susy: (
    gutter-position: inside-static,
	container: 100%,
    columns: $grid-columns,
    column-width: 1px,
    gutters: calc(($grid-gutter * 2) / 1px)
);





//* ------------------------------------- */
// GRID-ROWS
//* ------------------------------------- */

.l-#{$grid-row-class} {
    @include container();
    max-width: none;
    @include squish(-$grid-gutter);
    
    // medium and smaller
    @include respond-lte(medium) {
        // change gutter if necessary
        @if ($grid-gutter-medium != $grid-gutter) {
            @include squish(-$grid-gutter-medium);
        }
    }

    // small and smaller
    @include respond-lte(small) {
        // change gutter if necessary
        @if ($grid-gutter-small != $grid-gutter) {
            @include squish(-$grid-gutter-small);
        }
    }
    
    &--space {
        margin-bottom: $grid-row-space;
    }

    &--space-small {
        margin-bottom: $grid-row-space-small;
    }

    &--space-large {
        margin-bottom: $grid-row-space-large;
    }
}

// collapse row's margins to give paddings to first and last columns
.l-#{$grid-row-class}--collapse {
    @include squish(0);
}





//* ------------------------------------- */
// GRID-COLUMNS
//* ------------------------------------- */

// base column has full width
.l-#{$grid-column-class} {
    @include span(100%);
    position: relative;
    min-height: 1px;
}

// align column to right
.l-#{$grid-column-class}--opposite {
    float: right;
}

.l-#{$grid-column-class}--auto {
    width: auto;
}



// generate Mobile-First grid
@if $grid-mobile-first == true {

    .l-#{$grid-column-class} {
        @include gutter($grid-gutter-small * 2);
    }
    // generate columns
    $i: 1;
    @for $i from 1 through $grid-columns {
        @if $i <= $grid-columns {
            $temp-col: $grid-column-class + '-' + $i;
            .l-#{$temp-col} {
                width: span($i);
            }
        }
    }

    // medium and bigger
    @include respond-gte(medium) {
        @if($grid-gutter-medium != $grid-gutter-small) {
            .l-#{$grid-column-class} {
                @include gutter($grid-gutter-medium * 2);
            }
        }
        // generate columns
        $i: 1;
        @for $i from 1 through $grid-columns {
            @if $i <= $grid-columns {
                .l-#{$grid-column-class}-medium-#{$i} {
                    width: span($i);
                }
            }
        }
    }

    // large and bigger
    @include respond-gte(large) {

        @if($grid-gutter != $grid-gutter-medium) {
            .l-#{$grid-column-class} {
                @include gutter($grid-gutter * 2);
            }
        }
        // generate columns
        $i: 1;
        @for $i from 1 through $grid-columns {
            @if $i <= $grid-columns {
                .l-#{$grid-column-class}-large-#{$i} {
                    width: span($i);
                }
            }
        }
    }

// generate regular grid
} @else {

    // generate columns
    $i: 1;
    @for $i from 1 through $grid-columns {
        @if $i <= $grid-columns {
            $temp-col: $grid-column-class + '-' + $i;
            .l-#{$temp-col} {
                width: span($i);
            }
        }
    }

    // medium and smaller
    @include respond-lte(medium) {
        // change gutter if necessary
        @if($grid-gutter-medium != $grid-gutter) {
            .l-#{$grid-column-class} {
                @include gutter($grid-gutter-medium * 2);
            }
        }

        // generate columns
        $i: 1;
        @for $i from 1 through $grid-columns {
            @if $i <= $grid-columns {
                .l-#{$grid-column-class}-medium-#{$i} {
                    width: span($i);
                }
            }
        }
    }

    // small and smaller
    @include respond-lte(small) {
        // change gutter if necessary
        @if($grid-gutter-small != $grid-gutter-medium) {
            .l-#{$grid-column-class} {
                @include gutter($grid-gutter-small * 2);
            }
        }

        // generate columns
        $i: 1;
        @for $i from 1 through $grid-columns {
            @if $i <= $grid-columns {
                .l-#{$grid-column-class}-small-#{$i} {
                    width: span($i);
                }
            }
        }
    }
}





//* ------------------------------------- */
// COLUMN OFFSETS
//* ------------------------------------- */

// offset classes on small
@include respond-lte(small) {
    $i: 1;
    @for $i from 1 through $grid-columns - 2 {
        .l-#{$grid-column-class}.small-offset-by-#{$i} {
            margin-left: span($i);
        }
    }
}

// offset classes on medium
@include respond(medium) {
    $i: 1;
    @for $i from 1 through $grid-columns - 2 {
        .l-#{$grid-column-class}.medium-offset-by-#{$i} {
            margin-left: span($i);
        }
    }
}

// offset classes on large
@include respond-gte(large) {
    $i: 1;
    @for $i from 1 through $grid-columns - 2 {
        .l-#{$grid-column-class}.large-offset-by-#{$i} {
            margin-left: span($i);
        }
    }
}
