.bankDetailsRow {
    .bankDetailsColumn {
        margin: 0 auto;
        float: none;
        label {
            color: $color-gray4;
        }
        .fadingElement {
            opacity: 0;
            &.inputIsVisible {
                opacity: 1;
                transition: opacity 0.5s ease;
            }
        }
    }
}
