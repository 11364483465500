.#{$ns}reports {
    &__list {
        background-color: #e8eff3;
        display: inline-block;
        width: 100%;
    }
    &__item {
        width: 100%;
        vertical-align: middle;
        float: left;
        box-sizing: border-box;
        padding: 10px;
        border-bottom: 1px #e8e8e8 solid;
        &:first-of-type {
            border-top: 1px #e8e8e8 solid;
        }
        .#{$ns}reports__expand-content {
            max-width: 60%;
            padding: 20px 20px 20px 40px;
            display: none;
        }
        &.is-expanded {
            .#{$ns}reports__expand-content {
                display: block;
            }
        }
    }
    &__title {
        display: inline-block;
        min-width: 100px;
        margin-bottom: 0;
        padding-left: 15px;
        color: #575757;
        font-weight: $font-weight-bold;
    }
    &__link {
        float: right;
    }
}
