$scrollable-table-tfoot-th-color: $color-white !default;

.#{$ns}scrollable {
    .#{$ns}table__thead {
        th {
            background-color: $scrollable-table-thead-th-background-color;
            color: $scrollable-table-th-color;
            font-weight: $scrollable-table-th-font-weight;
        }
    }

    td {
        font-weight: $table-td-font-weight;
    }

    &__tfoot {
        &.counted {
            thead,
            tfoot {
                display: none;
            }
        }

        .#{$ns}table--extended tbody tr {
            td,
            th {
                &:first-child {
                    color: $scrollable-table-th-color;
                }

                &:not(:first-child) {
                    background-color: $scrollable-table-tfoot-th-background-color;
                    color: $scrollable-table-tfoot-th-color;
                    font-weight: $font-weight-bold;
                }

                .cm-table__content {
                    padding-top: 16px !important;
                }
            }

            td:first-child > .cm-table__content {
                border: none;
                color: $scrollable-table-th-color;
                font-size: ms($h4-scale);
            }
        }
    }

    .vb > .vb-dragger {
        z-index: 0;
    }

    &__group-name {
        padding: 10px;
        white-space: nowrap;
        font-weight: $font-weight-bold;
        color: $scrollable-table-group-name-color;

        .cm-table__row-number {
            width: 60px !important;
        }
    }

    .#{$ns}table--group-summary {
        td,
        th {
            &:not(:first-child) {
                background: $table-group-summary-background-color;
                color: $table-group-summary-color;
                font-weight: $font-weight-bold;
            }

            &:first-child > .cm-table__content {
                font-size: ms($h5-scale);
                color: $color-black;
                border-bottom: 1px solid $color-gray2;
            }
        }
    }

    &__scroller {
        height: 10px;
    }

    &__bottom-left {
        overflow-x: hidden !important;
    }

    &__col {
        width: 310px !important;
        position: absolute !important;
        padding-right: 10px;
        height: auto !important;
        overflow: visible !important;
        left: 0;
        top: 0;
        background: $color-white;
        box-sizing: border-box;

        .cm-table__container--hover {
            display: inline-block;
        }

        tr td .cm-table__content,
        .cm-table__content .cm-table__content--editable {
            overflow: hidden;
            width: 100%;
            padding: 0 10px 0;
            white-space: normal;
            box-sizing: border-box;
            height: 48px;
            display: table-cell;
            vertical-align: middle;

            p {
                padding-top: 0;
            }
        }

        &.cm-scrollable__col--thead {
            background-color: $scrollable-table-thead-th-background-color;
            z-index: 1;

            .cm-table__content {
                display: block;
            }

            .cm-scrollable__top-left {
                .cm-table__content {
                    height: 49px;
                    display: flex;
                    align-items: center;
                    color: $scrollable-table-th-color;
                }
            }
        }

        &--total {
            padding-right: 48px;

            .cm-table__content {
                padding: 10px;
                font-size: ms($h5-scale);
                color: $scrollable-table-th-color;
            }
        }

        &--thead {
            .cm-table__content {
                color: $color-gray4;
                border-bottom: 1px solid $color-gray2;
            }
        }

        .cm-table__content.cm-scrollable__col--part {
            display: inline-block !important;

            &-first {
                width: 20%;
            }

            &-second {
                width: 80%;
            }
        }

        &--tfoot {
            .cm-table__content {
                color: $color-black;
                font-size: ms($h4-scale);
            }
        }
    }

    &__wrapper {
        padding-left: 310px;

        &--only-scroll-h {
            padding-left: 0;

            .#{$ns}scrollable__horizontal {
                height: 10px !important;
            }
        }

        //box-sizing: border-box;
        .cm-table--extended {
            tbody {
                td {
                    background-color: $scrollable-table-td-background-color;
                }
            }

            th,
            td {
                &:first-child,
                &.is-ordered {
                    display: none;
                }
            }

            &.cm-table--group-summary {
                .cm-table__content {
                    box-sizing: border-box;
                    height: 47px;
                    padding-bottom: 0;
                }
            }
        }

        .cm-scrollable__tr {
            .cm-table__content {
                padding: 16px 10px;
                box-sizing: border-box;
            }

            &.cm-partial__row {
                .cm-table__content {
                    padding-top: 0;
                    box-sizing: border-box;
                    height: auto;
                }
            }
        }
    }

    &__part {
        position: relative;
        overflow: hidden;

        &--main {
            .#{$ns}scrollable__col {
                .cm-table__hidden-content {
                    .cm-tooltip__container {
                        left: unset !important;
                    }
                }
                .cm-virtual-list {
                    overflow: hidden !important;
                }
            }
        }

        &--thead {
            margin-bottom: 10px;
        }

        .cm-scrollable__content {
            @include respond-gte(large) {
                max-height: 70vh !important;
            }
        }
    }

    .cm-shadow--top {
        box-shadow: 0px -3px 3px 0px rgba(0, 0, 0, 0.07);
    }

    &--horizontal {
        .cm-scrollable__vb-content {
            position: relative;

            .vb-visible {
                overflow: visible !important;
                position: static !important;
            }
        }
    }

    &__content.cm-table--flat > .cm-virtual-list {
        max-height: inherit !important;
    }

}

.cm-table__content--editable {
    padding: 0 5px !important;

    input {
        padding-top: 10px;
        border: 0;
        text-align: inherit;
    }

    .cell-invalid {
        background-color: $color-error;
        border-radius: 50px;
        margin: 0 auto;
        padding: 1px;
        position: relative;
        z-index: 1;
    }
}
