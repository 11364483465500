$sidebar-bg-color: $off-canvas-background !default;
$sidebar-color: #fff !default;
$sidebar-roll-indicator-shadow: inset -20px 0 30px 0 rgba(0, 0, 0, 0.2) !default;
$sidebar-roll-indicator-background: #fff !default;

.#{$ns}sidebar {
    &__container {
        @include respond-gt(medium) {
            height: calc(100% - 120px);
        }
    }

    background-color: $sidebar-bg-color;
    color: $sidebar-color;
    padding: 20px 20px 0 10px;
    box-sizing: border-box;
    z-index: 10;

    .simplebar-mask {
        // Safari only
        @media not all and (min-resolution: 0.001dpcm) {
            @supports (-webkit-appearance: none) {
                overflow: visible;
            }
        }
    }

    .simplebar-track {
        .simplebar-scrollbar.simplebar-visible {
            width: 4px;

            &::before {
                background: $sidebar-color;
                opacity: 1;
            }
        }

        &.simplebar-vertical {
            right: auto;
            left: -6px;
        }
    }

    button,
    a {
        color: $sidebar-color;
        text-decoration: none;
        outline: none;
    }

    @include respond-gt(medium) {
        box-shadow: $sidebar-roll-indicator-shadow;
        position: fixed;
        left: 0;
        top: 0;
        min-height: 100vh;
        width: 0;
        height: 100%;
        @include respond-gt(medium) {
            visibility: hidden;
            opacity: 0;
            transition: width 0.3s linear 0s;
        }
        &.is-open {
            width: 258px;
            visibility: visible;
            opacity: 1;
        }
    }
    @include respond(medium) {
        display: flex;
        align-items: center;
    }
    @include respond-lte(medium) {
        padding: 22px 20px 20px;
        height: 70px;
        position: relative;
    }

    img {
        max-width: 80%;
    }

    .vb-dragger {
        right: initial;
        left: 0;
    }

    &__rollOut {
        position: fixed;
        top: 50vh;
        left: 233px;

        &:after {
            position: absolute;
            top: -16px;
            content: '';
            display: block;
            width: 32px;
            height: 64px;
            background-color: $sidebar-roll-indicator-background;
            border-bottom-left-radius: 64px;
            border-top-left-radius: 64px;
            z-index: -1;
        }
    }

    &__icons-container {
        display: flex;
        flex-direction: row-reverse;
        width: 100%;

        .o-btn--link {
            padding-left: 17px;

            &:hover {
                color: $color-white;
            }

            @include respond-lt(medium) {
                display: none;
            }
        }
    }
}
