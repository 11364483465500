/*
 * hy.frontend.core
 * 
 * NOTE: this file is not subject to copyright transfer
 * 
 * UWAGA: plik nie objęty przekazaniem praw autorskich
 * 
 * 
 */





/*------------------------------------*\
    #LAYOUT GROUP [LG]
\*------------------------------------*/

////
/// LAYOUT GROUP [LG]
/// @group grid
/// @author sebastian.nowak
////

//* ------------------------------------- */
// LAYOUT GROUP
//* ------------------------------------- */

.l-group {
    display: table;
    position: relative;
    
    &--fluid {
        border-collapse: separate;
        box-sizing: border-box;
        
        > .l-group__element {
            &--addon {
                text-align: center;
                width: 1%;
                white-space: nowrap;
                vertical-align: middle;
            }

            &--main {
                width: 100%;
            }
        }
    }
    
    &--fixed {
        width: 100%;
        table-layout: fixed;
        border-collapse: separate;
        vertical-align: middle;

        > .l-group__element {
            width: 1%;
        }
    }

    &__element {
        display: table-cell;
    }
}

@include respond(medium) {
    .l-group-break-medium {
        > .l-group__element {
            display: block;
            width: 100%;
            white-space: normal;
            text-align: left;
        }
    }
}

@include respond-lte(small) {
    .l-group-break-small {
        display: block;
        
        > .l-group__element {
            display: block;
            width: 100%;
            white-space: normal;
            text-align: left;
        }
    }
}

@include respond-gte(large) {
    .l-group-break-large {
        display: block;

        > .l-group__element {
            display: block;
            width: 100%;
            white-space: normal;
            text-align: left;
        }
    }
}

