label,
.#{$form-label-class},
.o-#{$form-label-class} {
    @include respond(medium) {
        font-weight: $font-weight-normal;
    }
}
input[type='text'],
input[type='password'],
input[type='date'],
input[type='datetime'],
input[type='email'],
input[type='number'],
input[type='tel'],
input[type='time'],
input[type='url'],
textarea,
select {
    @include respond(small) {
        min-height: 40px;
    }
}
