/*
 * hy.frontend.core
 * 
 * NOTE: this file is not subject to copyright transfer
 * 
 * UWAGA: plik nie objęty przekazaniem praw autorskich
 * 
 * 
 */





/*------------------------------------*\
    #ICONS FONT
\*------------------------------------*/

////
/// ICONS-FONT
/// @group icons-font
/// @author marcin.krasowski
////

$icon-class: 'icon' !default;

$icon-xxsmall-size:  8 !default;
$icon-xsmall-size:  10 !default;
$icon-small-size:   12 !default;
$icon-medium-size:  14 !default;
$icon-size:         16 !default;
$icon-large-size:   22 !default;
$icon-xlarge-size:  32 !default;
$icon-xxlarge-size: 42 !default;

$icon-space:        10 !default;

@font-face {
    font-family: 'CustomIcons';
    src: url('../assets/fonts/CustomIcons.woff') format('woff');
}

/// creates *@font-face* css for icons set
@mixin font-styles {
    font-family: 'CustomIcons';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: size($icon-size);
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    // speak: none; // only necessary if not using the private unicode range (firstGlyph option)
    text-decoration: none;
    text-transform: none;
    vertical-align: middle;
    line-height: 1;
}




/*------------------------------------*\
    #ICON UTILS
\*------------------------------------*/

.g-#{$icon-class} {
    &:before, &:after {
        @include font-styles;
    }
    &:before {
        display: inline-block;
    }
    &:after {
        display: none;
    }
}

.g-#{$icon-class}--before {
    &:before {
        display: inline-block;
        margin-right: size($icon-space);
    }
    &:after {
        display: none;
    }
}

.g-#{$icon-class}--after {
    &:before {
        display: none;
    }
    &:after {
        display: inline-block;
        margin-left: size($icon-space);
    }
}

.g-#{$icon-class}--only {
    display: inline-block;
}

.g-#{$icon-class}--xxs {
    &:before, &:after {
        font-size: size($icon-xxsmall-size) !important;
    }
}

.g-#{$icon-class}--xs {
    &:before, &:after {
        font-size: size($icon-xsmall-size) !important;
    }
}

.g-#{$icon-class}--s {
    &:before, &:after {
        font-size: size($icon-small-size) !important;
    }
}
.g-#{$icon-class}--m {
    &:before, &:after {
        font-size: size($icon-medium-size) !important;
    }
}
.g-#{$icon-class}--l {
    &:before, &:after {
        font-size: size($icon-large-size) !important;
    }
}

.g-#{$icon-class}--xl {
    &:before, &:after {
        font-size: size($icon-xlarge-size) !important;
    }
}

.g-#{$icon-class}--xxl {
    &:before, &:after {
        font-size: size($icon-xxlarge-size) !important;
    }
}



/*------------------------------------*\
    #ICON SELECTORS
\*------------------------------------*/

//TODO docs
@function icon-char($filename) {
    $char: '';
    
        @if $filename == alert {
            $char: '\E001';
        }
        @if $filename == archive {
            $char: '\E002';
        }
        @if $filename == arrow-down {
            $char: '\E003';
        }
        @if $filename == arrow {
            $char: '\E004';
        }
        @if $filename == arrow_down {
            $char: '\E005';
        }
        @if $filename == arrow_long {
            $char: '\E006';
        }
        @if $filename == arrow_short {
            $char: '\E007';
        }
        @if $filename == arrow_up {
            $char: '\E008';
        }
        @if $filename == backspace {
            $char: '\E009';
        }
        @if $filename == button_down {
            $char: '\E00A';
        }
        @if $filename == button_up {
            $char: '\E00B';
        }
        @if $filename == checkmark {
            $char: '\E00C';
        }
        @if $filename == chevron_double_up {
            $char: '\E00D';
        }
        @if $filename == chevron_left {
            $char: '\E00E';
        }
        @if $filename == chevron_right {
            $char: '\E00F';
        }
        @if $filename == chevron_up {
            $char: '\E010';
        }
        @if $filename == close-bracket {
            $char: '\E011';
        }
        @if $filename == close {
            $char: '\E012';
        }
        @if $filename == company_details_1 {
            $char: '\E013';
        }
        @if $filename == company_details_2 {
            $char: '\E014';
        }
        @if $filename == company_details_3 {
            $char: '\E015';
        }
        @if $filename == company_details_4 {
            $char: '\E016';
        }
        @if $filename == company_details_5 {
            $char: '\E017';
        }
        @if $filename == company_details_6 {
            $char: '\E018';
        }
        @if $filename == company_details_7 {
            $char: '\E019';
        }
        @if $filename == company_details_8 {
            $char: '\E01A';
        }
        @if $filename == company_details_9 {
            $char: '\E01B';
        }
        @if $filename == datapicker {
            $char: '\E01C';
        }
        @if $filename == deleted {
            $char: '\E01D';
        }
        @if $filename == divide {
            $char: '\E01E';
        }
        @if $filename == documents {
            $char: '\E01F';
        }
        @if $filename == done {
            $char: '\E020';
        }
        @if $filename == dots {
            $char: '\E021';
        }
        @if $filename == down {
            $char: '\E022';
        }
        @if $filename == download {
            $char: '\E023';
        }
        @if $filename == edit {
            $char: '\E024';
        }
        @if $filename == error {
            $char: '\E025';
        }
        @if $filename == exclamation {
            $char: '\E026';
        }
        @if $filename == expand-plus {
            $char: '\E027';
        }
        @if $filename == eye {
            $char: '\E028';
        }
        @if $filename == filter {
            $char: '\E029';
        }
        @if $filename == filters {
            $char: '\E02A';
        }
        @if $filename == generated {
            $char: '\E02B';
        }
        @if $filename == hyperlink {
            $char: '\E02C';
        }
        @if $filename == i {
            $char: '\E02D';
        }
        @if $filename == info {
            $char: '\E02E';
        }
        @if $filename == link {
            $char: '\E02F';
        }
        @if $filename == menu {
            $char: '\E030';
        }
        @if $filename == minus-2 {
            $char: '\E031';
        }
        @if $filename == minus {
            $char: '\E032';
        }
        @if $filename == move {
            $char: '\E033';
        }
        @if $filename == no_documents {
            $char: '\E034';
        }
        @if $filename == notifications_1 {
            $char: '\E035';
        }
        @if $filename == notifications_2 {
            $char: '\E036';
        }
        @if $filename == open-bracket {
            $char: '\E037';
        }
        @if $filename == overview_1 {
            $char: '\E038';
        }
        @if $filename == overview_2 {
            $char: '\E039';
        }
        @if $filename == overview_3 {
            $char: '\E03A';
        }
        @if $filename == overview_4 {
            $char: '\E03B';
        }
        @if $filename == overview_5 {
            $char: '\E03C';
        }
        @if $filename == overview_6 {
            $char: '\E03D';
        }
        @if $filename == padlock {
            $char: '\E03E';
        }
        @if $filename == padlock_unlock {
            $char: '\E03F';
        }
        @if $filename == questionmark {
            $char: '\E040';
        }
        @if $filename == reload {
            $char: '\E041';
        }
        @if $filename == search {
            $char: '\E042';
        }
        @if $filename == square-dot {
            $char: '\E043';
        }
        @if $filename == square {
            $char: '\E044';
        }
        @if $filename == star-contur {
            $char: '\E045';
        }
        @if $filename == star-whole {
            $char: '\E046';
        }
        @if $filename == tooltip {
            $char: '\E047';
        }
        @if $filename == trashbin {
            $char: '\E048';
        }
        @if $filename == up {
            $char: '\E049';
        }
        @if $filename == user {
            $char: '\E04A';
        }
        @if $filename == value {
            $char: '\E04B';
        }
        @if $filename == view {
            $char: '\E04C';
        }

    @return $char;
}

//TODO docs
@mixin generate-icon($filename, $param: 'both') {
    @if ($param == 'before' or $param == 'both') {
        &:before {
            content: icon-char($filename);
        }
    }
    @if ($param == 'after' or $param == 'both') {
        &:after {
            content: icon-char($filename);
        }
    }
}

//TODO docs
@mixin icon($filename, $param: 'both') {
    @if ($param == 'both') {
        &:before, &:after {
            @include font-styles;
            content: icon-char($filename);
        }
    }
    @if ($param == 'before') {
        &:before {
            @include font-styles;
            content: icon-char($filename);
        }
    }
    @if ($param == 'after') {
        &:after {
            @include font-styles;
            content: icon-char($filename);
        }
    }
}

//TODO docs
@mixin icon-style($param: '') {

    &:before, &:after {
        @content;
    }

    @if $param == 'before' {
        &:before {
            display: inline-block;
        }

        &:after {
            display: none;
        }
    }

    @if $param == 'after' {
        &:before {
            display: none;
        }

        &:after {
            display: inline-block;
        }
    }


}

//TODO docs
@mixin icon-hide($param: '') {
    @if $param == 'before' {
        &:before {
            display: none;
        }
    }
    @else if $param == 'after' {
        &:after {
            display: none;
        }
    }
    @else {
        &:before, &:after {
            display: none !important;
        }
    }
}






/*------------------------------------*\
    #ICON LOOP
\*------------------------------------*/

.g-#{$icon-class}--alert {
    @include generate-icon(alert);
}
.g-#{$icon-class}--archive {
    @include generate-icon(archive);
}
.g-#{$icon-class}--arrow-down {
    @include generate-icon(arrow-down);
}
.g-#{$icon-class}--arrow {
    @include generate-icon(arrow);
}
.g-#{$icon-class}--arrow_down {
    @include generate-icon(arrow_down);
}
.g-#{$icon-class}--arrow_long {
    @include generate-icon(arrow_long);
}
.g-#{$icon-class}--arrow_short {
    @include generate-icon(arrow_short);
}
.g-#{$icon-class}--arrow_up {
    @include generate-icon(arrow_up);
}
.g-#{$icon-class}--backspace {
    @include generate-icon(backspace);
}
.g-#{$icon-class}--button_down {
    @include generate-icon(button_down);
}
.g-#{$icon-class}--button_up {
    @include generate-icon(button_up);
}
.g-#{$icon-class}--checkmark {
    @include generate-icon(checkmark);
}
.g-#{$icon-class}--chevron_double_up {
    @include generate-icon(chevron_double_up);
}
.g-#{$icon-class}--chevron_left {
    @include generate-icon(chevron_left);
}
.g-#{$icon-class}--chevron_right {
    @include generate-icon(chevron_right);
}
.g-#{$icon-class}--chevron_up {
    @include generate-icon(chevron_up);
}
.g-#{$icon-class}--close-bracket {
    @include generate-icon(close-bracket);
}
.g-#{$icon-class}--close {
    @include generate-icon(close);
}
.g-#{$icon-class}--company_details_1 {
    @include generate-icon(company_details_1);
}
.g-#{$icon-class}--company_details_2 {
    @include generate-icon(company_details_2);
}
.g-#{$icon-class}--company_details_3 {
    @include generate-icon(company_details_3);
}
.g-#{$icon-class}--company_details_4 {
    @include generate-icon(company_details_4);
}
.g-#{$icon-class}--company_details_5 {
    @include generate-icon(company_details_5);
}
.g-#{$icon-class}--company_details_6 {
    @include generate-icon(company_details_6);
}
.g-#{$icon-class}--company_details_7 {
    @include generate-icon(company_details_7);
}
.g-#{$icon-class}--company_details_8 {
    @include generate-icon(company_details_8);
}
.g-#{$icon-class}--company_details_9 {
    @include generate-icon(company_details_9);
}
.g-#{$icon-class}--datapicker {
    @include generate-icon(datapicker);
}
.g-#{$icon-class}--deleted {
    @include generate-icon(deleted);
}
.g-#{$icon-class}--divide {
    @include generate-icon(divide);
}
.g-#{$icon-class}--documents {
    @include generate-icon(documents);
}
.g-#{$icon-class}--done {
    @include generate-icon(done);
}
.g-#{$icon-class}--dots {
    @include generate-icon(dots);
}
.g-#{$icon-class}--down {
    @include generate-icon(down);
}
.g-#{$icon-class}--download {
    @include generate-icon(download);
}
.g-#{$icon-class}--edit {
    @include generate-icon(edit);
}
.g-#{$icon-class}--error {
    @include generate-icon(error);
}
.g-#{$icon-class}--exclamation {
    @include generate-icon(exclamation);
}
.g-#{$icon-class}--expand-plus {
    @include generate-icon(expand-plus);
}
.g-#{$icon-class}--eye {
    @include generate-icon(eye);
}
.g-#{$icon-class}--filter {
    @include generate-icon(filter);
}
.g-#{$icon-class}--filters {
    @include generate-icon(filters);
}
.g-#{$icon-class}--generated {
    @include generate-icon(generated);
}
.g-#{$icon-class}--hyperlink {
    @include generate-icon(hyperlink);
}
.g-#{$icon-class}--i {
    @include generate-icon(i);
}
.g-#{$icon-class}--info {
    @include generate-icon(info);
}
.g-#{$icon-class}--link {
    @include generate-icon(link);
}
.g-#{$icon-class}--menu {
    @include generate-icon(menu);
}
.g-#{$icon-class}--minus-2 {
    @include generate-icon(minus-2);
}
.g-#{$icon-class}--minus {
    @include generate-icon(minus);
}
.g-#{$icon-class}--move {
    @include generate-icon(move);
}
.g-#{$icon-class}--no_documents {
    @include generate-icon(no_documents);
}
.g-#{$icon-class}--notifications_1 {
    @include generate-icon(notifications_1);
}
.g-#{$icon-class}--notifications_2 {
    @include generate-icon(notifications_2);
}
.g-#{$icon-class}--open-bracket {
    @include generate-icon(open-bracket);
}
.g-#{$icon-class}--overview_1 {
    @include generate-icon(overview_1);
}
.g-#{$icon-class}--overview_2 {
    @include generate-icon(overview_2);
}
.g-#{$icon-class}--overview_3 {
    @include generate-icon(overview_3);
}
.g-#{$icon-class}--overview_4 {
    @include generate-icon(overview_4);
}
.g-#{$icon-class}--overview_5 {
    @include generate-icon(overview_5);
}
.g-#{$icon-class}--overview_6 {
    @include generate-icon(overview_6);
}
.g-#{$icon-class}--padlock {
    @include generate-icon(padlock);
}
.g-#{$icon-class}--padlock_unlock {
    @include generate-icon(padlock_unlock);
}
.g-#{$icon-class}--questionmark {
    @include generate-icon(questionmark);
}
.g-#{$icon-class}--reload {
    @include generate-icon(reload);
}
.g-#{$icon-class}--search {
    @include generate-icon(search);
}
.g-#{$icon-class}--square-dot {
    @include generate-icon(square-dot);
}
.g-#{$icon-class}--square {
    @include generate-icon(square);
}
.g-#{$icon-class}--star-contur {
    @include generate-icon(star-contur);
}
.g-#{$icon-class}--star-whole {
    @include generate-icon(star-whole);
}
.g-#{$icon-class}--tooltip {
    @include generate-icon(tooltip);
}
.g-#{$icon-class}--trashbin {
    @include generate-icon(trashbin);
}
.g-#{$icon-class}--up {
    @include generate-icon(up);
}
.g-#{$icon-class}--user {
    @include generate-icon(user);
}
.g-#{$icon-class}--value {
    @include generate-icon(value);
}
.g-#{$icon-class}--view {
    @include generate-icon(view);
}
