@function fillIconColor($color) {
    @return "fill='%23#{str-slice(ie-hex-str($color), 4)}'";
}

@mixin loader-icon($color) {
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' id='L9' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 100 100' enable-background='new 0 0 0 0' xml:space='preserve'%3E%3Cpath #{fillIconColor($color)} d='M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50'%3E%3CanimateTransform attributeName='transform' attributeType='XML' type='rotate' dur='1s' from='0 50 50' to='360 50 50' repeatCount='indefinite' /%3E%3C/path%3E%3C/svg%3E");
}

.#{$ns}load-indicator {
    &:not(.is-loaded) {
        position: relative;
        overflow: hidden;

        &::before {
            content: '';
            z-index: 1;
        }
    }

    &::before {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        z-index: 5;
        background-color: rgba($color-white, 0.9);
        background-size: 4rem;
        background-repeat: no-repeat;
        background-position: center;
        @include loader-icon($body-color);
    }

    &--auto {
        &::before {
            background-size: auto 100%;
        }
    }

    &--sidebar-color {
        &::before {
            background-color: rgba($sidebar-bg-color, 0.9);
            @include loader-icon($color-white);
        }
    }

    &--sidebar-filter-color {
        &::before {
            background-color: rgba($sidebar-roll-indicator-background, 0.9);
            @include loader-icon($color-white);
        }
    }

    &--table {
        @include transition-standard(padding-bottom);
        padding-bottom: 0;

        &:not(.is-loaded) {
            padding-bottom: 40px;
        }

        &::before {
            height: 40px;
            bottom: 0;
            top: auto;
            background-size: 20px;
            background-color: $color-white;
        }
    }

    &.section__element,
    &.section,
    &.section__content {
        min-height: 50px;
        display: block;
    }

    .cm-table__content .cm-table__container--hover > &,
    .cm-table__content > &,
    td > & {
        height: 16px;
        overflow: hidden;

        &::before {
            background-size: 16px;
            background-color: transparent;
            width: 40px;
            position: static;
            display: inline-block;
            height: 16px;
        }
    }

    &.o-btn {
        &::before {
            border-radius: 30px;
            background-size: 20px;
        }
    }

    &.#{$ns}autocomplete {
        min-height: 1px;

        &::before {
            background-size: 20px;
            width: 40px;
            left: calc(100% - 40px);
        }
    }

    .expander__item:not(.is-expanded) > & {
        &::before {
            display: none;
        }
    }
}
