.section {
    &--gray {
        background-color: $color-gray1;
        padding: 15px;
    }
    &--white {
        background-color: $color-white;
        padding: 15px;
    }
}
.section-minus-margins {
    background-color: $color-gray1;
    margin: 20px -30px;
    padding: 30px 70px;
    @include respond-lte(small) {
        margin: 20px -20px;
        padding: 30px 40px;
    }
}
