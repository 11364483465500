.#{$ns}loader {
    background: rgba(255, 255, 255, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
    opacity: 0;
    visibility: hidden;
    @include transition-standard(opacity, visibility);
    &.is-visible {
        opacity: 1;
        visibility: visible;
    }
}
