/*
 * hy.frontend.core
 * 
 * NOTE: this file is not subject to copyright transfer
 * 
 * UWAGA: plik nie objęty przekazaniem praw autorskich
 * 
 * 
 */





/*------------------------------------*\
    #ACCESSIBILITY
\*------------------------------------*/

////
/// ACCESSIBILITY
/// @group accessibility
/// @author marcin.krasowski
////

/// outline width for focused element
$acc-width: 3 !default;
/// outline offset for focused element
$acc-offset: 3 !default;

/// hide content offscreen while leaving it available for screenreaders
/// @param {String} $type [absolute] - method of hiding content, possible values are:
/// * *clip*
/// * *indent* or *text-indent*
/// * *absolute*
@mixin acc-hide($type: absolute) {
    @if $type == clip {
        border: none !important;
        clip: rect(0 0 0 0) !important;
        height: 1px !important;
        margin: -1px !important;
        overflow: hidden !important;
        padding: 0 !important;
        position: absolute !important;
        width: 1px !important;
    } @else if $type == indent or $type == text-indent {
        text-indent: -9999px !important;
    } @else if $type == absolute {
        position: absolute !important;
        left: -9999px !important;
    }
}


/// adds an outline to focused elements
@mixin acc-focus() {
    outline: size($acc-width) $acc-color solid;
    outline-offset: size($acc-offset);
}


// class for hiding content
.u-acc {
    &-hide {
        @include acc-hide;
    }
    
    &-clip {
        @include acc-hide(clip);
    }
    
    &-txt--show {
        display: inline;
        .is-open &, .is-active &, .is-expanded &, .is-selected &, :checked ~ label &, :checked ~ .o-ci-label & {
            display: none;
        }
    }
    
    &-txt--hide {
        display: none;
        .is-open &, .is-active &, .is-expanded &, .is-selected &, :checked ~ label &, :checked ~ .o-ci-label & {
            display: inline;
        }
    }
}


:focus {
    outline: none;
}

input, textarea, select, button, a, [tabindex] {
    &:focus {
        @include acc-focus;
    }
    &.u-hiding-focus:focus {
         outline: none;
    }
}
