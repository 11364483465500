.#{$ns}sign-in {
    &__bg {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: url('../assets/img/log-in-background.jpg') no-repeat center top;
        background-size: cover;

        &::after {
            content: '';
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            opacity: $sign-in-background-opacity;
            background: $body-color;
        }
    }

    &__box {
        position: absolute;
        width: 100%;
        left: 0;
        top: 50vh;
        transform: translateY(-50%);
        padding: 100px 40px 40px;

        form {
            position: relative;
            padding: 0;
        }
    }

    &__content {
        max-width: 500px;
        margin: 0 auto;
        padding: 60px 40px;
        background-color: $color-white;
    }

    &__logo {
        display: block;
        margin: 0 auto;
        max-width: 300px;
    }

    &__buttons {
        display: flex;
        justify-content: flex-end;
    }

    &__submit {
        background: $sign-in-button-background;
        border-color: $sign-in-button-border-color;

        &:hover,
        &:active,
        &:focus,
        &:focus:hover {
            background: $sign-in-button-hover-background;
            border-color: $sign-in-button-hover-border-color;
        }
    }
}
