.#{$ns}orb-navigation {
    padding-left: 15px;
    position: relative;
    &,
    li {
        font-size: 14px;
    }
    .cm-orb--m .cm-orb__inner--icon::before {
        font-size: 16px;
    }
    .o-btn {
        color: $navigation__button-color;
        margin-top: 10px;
        &:hover {
            color: $navigation__button-hover;
        }
    }
    @include respond-gt(medium) {
        margin-left: 30px;
        padding: 10px 0 10px 15px;
        .o-navigation__list {
            display: none;
            position: absolute;
            right: 0;
            margin-top: 10px;
            opacity: 0;
            transition: opacity 0.3s ease;
            z-index: 100;
            max-width: 300px;
            &.o-navigation__lvl1__list::after {
                right: 10px;
                left: auto;
            }
            .o-navigation__item {
                a:not(.o-btn) {
                    color: $link-color;
                }
            }
        }
        &:hover,
        .o-navigation__list:hover {
            .o-navigation__list {
                display: block;
                opacity: 1;
                box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.3);
                background-color: $color-white;
            }
        }
        .o-navigation__item--container {
            padding: 0 10px;
        }
    }
    @include respond-lte(medium) {
        .o-navigation__lvl1__item {
            background: none;
            margin: 0;
            a {
                text-decoration: none;
            }
        }
        .o-navigation__lvl1__link {
            padding: 15px 0;
        }
        .o-navigation__lvl1__list .o-navigation__item:hover .o-navigation__lvl1__item__link {
            background: none;
        }
        .cm-btn--secondary.o-btn {
            max-width: 999em;
            background: none;
            color: $color-white;
            &,
            &:hover {
                border-color: $color-white;
            }
        }
        .o-btn + .o-btn {
            margin-top: 20px;
        }
    }
    @include respond(small) {
        .cm-btn--secondary.o-btn {
            line-height: 30px;
        }
    }
    &__nav {
        @include respond-lte(medium) {
            a,
            .o-btn--link {
                color: inherit;
            }
            .o-navigation__item {
                color: $color-white;
                padding: 0 20px;
            }
            .o-navigation__lvl1__list {
                box-shadow: none;
            }
        }
        &.o-navigation.o-off-canvas__content {
            @include respond(small) {
                padding: 0;
            }
        }
        .o-btn {
            color: $navigation__button-color;
        }
    }

    &__user-info {
        padding: 0 10px 15px;
        color: $navigation__user-info-color;
        &__name,
        &__email {
            display: -webkit-box;
            overflow: hidden;
            -webkit-box-orient: vertical;
            word-break: break-all;
        }
        &__name {
            margin-bottom: 0;
        }
        &__email {
            font-size: 0.9em;
        }
        &.o-navigation__item {
            @include respond-lte(medium) {
                margin-bottom: 10px;
                padding-top: 20px;
            }
        }
    }
}
.#{$ns}orb--navigation {
    &__user-icon {
        background-color: $navigation__user-icon-background;
        .g-icon {
            color: $navigation__user-icon-color;
        }
        .cm-orb {
            background-color: $navigation__counter-icon-background !important;
            span {
                color: $navigation__counter-icon-color !important;
            }
        }
    }
}
