.#{$ns}download {
    position: relative;
    &__menu {
        position: absolute;
        box-sizing: border-box;
        min-width: 150px;
        padding: 15px;
        box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.3);
        background-color: $color-white;
        right: 0;
        top: calc(100% + 5px);
        @include respond-lte(medium) {
            top: auto;
            bottom: calc(100% + 5px);
        }
        li + li {
            margin-top: 10px;
        }
        .o-btn--link {
            text-align: left;
            max-width: 80vw;
            white-space: nowrap;
        }
    }
}
