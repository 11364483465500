.consent-page-bg {
    position: fixed;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #f5f5f5;
    box-sizing: border-box;
}
.consent-page {
    position: absolute;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    left: 0;
    top: 0;
    box-sizing: border-box;
    padding: 80px;
    @include respond(small) {
        padding: 20px;
    }
    &__content {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 800px;
        height: 100%;
        min-height: 500px;
        margin: 0 auto;
        padding: 60px 80px;
        box-shadow: 0 10px 20px #e5e8f2;
        background-color: $color-white;
        box-sizing: border-box;
        @include respond(small) {
            padding: 20px;
            min-height: 100%;
        }
        &__main {
            display: block;
            flex-grow: 1;
            width: 100%;
            overflow-y: hidden;
            margin-bottom: 40px;
            box-sizing: border-box;
            @include respond(small) {
                margin-bottom: 20px;
            }
            .vb-content > * {
                padding-right: 10px;
            }
        }
    }
    &__form {
        &__actions {
            margin-top: 20px;
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-end;
            @include respond(small) {
                justify-content: center;
            }
        }
        &__btn {
            @include respond(small) {
                &:first-child {
                    margin-bottom: 10px;
                }
            }
        }
    }
}
