/*
 * hy.frontend.core
 *
 * NOTE: this file is not subject to copyright transfer
 *
 * UWAGA: plik nie objęty przekazaniem praw autorskich
 *
 *
 */





////
/// OPTIONAL CSS
/// @group tools
/// @author kaja.zaleska-nguyen
////

/// Checks whether value is not empty or null, then applies it to property.
/// Used to avoid excess CSS.
@mixin optional($a, $b) {
    @if $b {
        #{$a}: #{$b};
    }
}
