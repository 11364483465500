/*
 * hy.frontend.core
 * 
 * NOTE: this file is not subject to copyright transfer
 * 
 * UWAGA: plik nie objęty przekazaniem praw autorskich
 * 
 * 
 */





/*------------------------------------*\
    #FONT-FACE [FF]
\*------------------------------------*/

////
/// FONT-FACE
/// @group font-face
/// @author marcin.krasowski
////

/// enable using EOT extension for custom fonts
$font-face-eot: true !default;
/// enable using SVG extension for custom fonts
$font-face-svg: true !default;
/// enable using WOFF extension for custom fonts
$font-face-woff: true !default;
/// enable using TTF extension for custom fonts
$font-face-ttf: true !default;

/// custom light font name
$custom-font-light: 'Arial' !default;
/// custom light italic font name
$custom-font-light-italic: 'Arial' !default;
/// custom regular font name
$custom-font-regular: 'Arial' !default;
/// custom regular italic font name
$custom-font-regular-italic: 'Arial' !default;
/// custom bold font name
$custom-font-bold: 'Arial' !default;
/// custom bold italic font name
$custom-font-bold-italic: 'Arial' !default;

$custom-font-path: '../fonts/' !default;

/// list assigning specific files and properties to font names
/// in order of:
/// * font name
/// * path and filename to font files, relative to *fonts* folder
/// * font weight
/// * font style
/// * SVG suffix
$custom-fonts: $custom-font-light          'Arial' 300 normal cf_light,
               $custom-font-light-italic   'Arial' 300 italic cf_lightitalic,
               $custom-font-regular        'Arial' 400 normal cf_regular,
               $custom-font-regular-italic 'Arial' 400 italic cf_regularitalic,
               $custom-font-bold           'Arial' 700 normal cf_bold,
               $custom-font-bold-italic    'Arial' 700 italic cf_bolditalic
               !default;

/// creates *@font-face* css by concatenating url for every enabled extension
/// @param {String} $font-name - font name
/// @param {String} $font-file - font file
/// @param {Number} $font-weight [normal] - font weight
/// @param {String} $font-style [normal] - font style
/// @param {String} $font-svg-suffix [''] - svg suffix
/// @see $custom-fonts
@mixin get-font-face($font-name, $font-file, $font-weight: normal, $font-style: normal, $svg-suffix: '') {
    @font-face {
        font-family: $font-name;
        font-weight: $font-weight;
        font-style:  $font-style;
        
        $string: '';
        
        // if extension is enabled
        @if $font-face-eot == true {
            // add comma if url is not empty (if concatenating another extension)
            $comma: if($string == '', '', ', ');
            // add full font url
            $string: #{$string} unquote($comma) url('#{$custom-font-path}#{$font-file}.eot?iefix') format('embedded-opentype');
        }
        @if $font-face-svg == true {
            $comma: if($string == '', '', ', ');
            $string: #{$string} unquote($comma) url('#{$custom-font-path}#{$font-file}.svg?#{$svg-suffix}') format('svg');
        }
        @if $font-face-woff == true {
            $comma: if($string == '', '', ', ');
            $string: #{$string} unquote($comma) url('#{$custom-font-path}#{$font-file}.woff') format('woff');
        }
        @if $font-face-ttf == true {
            $comma: if($string == '', '', ', ');
            $string: #{$string} unquote($comma) url('#{$custom-font-path}#{$font-file}.ttf') format('truetype');
        }
        
        // set src to concatenated url, preceded by **local('?')**
        @if $string != '' {
            src: local('?'), $string;
        }
    }
}

/// parses font list, and for each font creates *@font-face* css
/// @param {List} $font-list - list with defined fonts
/// @see $custom-fonts
@mixin font-face($list) {
    @each $font in $list {
        $font-name:   nth($font, 1);
        $font-file:   nth($font, 2);
        $font-weight: nth($font, 3);
        $font-style:  nth($font, 4);

        @if $font-face-svg == true {
            $svg-suffix: nth($font, 5);
            @include get-font-face($font-name, $font-file, $font-weight, $font-style, $svg-suffix);
        } @else {
            @include get-font-face($font-name, $font-file, $font-weight, $font-style);
        }
    }
}

@if($custom-fonts != 'none') {
    @include font-face($custom-fonts);
}
