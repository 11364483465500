$increment-icon-size: 30px;

.#{$ns}incrementer {
    &__wrapper {
        position: relative;
    }

    &__button {
        display: block;
        position: absolute;
        top: 50%;
        margin-top: -15px;
        width: $increment-icon-size;
        height: $increment-icon-size;
        line-height: $increment-icon-size;

        font-size: 15px;
        font-weight: $form-input-font-weight;
        text-align: center;

        cursor: pointer;
        user-select: none;

        &:last-child {
            right: 0;
        }

        @include respond-gte(large) {
            color: $form-input-color;
        }
    }

    input[readonly] {
        text-align: center;
        background-color: $form-input-background;
        color: $form-input-color;

        @include respond-lte(medium) {
            border-radius: 20px;
        }
    }
    input.isDisabled {
        color: #999999;
        background: #f6f6f6;
    }
}
