@import 'vue-select/dist/vue-select.css';

.cm-select {
    --vs-actions-padding: 5px 12px 5px 5px;
    --vs-border-radius: 15px;
    --vs-dropdown-option-color: #{$dropdown-tag-color};
    --vs-selected-color: #{$dropdown-tag-color};
    --vs-search-input-color: #{$dropdown-tag-color};
    --vs-dropdown-option--active-bg: #{$dropdown-hover-tag-background-color};
    --vs-dropdown-option--active-color: #{$color-white};
    --vs-dropdown-option-padding: 6px 10px;
    --vs-border-color: #{$color-gray3};
    --vs-dropdown-box-shadow: none;
    --vs-dropdown-bg: #{$color-white};
    --vs-dropdown-max-height: 500px;
    --vs-dropdown-z-index: 6;
    --vs-font-size: 14px;
    --vs-disabled-bg: #{$form-input-disabled-background};

    .v-select {
        font-family: $body-font-family;
    }

    .vs__selected-options {
        padding: unset;
    }

    .vs__selected {
        padding: 0 5px 0 10px;
        font-size: 14px;
        font-weight: 400;
        margin: unset;
        line-height: 28px;
        position: absolute;
        color: $color-black;
    }

    .vs__selected--ellipsis,
    .option-label__text {
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
        display: block;
    }

    .vs__selected--ellipsis {
        white-space: nowrap;
    }

    .vs--single.vs--loading .vs__selected,
    .vs--single.vs--open .vs__selected {
        opacity: 0.5;
    }

    .vs__open-indicator::before {
        content: '';
        border-width: 2px 2px 0 0;
        vertical-align: bottom;
        transform: rotate(135deg) translateX(-25%);
        border-color: $dropdown-toggle-open-indicator-border-color;
        border-style: solid;
        box-sizing: border-box;
        width: 10px;
        height: 10px;
        display: block;
    }

    .v-select input[type='search'] {
        width: 0;
        background: transparent;

        &,
        &:focus {
            height: 28px;
            padding: 0 15px 0 10px;
            margin: 0;
        }
    }

    .vs__dropdown-toggle {
        padding: unset;
        cursor: pointer;
        background-color: #{$color-white};
    }

    .vs--open .vs__dropdown-toggle {
        border-bottom-color: var(--vs-border-color);
        border-bottom-left-radius: var(--vs-border-radius);
        border-bottom-right-radius: var(--vs-border-radius);
    }

    .vs__dropdown-menu {
        padding: unset;
        border-radius: var(--vs-border-radius);
        border-top-style: solid;
        @include scrollbar($body-color, transparent, 4px, 4px, 4px);

        .o-btn--link {
            color: var(--vs-dropdown-option-color);
        }
    }

    .vs__dropdown-option {
        display: flex;
        justify-content: space-between;
        white-space: pre-wrap;

        &--selected {
            background-color: $dropdown-active-tag-background-color;
            color: $dropdown-active-tag-color;

            &.vs__dropdown-option--highlight {
                background: var(--vs-dropdown-option--active-bg);
                color: var(--vs-dropdown-option--active-color);
            }
        }
    }

    .vs--disabled {
        .vs__dropdown-toggle {
            background-color: #{$form-input-disabled-background};
        }
    
        .vs__selected {
            color: $form-input-disabled-color;
        }

        .vs__open-indicator::before {
            border-color: $form-input-disabled-color;
        }
    }

    &.is-dark {
        --vs-dropdown-bg: #{$dropdown-dark-menu-background-color};
        --vs-search-input-bg: #{$dropdown-dark-menu-background-color};
        --vs-selected-color: #{$dropdown-dark-menu-color};
        --vs-dropdown-option-color: #{$dropdown-dark-menu-color};
        --vs-dropdown-option--active-bg: #{$sidebar-dropdown-element-active};
        --vs-search-input-color: #{$color-black};

        .vs__open-indicator::before {
            border-color: $dropdown-dark-open-indicator-border-color;
        }

        .vs__dropdown-option {
            &--selected {
                background-color: $dropdown-dark-active-background-color;
                color: $dropdown-dark-active-tag-color;
            }
        }
    }

    &.is-error {
        --vs-border-color: #{$color-error};
        --vs-search-input-color: #{$color-error};
    }

    &.is--upwards {
        .vs__dropdown-menu {
            top: auto;
            bottom: calc(100% - var(--vs-border-width));
        }
    }

    .vs--multiple {
        .vs__selected {
            border: none;
            background: none;
            display: none;

            &:first-child {
                display: flex;
                width: 100%;
            }
        }
    }
}
