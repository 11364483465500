.#{$ns}exposure {
    &__company-details {
        min-height: 100px;
    }
    &__exposure-details {
        min-height: 100px;
        a.#{$ns}expand-trigger {
            &:hover {
                cursor: pointer;
            }
        }
        &.#{$ns}table--mobile {
            min-height: 400px;
            tbody ul li {
                justify-content: flex-start;
                span {
                    width: 115px;
                    &:nth-child(2) {
                        font-weight: $font-weight-bold;
                        text-align: right;
                    }
                }
            }
            .expander {
                td:not(:first-child) {
                    .#{$ns}expand-trigger {
                        float: right;
                    }
                }
            }
            .#{$ns}expand-trigger {
                display: table;
                & > span {
                    display: table-cell;
                    vertical-align: middle;
                }
                .#{$ns}table__span-array:not(:first-of-type) {
                    @include respond-lt(large) {
                        float: left;
                        width: 100%;
                        .g-icon {
                            padding-right: 5px;
                        }
                    }
                }
                &:not(:first-of-type) {
                    float: right;
                }
                span:not(.g-icon) {
                    min-width: 60px;
                }
            }
        }
    }
    &__financial-historic {
        .#{$ns}scrollable__content {
            td {
                background-color: $scrollable-table-td-background-color;
            }
        }
    }
    &__exposure-investment-performance {
        &__header {
            @include respond(small) {
                display: none;
            }
            &--no-wide {
                display: none;
                @include respond(small) {
                    display: block;
                }
            }
        }
    }
    &--stripped {
        table {
            tr {
                &:nth-of-type(odd) {
                    background-color: $table-tr-stripped-color-odd;
                }
                &:nth-of-type(even) {
                    background-color: $table-tr-stripped-color-even;
                }
            }
        }
    }
    &-admin {
        &__values {
            display: grid;
            grid-template-columns: min-content repeat(3, 1fr);
            grid-gap: 10px;
        }
    }
}
