/// Defines width on desktop viewport
$sidebar-filters-width-desktop: 477px;
/// Defines width on tablet viewport
$sidebar-filters-width-tablet: 495px;
/// Defines height of header
$sidebar-filters__header-height: 60px;
/// Defines height of footer
$sidebar-filters__footer-height: 60px;

.#{$ns}sidebar-filters {
    legend {
        width: 100%;
    }
    .cm-input {
        input::placeholder {
            color: $sidebar-filters__input-placeholder-color;
        }
    }
    .cm-checkbox,
    .cm-radio--yellow {
        input:not([disabled]):checked {
            & ~ .o-ci {
                &:before {
                    background-color: $sidebar-filters__checkbox-background !important;
                }
                &:after {
                    color: $sidebar-filters__checkbox-tick-color;
                }
            }
        }
    }
    .vb > .vb-dragger > .vb-dragger-styler {
        background-color: $sidebar-color !important;
    }
    .simplebar-track .simplebar-scrollbar::before {
        background-color: $sidebar-color;
    }
    .simplebar-track {
        .simplebar-scrollbar.simplebar-visible {
            width: 4px;
            &::before {
                background: $sidebar-color;
                opacity: 1;
            }
        }
        &.simplebar-vertical {
            right: auto;
            left: 5px;
        }
    }
    .o-btn--link {
        text-decoration: underline;
        font-weight: 700;
        color: $sidebar-filters__button--clear-color;
        &:hover {
            color: $sidebar-filters__button--clear-hover-color;
        }
    }
    &__mask {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        z-index: 99;
        background-color: rgba(0, 0, 0, 0.5);
    }
    &__container {
        padding: 0;
        z-index: 102;
        @include respond-gt(medium) {
            width: $sidebar-filters-width-desktop !important;
        }
        @include respond-gt(small) {
            width: $sidebar-filters-width-tablet;
        }
        &:not(.is-open) .cm-subfilters__item .el-picker-panel {
            display: none; // on IE calendar doesn't disappear when filters are closed
        }
        &.lock-shadow {
            box-shadow: -4px 0 4px rgba(0, 0, 0, 0.25);
        }
    }
    &__header {
        height: $sidebar-filters__header-height;
        width: 100%;
        position: absolute;
        top: 0;
        display: flex;
        align-items: center;
        padding: 0 20px;
        box-sizing: border-box;
        box-shadow: 0 4px 8px $sidebar-filters__box-shadow-color;
    }
    &__menu-item {
        display: flex;
        align-items: center;
        cursor: default;
        pointer-events: none;
        &.disabled {
            pointer-events: auto;
            span {
                cursor: pointer;
                &.cm-orb {
                    background-color: $sidebar-filters__menu-item-disabled-background !important;
                    &:before {
                        color: $sidebar-filters__menu-item-disabled-color;
                    }
                }
            }
        }
        .cm-orb {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: $sidebar-filters__menu-item-background !important;
            &:before {
                color: $sidebar-filters__menu-item-color;
            }
        }
    }
    &__title {
        font-size: 16px;
        font-weight: $font-weight-bold;
        color: $sidebar-color;
        margin: 0 20px 0 10px;
    }
    &__button-group {
        margin-left: auto;
    }
    &__lock-button {
        margin-right: 25px;
        .g-icon::before {
            font-size: 16px;
        }

        &.o-btn--transparent {
            &,
            &:hover,
            &:focus {
                color: $sidebar-filters__menu-item-background;
            }
        }

        &--unlock {
            &.o-btn--transparent {
                &,
                &:hover,
                &:focus {
                    color: $sidebar-color;
                }
            }
        }
    }
    &__close-button {
        &.o-btn--transparent {
            &,
            &:hover,
            &:focus {
                color: $sidebar-color;
            }
        }
    }
    &__content {
        box-sizing: border-box !important;
        &-wrapper {
            position: absolute;
            top: $sidebar-filters__header-height;
            width: 100%;
            overflow-y: auto;
            overflow-x: hidden;
            box-sizing: border-box;
            color: $sidebar-color;
            height: calc(100% - #{$sidebar-filters__header-height}) !important;
            @include scrollbar($sidebar-color, transparent, 4px, 2px);
            &.with-footer {
                height: calc(100% - #{$sidebar-filters__header-height} - #{$sidebar-filters__footer-height}) !important;
            }
            &.vb > .vb-dragger {
                right: 5px;
            }
            & > .simplebar-track.simplebar-vertical {
                left: auto;
                right: 5px;
            }
        }
    }
    &__footer {
        height: $sidebar-filters__footer-height;
        width: 100%;
        position: absolute;
        bottom: 0;
        background-color: $sidebar-filters__footer-color;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 20px;
        box-sizing: border-box;
        box-shadow: 0px -4px 8px $sidebar-filters__box-shadow-color;
        .l-row {
            width: calc(100% + 20px);
        }
        .o-btn {
            font-weight: 700;
            &:not([disabled]) {
                background-color: $sidebar-filters__button-background;
                color: $sidebar-filters__button-text-color !important;
                border: 2px solid $sidebar-filters__button-background;
                &:hover {
                    background-color: $sidebar-filters__button-background-hover;
                    border: 2px solid $sidebar-filters__button-background-hover;
                }
            }
            @include respond(small) {
                padding: 0 10px;
            }
            //TODO remove important from color
            &.cm-btn--border {
                border: 2px solid $sidebar-filters__button-border-color;
                color: $sidebar-filters__button-border-color !important;
                background-color: $sidebar-filters__button-border-background;
                &:hover {
                    border: 2px solid $sidebar-filters__button-border-color;
                    background-color: $sidebar-filters__button-border-color;
                    color: $sidebar-filters__button-border-background !important;
                }
            }
        }
    }
    &__toggleIconButton {
        margin-left: 20px;
        background-color: $sidebar-filters__toggle-icon-background !important;
        color: $sidebar-filters__toggle-icon-color;
        &:not([disabled]):hover {
            background-color: $sidebar-filters__toggle-icon-background-hover !important;
            box-shadow: none;
        }
    }
    &__menu-icon {
        display: flex;
        align-items: center;
        background: none;
        border: none;
    }

    .#{$custom-checkbox-class},
    .#{$custom-radio-class} {
        @include respond(small) {
            min-height: size(25);
            min-width: size(25);
            @include custom-input() {
                height: size(25);
                width: size(25);
            }
            @include custom-input-background() {
                height: size(25);
                width: size(25);
            }
            @include custom-input-icon() {
                left: size(8);
                top: size(8);
            }
            input ~ .#{$custom-input-label-class} {
                line-height: size(26);
                padding-left: size(10);
            }
        }
    }
    .#{$custom-checkbox-class} {
        @include respond(small) {
            @include custom-input-icon() {
                left: size(8);
                top: size(8);
            }
        }
    }
}
