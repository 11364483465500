/*------------------------------------*\
    #Navigation
\*------------------------------------*/
////
/// @group navigation
/// @author kaja.zaleska-nguyen
////


// use em-based width for xlarge mq
$navigation-xlarge-unlock-width: true !default;
$navigation-xlarge-width-em: 50em !default;

// use default styling from core
$navigation-default-styling: false !default;
$navigation-use-css-icons: false !default;
$navigation-cell-like-table: false !default;
$navigation-cell-valign: middle !default;
$navigation-list-horizontal-valign: middle !default;



/*------------------------------------*\
    #Navigation - COMMON
\*------------------------------------*/

.o-navigation {

    &-positioner {
        position: relative;
    }


    &__row {
        @include page-row;
    }

    @if $navigation-xlarge-unlock-width == true {
        &__row {
            @include respond(xlarge) {
                width: $page-width;
                min-width: $navigation-xlarge-width-em;
            }
        }
    }


    &__link {
        display: inline-block;
        text-decoration: none;
    }


    .o-navigation__parent > .o-navigation__cell .o-navigation__expand-link {
        width: 1px;
    }

    &__expand-arrow {
        display: inline-block;
        padding: 0 15px;

        &:before {
            display: inline-block;
        }


        @if $navigation-use-css-icons == true {
            &.o-navigation__expand-arrow--up {
                &:before {
                    content: '\25B2';
                }
            }

            &.o-navigation__expand-arrow--right {
                &:before {
                    content: '\25B6';
                }
            }

            &.o-navigation__expand-arrow--down {
                &:before {
                    content: '\25BC';
                }
            }

            &.o-navigation__expand-arrow--left {
                &:before {
                    content: '\25C0';
                }
            }
        }
    }


    @if $navigation-cell-like-table == true {
        &__cell {
            display: table;
            width: 100%;

            &__part {
                display: table-cell;
                vertical-align: $navigation-cell-valign;
            }
        }
    }

    .o-navigation--table.o-navigation__cell {
        display: table;
        width: 100%;

        .o-navigation__cell__part {
            display: table-cell;
            vertical-align: $navigation-cell-valign;
        }
    }

    &__cell__part {
        text-decoration: none;
    }



    @if $navigation-default-styling == true {
        &__item {
            border: 1px #ccc solid;
        }
        &__link {
            padding: 20px;
        }
        &__expand-arrow {
            padding: 0 15px;
        }
        &__cell__part, &__child, &__list {
            background: white;
        }
    }


}





/*------------------------------------*\
    #Navigation - DESKTOP
\*------------------------------------*/

@include respond-gt(medium) {

    .o-navigation {
        .o-navigation__child {
            display: none;
            
            &:hover {
                display: block;
            }
        }
        .o-navigation__cell:hover + .o-navigation__child,
        .o-navigation__cell.is-active + .o-navigation__child {
            display: block;
        }

        &__trigger {
            display: none;
        }

        // horizontal list
        &__list-h {
            display: table;
            width: 100%;

            & > .o-navigation__item {
                box-sizing: border-box;
                display: table-cell;
                vertical-align: $navigation-list-horizontal-valign;
            }
        }

        // child navigation direction
        .o-navigation__dir--right {
            position: absolute;
            top: 0;
            left: 100%;
            z-index: 9;
        }
        .o-navigation__dir--down {
            position: absolute;
            left: 0;
            right: 0;
            z-index: 9;
        }

        .o-navigation__dir--left {
            position: absolute;
            top: 0;
            right: 100%;
            z-index: 9;
        }
    }

}



/*------------------------------------*\
    #Navigation - MOBILE
\*------------------------------------*/

@include respond-lte(medium) {

    .o-navigation {

        //tmp
        .l-page-row {
            padding: 0;
        }

        &__trigger {
            display: block;
            width: 60px;
            height: 60px;
            border: 1px #ccc solid;
            margin: 10px;
            position: relative;
            overflow: hidden;//firefox outline bug

            &:before {
                content: '\2630';
                position: absolute;
                top: 11px;
                left: 0;
                width: 60px;
                text-align: center;
                font-size: 30px;
                @include respond-gt(small) {
                    margin-left: 17px;
                }
            }
        }

        .o-navigation__lvl2 .o-navigation__cell__part {
            background: #eee;
        }
        .o-navigation__lvl3 .o-navigation__cell__part {
            background: #ddd;
        }


    }

}



