.#{$ns}list-expanded {
    border-bottom: 1px solid $color-gray2;
    &__item {
        display: flex;
        display: -webkit-flex;
        justify-content: space-between;
        -webkit-justify-content: space-between;
        width: 100%;
        &.cm-table__form-row {
            align-items: center;
        }
    }
    &__item + &__item {
        margin-top: 10px;
    }
    &__label {
        font-weight: $font-weight-light;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: left;
    }
    &__value {
        font-weight: $font-weight-bold;
        text-align: right;
    }
    &__actions {
        padding: 0 10px 10px 10px;
        text-align: left;
        display: flex;
    }
    &__list {
        background-color: $color-gray1;
        padding: 20px 10px;
        box-sizing: border-box;
        width: 100%;
        text-align: left;
    }
    &__links-list {
        border-bottom: 1px solid $o-separator-color;
        &__item + &__item {
            margin-top: 10px;
        }
        padding-bottom: 20px;
        margin-bottom: 20px;
        @include respond-lt(large) {
            .#{$ns}super-table & {
                padding: 0 20px;
            }
        }
    }
}
