.#{$ns}grouping {
    $self: &;
    .cm-orb:hover {
        cursor: pointer;
    }
    .selected p span {
        .cm-orb {
            background-color: $icons-with-background-color;
            color: $icons-with-background-background-color;
            box-shadow: 0 0 0 3px #ccc;
        }
    }

    &__item-header>span>.cm-orb {
        background-color: $icons-with-background-background-color;
        color: $icons-with-background-color;
    }

    .handle p .label {
        &:hover {
            cursor: move;
        }
    }

    p {
        .expander__trigger {
            &:hover {
                cursor: pointer;
            }
            .cm-orb {
                &:hover {
                    cursor: pointer;
                }
            }
        }
        .g-icon--move:hover {
            cursor: move;
        }
        .g-icon--close:hover {
            cursor: pointer;
        }
    }
    .sortable-chosen {
        border-top: 2px solid #161c2b;
        border-bottom: 2px solid #161c2b;
        border-radius: 2%;
    }
    .sortable-drag {
        @include respond-lte(medium) {
            visibility: hidden;
        }
    }
    &__item-header {
        .label {
            padding-top: 0;
        }
        padding: 5px 0;
    }
    &__item {
        padding-bottom: 10px;
        padding-top: 5px;
        margin: 0;
        &.is-selected {
            padding-top: 0;
            padding-bottom: 0;
            &:first-child {
                margin-top: 20px;
            }
            &.is-expanded {
                margin-bottom: 10px;
                padding-bottom: 10px;
                padding-top: 5px;
            }
        }
        &.is-selected + #{$self}__item:not(.is-selected) {
            margin-top: 10px;
        }
    }
}
