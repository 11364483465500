$scrollable-table-tfoot-th-color: $color-white !default;

.#{$ns}super-table {
    width: 100%;
    height: 100%;
    .g-icon.g-icon--arrow-down {
        color: $arrow-icon-color;
    }
    &__part {
        display: inline-block;
        height: 100%;

        &--fixed {
            &.scroll-right {
                div.cm-super-table--buckets {
                    .vb-dragger {
                        left: 296px;
                        visibility: visible !important;
                    }
                }
            }
            &__wrapper {
                /*For Safari z-index and fixed position issue when child (bubble) element overflows parent */
                width: 300px;
                display: inline-block;
                box-sizing: border-box;
                box-shadow: 8px 2px 12px -2px rgba(0, 0, 0, 0.1);
                position: relative;
                z-index: 1;

                @include respond-lt(large) {
                    width: 130px;
                }
            }
            .vb-dragger {
                right: auto;
                left: 2px;
            }
            overflow: hidden;
            width: 300px;
            table {
                width: 100%;
            }
            @include respond-lte(medium) {
                width: 130px;
            }
            tfoot {
                font-size: 1.3em;
            }
            .expander {
                height: auto !important;
                .#{$ns}super-table {
                    &__group {
                        &-thead {
                            padding-left: 10px;
                            display: block;
                            td {
                                .g-icon {
                                    padding: 10px;
                                }
                                .cm-table__content--expandable {
                                    padding-left: 0;
                                    margin-left: 5px;
                                }
                            }
                        }
                        &-body--fixed {
                            padding-left: 30px;
                        }
                    }
                }
                &.#{$ns}super-table__group {
                    .vb-dragger {
                        visibility: hidden !important;
                    }
                }
            }
        }
        &--scrollable {
            width: calc(100% - 300px);
            @include respond-lte(medium) {
                width: calc(100% - 130px);
            }
            .simplebar-offset {
                @include respond-gt(medium) {
                    margin-right: -19px;
                }
            }
            .simplebar-track {
                bottom: -10px;
                pointer-events: auto;
                &.simplebar-horizontal .simplebar-scrollbar {
                    color: $table-simplebar-color;
                    &:hover {
                        cursor: pointer;
                    }
                }
                &.simplebar-vertical {
                    visibility: hidden !important;
                }
                .simplebar-scrollbar:before {
                    background-color: $table-simplebar-color !important;
                    opacity: 1 !important;
                }
            }
        }
    }
    .expander {
        &__element,
        .item-to-expand {
            height: auto !important;
        }

        &.is-expanded,
        &__item.is-expanded {
            .expander__element:not(.auto-height),
            .item-to-expand {
                max-height: 241px !important;
            }
        }
    }
    &__main {
        overflow-x: hidden;
        overflow-y: scroll !important;
        transition: height 0.3s;
        thead,
        tfoot {
            position: absolute;
            top: -9999px !important;
            left: -9999px !important;
        }
        td.hover {
            background: $table-td-hover-background;
        }
        .cm-table__content {
            min-width: 135px;
            box-sizing: border-box;
            &:not(.counted) {
                width: auto !important;
            }
        }
        & ~ .vb-dragger {
            right: auto;
            left: 0;
        }
        &:not(.vuebar-grouped) tbody {
            background-color: $scrollable-table-td-background-color;
            tr {
                border-bottom: 1px $table-between-row-border-color solid;
                td {
                    color: $body-color;
                }
            }
        }
    }
    thead {
        border-bottom: 1px solid $color-gray2;
        box-sizing: border-box;
        th {
            background-color: $scrollable-table-thead-th-background-color;
            color: $scrollable-table-th-color;
            .cm-table__content {
                /*
                p {
                    width: 100%;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                }
                */
                .g-icon--move {
                    display: none;
                    position: absolute;
                    top: 50%;
                    &--down,
                    &--up {
                        right: -5px;
                        cursor: pointer;
                        &:before {
                            color: #cccccc;
                        }
                        &.sort {
                            &:before {
                                color: $body-color;
                            }
                        }
                    }
                    &--down {
                        transform: translateY(calc(-50% + 5px));
                    }
                    &--up {
                        transform: translateY(calc(-50% - 5px));
                    }
                }
                .sorted .g-icon {
                    display: inline-block;
                }
            }
            &.hover,
            &.sortable-chosen,
            &[draggable~='true'] {
                background: $table-th-hover-background;
                position: relative;
                z-index: 1;
                vertical-align: middle;
                .g-icon {
                    display: inline-block;
                    &--move {
                        color: $table-th-draggable-color;
                        left: 6px;
                        transform: translateY(-50%);
                        &:hover {
                            cursor: move;
                        }
                    }
                    &--down,
                    &--up {
                        right: 6px;
                    }
                }
                .cm-table__content {
                    padding: 5px 30px;
                    p {
                        color: $table-th-draggable-color;
                        white-space: normal;
                        overflow: visible;
                        word-break: normal;
                        font-size: 10px;
                        line-height: 15px;
                    }
                }
            }
        }
    }
    thead {
        .secondary-col {
            background-color: $color-blue7;
        }
        .column-has-tooltip {
            .cm-table__content.u-text-right.u-text-left {
                padding-right: 30px;
            }
        }
    }
    th,
    td {
        @include respond-gte(large) {
            white-space: nowrap;
        }
        position: relative;
        .#{$ns}table__content {
            padding: 0 15px;
            box-sizing: border-box;

            &.has-tooltip {
                padding-right: 30px;
            }
        }
    }
    th {
        font-weight: $scrollable-table-th-font-weight;
    }
    td {
        font-weight: $scrollable-table-td-font-weight;
    }
    tr:nth-child(even) .secondary-col {
        background-color: $table-tr-stripped-color-even;
    }
    tr:nth-child(odd) .secondary-col {
        background-color: $table-tr-stripped-color-odd;
    }

    &__group {
        max-height: 385px;
        &-tfoot {
            position: static !important;
            background-color: $table-group-summary-background-color !important;
            color: $table-group-summary-color !important;
        }
        &-thead {
            border: none !important;
            height: 48px;

            @include respond-lt(large) {
                max-width: 150px;
            }
            &--expandable-first td:first-of-type {
                padding-left: 15px;
            }
            td {
                a {
                    padding: 0;
                    margin-left: 0;
                    word-wrap: break-word;
                }
                .g-icon {
                    padding-right: 5px;
                    &:hover {
                        cursor: pointer;
                    }
                }
                .cm-table__content--expandable {
                    padding-left: 5px;
                    margin-left: 10px;
                }
            }
        }
        &-body {
            display: block;
            max-height: 241px;
            &--fixed {
                width: 319px;
                & ~ .vb-dragger {
                    left: 2px;
                    right: auto;
                }
            }
            &--normal {
                overflow: hidden scroll;
                width: calc(100% + 19px);
                & ~ .vb-dragger {
                    left: 5px;
                    right: auto;
                }
            }
        }
    }
    tr {
        height: 48px;
        box-sizing: border-box;
        background-color: inherit;
    }

    &--expandable {
        &.vb > .vb-dragger {
            right: 10px;
        }
    }
    &--grouped {
        .cm-super-table__group {
            &-thead {
                box-shadow: 8px 2px 12px -2px rgba(0, 0, 0, 0.1);
                max-width: 999em;
                .expander-cell {
                    padding-left: 20px;
                    box-sizing: border-box;
                }
            }
            &__header .cm-table__content {
                font-weight: $font-weight-bold;
            }
        }
        &--mobile {
            table {
                width: 100%;
            }
        }
    }
    &__bucket {
        thead {
            border-bottom-width: 0;
        }
        .expander {
            width: 100%;
        }
        .#{$ns}super-table {
            &__header,
            &__expander-trigger {
                height: 48px;
                background-color: $table-group-summary-background-color;
                font-weight: $font-weight-bold;
                padding: 10px;
                box-sizing: border-box;
                display: flex;
                align-items: center;
            }
            &__group {
                &-body {
                    &--normal {
                        .is-edited td {
                            width: 10%;
                        }
                        > div {
                            margin-top: -48px;
                        }
                    }
                    &--fixed {
                        width: 117px;
                        margin-right: -17px;
                        > div {
                            margin-top: -1px;
                        }
                    }
                }
            }
            &__expander-trigger {
                background-color: $scrollable-table-td-background-color;
                border-bottom: 1px solid #e8e8e8;
                .g-icon.g-icon--arrow-down {
                    padding-right: 15px;
                }
                .o-btn {
                    margin-left: 20px;
                }
            }

            &__mainPart {
                display: flex;
                align-items: flex-end;
                background-color: $scrollable-table-td-background-color;
                border-bottom: 2px solid #e8e8e8;
                th {
                    background-color: $scrollable-table-td-background-color;
                    padding: 15px 0;
                }
                td {
                    background: $scrollable-table-td-background-color;
                }
                &.non-expandable {
                    .#{$ns}super-table__buttons {
                        height: 48px;
                        min-width: 105px;
                    }
                }
            }
            &__buttons {
                box-sizing: border-box;
                border-top: 1px solid #e8e8e8;

                tr:not(:first-of-type) td {
                    border-top: 1px solid #e8e8e8;
                    box-sizing: border-box;
                }
                td {
                    padding-left: 10px;
                    width: 100px;
                }
                .o-btn {
                    padding: 0 10px 0 0;
                }
            }
            &__scrollablePart {
                width: 100%;
                border-left: 1px solid #e8e8e8;
                table {
                    width: 100%;
                    :not(thead) {
                        tr {
                            border-top: 1px solid #e8e8e8;
                        }
                    }
                }
                .vb > .vb-dragger {
                    right: -4px;
                }
            }
        }
    }
    &--stripped {
        @include respond-gte(large) {
            .expander-row:nth-of-type(even) {
                background-color: $table-tr-stripped-color-even;
            }
        }
        .expander .expander:nth-of-type(even) {
            background-color: $table-tr-stripped-color-even;
        }
        .cm-super-table__main tbody tr {
            border-bottom: none;
        }
        .cm-super-table--buckets {
            & > div > div {
                &:nth-of-type(odd) {
                    background-color: $table-tr-stripped-color-odd;
                    & > div {
                        background-color: inherit;
                    }
                    & > div.cm-super-table__group-thead--first {
                        background-color: $table-stripped-color-thead-group;
                    }
                    & > table .cm-super-table__group-thead {
                        background-color: $table-stripped-color-thead-group;
                    }
                    thead,
                    tbody {
                        background-color: inherit;
                    }
                    tfoot tr {
                        background-color: $table-tr-stripped-color-even;
                    }
                }
                &:nth-of-type(even) {
                    background-color: $table-tr-stripped-color-even;
                    & > div {
                        background-color: inherit;
                    }
                    & > div.cm-super-table__group-thead--first {
                        background-color: $table-stripped-color-thead-group;
                    }
                    & > table .cm-super-table__group-thead {
                        background-color: $table-stripped-color-thead-group;
                    }
                    thead,
                    tbody {
                        background-color: inherit;
                    }
                    tfoot tr {
                        background-color: $table-tr-stripped-color-odd;
                    }
                }
            }
            .cm-super-table__group:not(.expander) {
                .cm-super-table__group-thead--first {
                    background-color: $table-stripped-color-thead-group;
                }
                & > div.cm-super-table__group-thead--first {
                    background-color: $table-stripped-color-thead-group;
                }
                .cm-super-table__group-body {
                    tr {
                        border-bottom: none;

                        // .even and .odd classes help when in virtual-list e.g. even index element appears as odd html element
                        &:nth-of-type(odd):not(.cm-super-table__level-3):not(.odd),
                        &.even:not(.cm-super-table__level-3) {
                            background-color: $table-tr-stripped-color-odd !important;
                        }

                        &:nth-of-type(even):not(.cm-super-table__level-3):not(.even),
                        &.odd:not(.cm-super-table__level-3) {
                            background-color: $table-tr-stripped-color-even !important;
                        }
                        .cm-super-table__level-3 {
                            background-color: inherit !important;
                        }
                    }
                }
            }
        }

        .cm-super-table--flat {
            overflow-x: hidden;
            overflow-y: scroll !important;
            table {
                tbody {
                    tr {
                        border-bottom: none;
                        &:nth-of-type(odd) {
                            background-color: $table-tr-stripped-color-odd;
                        }

                        &:nth-of-type(even) {
                            background-color: $table-tr-stripped-color-even;
                        }
                    }
                }
            }
        }

        .cm-super-table__part {
            .cm-super-table__group-tfoot {
                color: $table-stripped-color-total-group !important;
            }
            &.cm-super-table__part--fixed {
                .cm-super-table__main {
                    .cm-super-table__group {
                        &.expander {
                            td {
                                padding-left: 35px;

                                span {
                                    margin-left: -35px;
                                }
                            }
                            .cm-super-table__group-body--fixed {
                                td {
                                    box-sizing: border-box;
                                    padding-right: 40px;
                                }
                                p {
                                    margin-left: -20px;
                                }
                            }
                        }
                        td {
                            white-space: normal;
                            padding-right: 20px;
                        }
                    }
                    table {
                        width: 100%;

                        td {
                            white-space: normal;
                            padding: 0 15px;
                            a {
                                padding: 0;
                                //font-size: 0.9em;
                                margin-left: 0;
                                word-wrap: break-word;
                            }

                            p {
                                padding: 0;
                            }
                        }
                    }
                }
                table {
                    .cm-super-table__group-body--fixed {
                        td {
                            width: 300px;
                        }
                    }
                }
            }
        }
    }
    & &__arrow {
        padding-right: 20px;
        .cm-table__content {
            padding: 0;
            display: inline;
        }
    }
    @include respond-lt(large) {
        table tr td {
            word-break: break-all;
        }
    }
    &__footer {
        background-color: $scrollable-table-tfoot-th-background-color;
        color: $scrollable-table-tfoot-th-color;
        .periodic-col {
            color: $periodic-column-footer-color;
        }
        tr {
            &:not(:last-child) {
                border-bottom: 1px solid $scrollable-table-tfoot-th-border-bottom-color;
            }
            .column-has-tooltip {
                .cm-table__content.u-text-right.u-text-left {
                    padding-right: 30px;
                }
            }
        }
    }

    &__footer-with-sub-footer {
        display: flex;
        flex-direction: column;
    }

    &__sub-footer {
        td {
            vertical-align: top;
            border-right: 1px $table-between-row-border-color solid;
            border-bottom: 1px $table-between-row-border-color solid;

            p {
                white-space: normal;
                word-break: break-word;
                word-wrap: break-word;
                max-width: 100%;
            }
        }

        &.fixed {
            p {
                font-weight: $font-weight-bold;
            }
        }
    }

    .cm-super-table__sub-footer {
        color: $color-blue1;
        background-color: $color-gray1;
    }
}
