:root {
    --dp-menu-width: 300px;
    .cm-datepicker {
        /*General*/
        --dp-font-family: $font-family;
        --dp-border-radius: 15px; /*Configurable border-radius*/
        --dp-cell-border-radius: 4px; /*Specific border radius for the calendar cell*/
        --dp-button-icon-height: 30px; /*Size of arrow buttons*/

        --dp-font-size: 12px; /*Default font-size*/
        --dp-preview-font-size: 3em; /*Font size of the date preview in the action row*/
        --dp-input-padding: 0px 35px 0px 0px; /*Padding in the input*/
        --dp-input-icon-padding: 10px; /*Padding on the left side of the input if icon is present*/

        .dp__input {
            height: 32px;
            position: relative;
            font-size: 1em;
        }

        .dp__arrow_top {
            display: none;
        }

        .dp__input_icon {
            color: $datepicker-input-icon-color;
            inset-inline-start: unset;
            inset-inline-end: 0;
            padding-right: 12px;
        }

        .dp__clear_icon {
            margin-right: 40px;
            padding-bottom: 4px;

            .g-icon {
                &:before {
                    font-size: 10px;
                }
            }
        }
    }

    .dp__theme_light {
        --dp-primary-color: #{$datepicker-current-background};
        --dp-text-color: #000;
        --dp-primary-disabled-color: #{$datepicker-current-background};
    }

    .menu_datepicker {
        /*General*/
        --dp-font-family: $font-family;
        --dp-border-radius: 15px; /*Configurable border-radius*/
        --dp-cell-border-radius: 4px; /*Specific border radius for the calendar cell*/
        --dp-button-icon-height: 30px; /*Size of arrow buttons*/

        --dp-font-size: 12px; /*Default font-size*/
        --dp-preview-font-size: 3em; /*Font size of the date preview in the action row*/
        --dp-input-padding: 0px 35px 0px 0px; /*Padding in the input*/
        --dp-input-icon-padding: 10px; /*Padding on the left side of the input if icon is present*/

        border-radius: 0;

        .dp__cell_inner {
            border-radius: 50%;
            color: $datepicker-current-background;
        }

        .dp__menu {
            border: 1px solid #d1dbe5;
            border-radius: 0;
            box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.2);
        }

        .dp__active_date {
            background: $datepicker-current-background;
            color: $color-white;
        }
        .dp--past.dp__range_end,
        .dp--past.dp__range_start,
        .dp--future.dp__range_end,
        .dp--future.dp__range_start,
        .dp__today.dp__range_end,
        .dp__today.dp__range_start,
        .dp__range_start.dp__range_end {
            color: $color-white;

            &:hover {
                color: $color-white;
            }
        }

        .dp__today {
            border: 1px solid $datepicker-current-background;
        }

        .dp__cell_disabled {
            color: $color-gray3;
        }

        .dp__icon {
            stroke: none;
            color: $datepicker-current-background;
        }

        .dp__clear_icon {
            margin-right: 40px;
            padding-bottom: 4px;

            .g-icon {
                &:before {
                    font-size: 10px;
                }
            }
        }

        .dp__calendar_header_item {
            font-weight: normal;
        }

        .dp__btn {
            font-size: 14px;
            color: $datepicker-current-background;
        }

        .dp--qr-btn {
            span {
                color: $datepicker-current-background;
            }
        }

        .dp--qr-btn-active {
            span {
                color: $color-white;
            }
        }

        .dp__button.dp__overlay_action {
            border-radius: 0;
        }
    }
}

.cm-sidebar-filters {
    .dp--date-ranges {
        .dp__flex_display {
            flex-wrap: wrap;
        }
        .dp__menu_inner > .dp__instance_calendar:first-child {
            border-bottom: 1px solid $sidebar-bg-color;
            margin-bottom: 10px;
            padding-bottom: 10px;
        }
    }
}
