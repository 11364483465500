$color-first: #1B0757; //1st-lvl-Menu-BG_Total-BG_SideMenu-BG
$color-second: #150442; //2nd-lvl-Menu_Filters-expanded-BG
$color-third: #C5C0BA; //active
$color-third-hover: #E2DCD6;
$color-menu-shadow: #150442;
$color-menu-2nd-lvl-hover:  #3F39B2;
$color-menu-3rd-lvl-hover: #5252CB; //Hover in 3rd-lvl-Menu
$color-links-on-white: $color-menu-2nd-lvl-hover; //Links on white-BG
$color-table-dark: #F8F6F4; //Table_Dark_BG - F6F9FA
$color-grey-elements: #F1EDEA; //Grey elements_disabled elements
$color-error: #C42E2E; //Error - CE2020
$color-positive: #146743;