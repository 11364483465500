/*
 * hy.frontend.core
 * 
 * NOTE: this file is not subject to copyright transfer
 * 
 * UWAGA: plik nie objęty przekazaniem praw autorskich
 * 
 * 
 */





/*------------------------------------*\
    #TYPOGRAPHY
\*------------------------------------*/
////
/// @group typography
/// @author sebastian.nowak
////

/// Defines base font-size on body element
/// Default browsers setting is 1em==16px.
/// Do not touch this one!!!
$browsers-font-size-base: 16 !default;


/// Defines line-height on body
$body-line-height: normal !default;

$h1-line-height: $body-line-height !default;
$h2-line-height: $body-line-height !default;
$h3-line-height: $body-line-height !default;
$h4-line-height: $body-line-height !default;
$h5-line-height: $body-line-height !default;
$h6-line-height: $body-line-height !default;

$p-line-height: $body-line-height !default;

/// Defines most popular font-size in project (usually for p element)
$font-size-base: 16 !default;

$font-size-base-medium: $font-size-base !default;
$font-size-base-small: $font-size-base !default;

/// Defines base size for modular-scale
$ms-base: size($font-size-base) !default;

/// The factor of change between each number so if the ratio is 1.5
/// then each number in the sequence will be 1.5 times that of the previous number.
/// There are several predefinied factors:
/// * $golden           : 1.618034
/// * $double-octave    : 4        
/// * $major-twelfth    : 3        
/// * $major-eleventh   : 2.666666667
/// * $major-tenth      : 2.5        
/// * $octave           : 2        
/// * $major-seventh    : 1.875      
/// * $minor-seventh    : 1.777777778
/// * $major-sixth      : 1.666666667
/// * $minor-sixth      : 1.6        
/// * $fifth            : 1.5        
/// * $augmented-fourth : 1.41421    
/// * $fourth           : 1.333333333
/// * $major-third      : 1.25       
/// * $minor-third      : 1.2        
/// * $major-second     : 1.125      
/// * $minor-second     : 1.066666667
/// but we can also set our own factor
$ms-ratio: $golden !default;

/// Defines step on scale for h1 element
$h1-scale: 3 !default;
$h1-scale-medium: $h1-scale !default;
$h1-scale-small: $h1-scale-medium !default;
/// Defines step on scale for h2 element
$h2-scale: 2 !default;
$h2-scale-medium: $h2-scale !default;
$h2-scale-small: $h2-scale-medium !default;
/// Defines step on scale for h3 element
$h3-scale: 1 !default;
$h3-scale-medium: $h3-scale !default;
$h3-scale-small: $h3-scale-medium !default;
/// Defines step on scale for h4 element
$h4-scale: 0 !default;
$h4-scale-medium: $h4-scale !default;
$h4-scale-small: $h4-scale-medium !default;
/// Defines step on scale for h5 element
$h5-scale: 0 !default;
$h5-scale-medium: $h5-scale !default;
$h5-scale-small: $h5-scale-medium !default;
/// Defines step on scale for h6 element
$h6-scale: 0 !default;
$h6-scale-medium: $h6-scale !default;
$h6-scale-small: $h6-scale-medium !default;
/// Defines step on scale for p element
$p-scale:  0 !default;
$p-scale-medium:  $p-scale !default;
$p-scale-small:  $p-scale-medium !default;

/// Defines step on scale for smaller font
$small-scale:  -1 !default;
/// Defines alternative small font size (only if $large-scale is undefined)
$small-size: 12 !default;

/// Defines step on scale for larger font
$large-scale: 1 !default;
/// Defines alternative large font size (only if $large-scale is undefined)
$large-size: 18 !default;

/// Defines step on scale for bottom margin on [h1, h2, h3, h4, h5, h6, p] elements
$margin-bottom-scale: 0 !default;

/// Defines step on scale for top margin on [h1, h2, h3, h4, h5, h6, p] elements which is set only in articles.
$margin-top-scale: 0 !default;

/// Defines global body font family
$body-font-family: Arial, sans-serif !default;

/// Defines global body text color
$body-color: $color-black !default;

/// Defines global headings font family
$heading-font-family: $body-font-family !default;

/// Defines h1 font family
$h1-font-family: $heading-font-family !default;
/// Defines h2 font family
$h2-font-family: $heading-font-family !default;
/// Defines h3 font family
$h3-font-family: $heading-font-family !default;
/// Defines h4 font family
$h4-font-family: $heading-font-family !default;
/// Defines h5 font family
$h5-font-family: $heading-font-family !default;
/// Defines h6 font family
$h6-font-family: $heading-font-family !default;


/// Defines global headings font weight
$heading-font-weight: normal !default;

/// Defines h1 font weight
$h1-font-weight: $heading-font-weight !default;
/// Defines h2 font weight
$h2-font-weight: $heading-font-weight !default;
/// Defines h3 font weight
$h3-font-weight: $heading-font-weight !default;
/// Defines h4 font weight
$h4-font-weight: $heading-font-weight !default;
/// Defines h5 font weight
$h5-font-weight: $heading-font-weight !default;
/// Defines h6 font weight
$h6-font-weight: $heading-font-weight !default;


/// Defines global headings color
$heading-color: inherit   !default;

/// Defines h1 color
$h1-color: $heading-color !default;
/// Defines h2 color
$h2-color: $heading-color !default;
/// Defines h3 color
$h3-color: $heading-color !default;
/// Defines h4 color
$h4-color: $heading-color !default;
/// Defines h5 color
$h5-color: $heading-color !default;
/// Defines h6 color
$h6-color: $heading-color !default;

body, #{$scope} {
    font-size: size($font-size-base, $rel-unit, $browsers-font-size-base);
    font-family: $body-font-family;
    color: $body-color;
    line-height: $body-line-height;
}

h1, .h1 {
    @include heading-style($h1-scale, $h1-font-family, $h1-font-weight, $h1-color, $h1-line-height);

    @include respond-lte(small) {
        font-size: ms-size($h1-scale-small);
    }

    @include respond(medium) {
        font-size: ms-size($h1-scale-medium);
    }
}

h2, .h2 {
    @include heading-style($h2-scale, $h2-font-family, $h2-font-weight, $h2-color, $h2-line-height);

    @include respond-lte(small) {
        font-size: ms-size($h2-scale-small);
    }

    @include respond(medium) {
        font-size: ms-size($h2-scale-medium);
    }
}

h3, .h3 {
    @include heading-style($h3-scale, $h3-font-family, $h3-font-weight, $h3-color, $h3-line-height);

    @include respond-lte(small) {
        font-size: ms-size($h3-scale-small);
    }

    @include respond(medium) {
        font-size: ms-size($h3-scale-medium);
    }
}

h4, .h4 {
    @include heading-style($h4-scale, $h4-font-family, $h4-font-weight, $h4-color, $h4-line-height);

    @include respond-lte(small) {
        font-size: ms-size($h4-scale-small);
    }

    @include respond(medium) {
        font-size: ms-size($h4-scale-medium);
    }
}

h5, .h5 {
    @include heading-style($h5-scale, $h5-font-family, $h5-font-weight, $h5-color, $h5-line-height);

    @include respond-lte(small) {
        font-size: ms-size($h5-scale-small);
    }

    @include respond(medium) {
        font-size: ms-size($h5-scale-medium);
    }
}

h6, .h6 {
    @include heading-style($h6-scale, $h6-font-family, $h6-font-weight, $h6-color, $h6-line-height);

    @include respond-lte(small) {
        font-size: ms-size($h6-scale-small);
    }

    @include respond(medium) {
        font-size: ms-size($h6-scale-medium);
    }
}

p, .p {
    font-size: ms-size($p-scale);
    margin: 0;
    line-height: $p-line-height;

    @include respond-lte(small) {
        font-size: ms-size($p-scale-small);
    }

    @include respond(medium) {
        font-size: ms-size($p-scale-medium);
    }
}




/*------------------------------------*\
    TYPOGRAPHY - CMS
\*------------------------------------*/

.#{$ns}cms {
    
    h1, .h1,
    h2, .h2,
    h3, .h3,
    h4, .h4,
    h5, .h5,
    h6, .h6,
    p, .p {
        &:first-child {
            margin-top: 0;
        }
    }
    
    h1, .h1 {
        margin-top: ms-size($margin-top-scale);
    }

    h2, .h2 {
        margin-top: ms-size($margin-top-scale);
    }

    h3, .h3 {
        margin-top: ms-size($margin-top-scale);
    }

    h4, .h4 {
        margin-top: ms-size($margin-top-scale);
    }

    h5, .h5 {
        margin-top: ms-size($margin-top-scale);
    }

    h6, .h6 {
        margin-top: ms-size($margin-top-scale);
    }
    
    p, .p {
        margin: ms-size($margin-top-scale) 0 ms-size($margin-bottom-scale);
    }
}
