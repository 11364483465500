.#{$ns}input-date {
    &__container {
        margin: 0 auto;
        min-width: 130px;
        height: 33px;
        background-color: $form-input-background;
        border-radius: size($form-input-border-radius);

        &--mobile {
            margin: 0 0 0 auto;
            min-width: 0;
            height: 100%;
        }

        &:focus-within {
            outline: 2px #5ea1c1 solid;
            outline-offset: 1px;
        }
    }
    &__field {
        display: inline-block !important;
        font-size: 0.9em;
        height: 100%;
        position: relative;
        margin: 0;
        padding: 0;
        width: 30%;

        &--year {
            width: 40%;
        }
        input {
            width: 100%;
            height: 100%;
            background: none;
            &:focus {
                outline: none;
            }
        }
    }
    &__field + &__field {
        &:before {
            content: '/';
            display: block;
            position: absolute;
            top: 36%;
            left: 1px;
        }
    }
}
