.#{$ns}filters-presets {
    padding-bottom: 20px;
    .cm-datepicker.el-date-editor .el-picker-panel {
        margin-left: 0;
    }
    .is-expanded .expander__element {
        overflow: visible;
    }
    .is-expanded .el-to-expand.auto-height {
        max-height: none !important;
    }
    .cm-filters__list.auto-height {
        max-height: none;
    }
    &__top {
        padding: 20px 20px 0;
    }
    &__button-container {
        position: relative;
        padding: 0 0 15px 0;
        box-sizing: border-box;
        .cm-filters-presets__tooltip-container {
            position: absolute;
            bottom: 0;
            height: 50px;
            width: 100%;
            @include respond-gt(medium) {
                .cm-filters-presets__tooltip {
                    display: none;
                }
                &:hover {
                    .cm-filters-presets__tooltip {
                        display: block;
                    }
                }
            }
        }
    }
    &__tooltip {
        position: absolute;
        top: -65px;
        padding: 8px;
        background-color: $color-gray2;
        color: $filters-presets-tooltip-background-color;
        &:after {
            display: inline-block;
            content: '';
            width: 0;
            height: 0;
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            border-top: 8px solid $color-gray2;
            position: absolute;
            bottom: -8px;
            left: 47%;
        }
    }
}
