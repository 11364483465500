.#{$ns}subfilters {
    $self: &;
    &__item {
        label,
        .label {
            display: block;
            padding-top: 0;
            font-weight: $form-label-font-weight;
        }
        &--dropdown {
            min-height: 58px;
        }
        #{$self}--side #{$self}__group--regular & {
            @include respond-gt(medium) {
                width: 75%;
                &--comparison {
                    width: 100%;
                }
            }
        }
    }
    &__group {
        &--main {
            label,
            .label {
                @include heading-style($h4-scale, $h4-font-family, $h4-font-weight, $h4-color, $h4-line-height);
                margin-bottom: 10px;
            }
        }
    }
    &__field {
        margin-top: 10px;
        .#{$ns}checkbox {
            input ~ .#{$custom-input-class}:before {
                border-width: 1px;
            }
        }
    }

    &__link {
        padding: 3px;
        @include respond-lte(medium) {
            display: none;
        }
    }
    &__title {
        #{$self}__group + #{$self}__group & {
            padding-top: 30px;
        }
        margin-bottom: 20px;
        clear: both;
    }
    &__presets {
        padding: 20px;
        &__checkbox {
            margin-top: 10px;
            .o-checkbox input:disabled ~ .o-ci-label {
                color: inherit;
            }
        }
        &__label {
            margin-bottom: 5px;
        }
    }
    &__alert {
        padding: 20px;
    }
    &--main {
        margin-bottom: 40px;
        #{$self}__wrapper {
            @include container();
            max-width: none;
            @include squish(-$grid-gutter);

            @include respond-lte(medium) {
                @if ($grid-gutter-medium != $grid-gutter) {
                    @include squish(-$grid-gutter-medium);
                }
            }
            @include respond-lte(small) {
                // change gutter if necessary
                @if ($grid-gutter-small != $grid-gutter) {
                    @include squish(-$grid-gutter-small);
                }
            }
        }
        #{$self}__button,
        #{$self}__link {
            margin-top: 17px;
        }
        #{$self}__item {
            @include span(100%);
            width: span(2);

            &--date_ranges {
                width: span(4);
            }

            @include respond(medium) {
                width: span(4);
                margin-top: 25px;
            }
            @include respond(small) {
                &:not(:first-of-type) {
                    margin-top: 25px;
                }
                width: span(12);
            }
            &--comparison {
                @include respond-gt(medium) {
                    width: span(6);
                    #{$self}__item {
                        width: span(4);
                    }
                }
                @include respond(medium) {
                    width: span(12);
                }
            }
            &--checkbox {
                @include respond-gte(medium) {
                    width: span(12);
                }
                text-align: right;
            }
            &--pacing {
                width: auto;
            }
        }
        .#{$ns}checkbox {
            @include respond-gt(medium) {
                & + .#{$ns}checkbox {
                    margin-left: 20px;
                }
            }
            @include respond-lte(medium) {
                display: block;
                margin-top: 15px;
            }
        }
    }
    &--side {
        #{$self}__wrapper {
            padding: 20px;
        }
        #{$self}__item + #{$self}__item {
            margin-top: 25px;
        }
        #{$self}__item--date_range_quarters {
            .l-col + .l-col {
                margin-top: 25px;
            }
        }
        .o-btn--link {
            color: $sidebar-filters__button--clear-color;
            font-weight: 400;
            &:hover {
                color: $sidebar-filters__button--clear-hover-color;
            }
        }
        .#{$ns}checkbox + .#{$ns}checkbox {
            display: block;
            margin-top: 15px;
        }
    }
}
