.ck-editor__editable_inline:not(.ck-comment__input *) {
  height: 1000px;
  overflow-y: auto;
}

.ck .ck-powered-by,
.ck.ck-balloon-panel.ck-balloon-panel_position_border-side_right.ck-balloon-panel_visible.ck-powered-by-balloon {
  display: none !important;
}

.ck.ck-content ul,
.ck.ck-content ol {
  padding-left: 20px;
}

.ck.ck-content {
  border-color: #CCCED1 !important;
}

.ck.ck-toolbar {
  border-bottom: none !important;
}

.ck-content {
  font-size: 15px;
  font-family: $body-font-family;
}