.#{$ns}spacing-bottom-5 {
    margin-bottom: 5px !important;
}
.#{$ns}spacing-bottom-10 {
    margin-bottom: 10px;
}
.#{$ns}spacing-bottom-20 {
    margin-bottom: 20px !important;
}
.#{$ns}spacing-bottom-25 {
    margin-bottom: 25px;
}
.#{$ns}spacing-bottom-30 {
    margin-bottom: 30px;
}
.#{$ns}spacing-bottom-50 {
    margin-bottom: 50px;
}
.#{$ns}spacing-top-5 {
    margin-top: 5px !important;
}
.#{$ns}spacing-top-20 {
    margin-top: 20px;
}
.#{$ns}spacing-top-25 {
    margin-top: 25px;
}

.l-row--clear-odd {
    .l-col:nth-child(2n + 1) {
        clear: both;
    }
}

.u-flex-gap-20 {
    gap: 20px;
}
