.#{$ns}table-fixed-expand {
    border-bottom: 1px solid $table-between-row-border-color;
    width: 100%;
    overflow-x: auto;
    @include scrollbar($body-color, transparent, 4px, 2px);
    &::-webkit-scrollbar-track {
        margin-left: 190px;
    }

    th,
    td {
        padding: 15px 16px;
        box-sizing: border-box;
        text-align: end;
        border-top: 1px solid $table-between-row-border-color;
        background-color: $table-tr-stripped-color-odd;
        @include respond(small) {
            padding: 10px 8px;
        }
    }
    td {
        color: $table-td-color;
        font-weight: $table-td-font-weight;
        min-width: 100px;
    }
    th {
        font-weight: $font-weight-bold;
    }
    &__table {
        max-width: 100%;
        text-align: left;
    }
    &__thead {
        th {
            border: none;
        }
    }
    &__tr {
        &.has_expander ~ .item-to-expand {
                display: none;

        }
        &.is-expanded ~ .item-to-expand {
                display: table-row;

        }
        &--selected {
            td,
            th {
                background-color: $table-fixed-expand-selected-background-color;
            }
        }
    }
    &__cell {
        &--first {
            min-width: 190px;
            max-width: 190px;
            position: sticky;
            left: 0;
            border-right: 1px solid $table-between-row-border-color;
            text-align: left;
        }
    }
    &__content {
        &--first {
            display: flex;
            align-items: baseline;
            text-align: start;
        }
    }
    &__dot {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        margin-right: 8px;
    }
    .o-btn--link {
        padding-left: 5px;
        padding-right: 5px;
        margin-left: 5px;
    }
}
