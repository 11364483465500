/*
 * hy.frontend.core
 * 
 * NOTE: this file is not subject to copyright transfer
 * 
 * UWAGA: plik nie objęty przekazaniem praw autorskich
 * 
 * 
 */





/*------------------------------------*\
    #LINKS
\*------------------------------------*/

////
/// @group links
/// @author marcin.krasowski
////

/// defines link color
$link-color: $color-brand1 !default;

/// defines link color on hover/focus/active
$link-hover-color: $color-brand2 !default;

/// defines link decoration
$link-decoration: none !default;

/// defines link decoration on hover/focus/active
$link-hover-decoration: underline !default;

/// defines link weight
$link-weight: inherit !default;

a {
    color: $link-color;
    text-decoration: $link-decoration;
    font-weight: $link-weight;

    &:hover, &:focus, &:active {
        color: $link-hover-color;
        text-decoration: $link-hover-decoration;
    }

    @include transition-standard-fast(color);

    &, &:hover, &:focus, &:active {
        @include fix-content-underline;
    }
}
