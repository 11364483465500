.#{$ns}simplebar {
    padding-bottom: 15px;
    padding-right: 15px;
}
.simplebar-track {
    pointer-events: auto;
    .simplebar-scrollbar {
        &::before {
            background: $simplebar-scrollbar-background-color;
            opacity: 1;
            cursor: pointer;
        }
        &.simplebar-visible::before {
            opacity: 1 !important;
        }
    }
    &.simplebar-horizontal {
        .simplebar-scrollbar {
            height: 100%;
            cursor: pointer;
            &::before {
                height: 4px;
                bottom: 2px;
            }
        }
    }
    &.simplebar-vertical {
        .simplebar-scrollbar {
            width: 4px;
        }
    }
}
