/*------------------------------------*\
    #ORB
\*------------------------------------*/

$orb-size-xsmall: size(20, px);
$orb-size-small: size(26, px);
$orb-size-medium: size(30, px);
$orb-size-medium-mobile: size(40, px);
$orb-size-large: size(36, px);
$orb-size-xlarge: size(280, px);
$orb-size-xxlarge: size(335, px);

$orb-icon-size-xsmall: 11px;
$orb-icon-size-small: 12px;
$orb-icon-size-medium: 15px;
$orb-icon-size-large-mobile: 20px;
$orb-icon-size-large: 20px;
$orb-icon-size-xlarge: 90px;

@mixin orb-shadow {
    box-shadow: 0 4px 9px 0 rgba(0, 0, 0, 0.21);
}

@mixin orb-icon($orb-icon-size) {
    color: $color-white;
    &::before {
        font-size: $orb-icon-size;
    }
}

@mixin orb-size($radius) {
    width: $radius;
    height: $radius;
    //border: 1px solid transparent;
    box-sizing: border-box;
}

@mixin orb-image($orb-image-size) {
    width: $orb-image-size;
    height: $orb-image-size;
    border-radius: $orb-image-size;
}

@mixin orb($radius, $bg: $color-white, $shadow: true) {
    display: inline-block;
    background: $bg;
    border: 1px solid transparent;
    //border: 0 $bg solid; //high contrast mode fix
    @include orb-size($radius);
    @include transition-standard(background);
    @if $shadow == true {
        @include orb-shadow;
    }

    @content;
}

.#{$ns}orb {
    @include orb($orb-size-large);
    padding: 0;
    border-radius: 50%;
    &:disabled {
        background-color: $color-gray3 !important;
        .cm-orb__inner {
            .g-icon {
                color: $color-blue1 !important;
            }
        }
    }
    &__inner {
        display: block;
        text-align: center;
        position: relative;
        &--counter {
            .#{$ns}orb__inner--icon {
                position: absolute;
                top: 50%;
                right: 50%;
                transform: translateY(-50%) translateX(50%);
            }
        }
        &--icon {
            @include orb-icon($orb-icon-size-large);
            line-height: 1em;
            display: inline;
        }
        .#{$ns}orb__picture {
            @include orb-image($orb-size-large);
        }
    }
    &--xs {
        @include orb-size($orb-size-xsmall);
        line-height: ($orb-size-xsmall - 2);
        .#{$ns}orb__inner--icon {
            @include orb-icon($orb-icon-size-xsmall);
        }
        .#{$ns}orb__picture {
            @include orb-image($orb-size-xsmall);
        }
    }
    &--s {
        @include orb-size($orb-size-small);
        .#{$ns}orb__inner--icon {
            @include orb-icon($orb-icon-size-small);
        }
        .#{$ns}orb__picture {
            @include orb-image($orb-size-small);
        }
    }

    &--m {
        @include orb-size($orb-size-medium);
        .#{$ns}orb__inner--icon {
            @include orb-icon($orb-icon-size-medium);
        }
        .#{$ns}orb__picture {
            @include orb-image($orb-size-medium);
        }
    }
    &--mm {
        @include orb-size($orb-size-medium-mobile);
        .#{$ns}orb__inner--icon {
            @include orb-icon($orb-icon-size-large-mobile);
        }
        .#{$ns}orb__picture {
            @include orb-image($orb-size-medium);
        }
    }
    &--l {
        @include orb-size($orb-size-large);
        .#{$ns}orb__inner--icon {
            @include orb-icon($orb-icon-size-large);
            @include respond(small) {
                @include orb-icon($orb-icon-size-large-mobile);
            }
        }
        .#{$ns}orb__picture {
            @include orb-image($orb-size-large);
        }
    }

    &--xl {
        @include orb-size($orb-size-xxlarge);
        @include respond-lte(medium) {
            @include orb-size($orb-size-xlarge);
        }
        .#{$ns}orb__inner--icon {
            @include orb-icon($orb-icon-size-xlarge);
            @include respond(small) {
                @include orb-icon($orb-icon-size-large);
            }
        }
    }
    &--counter {
        background-color: $icons-with-background-background-color;
        .#{$ns}orb__inner {
            font-weight: $font-weight-bold;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            padding: 0;
            color: $icons-with-background-color;
        }
    }
    &-double {
        .#{$ns}orb {
            position: relative;
            .#{$ns}orb {
                position: absolute;
                &__inner {
                    span {
                        vertical-align: middle;
                        &.cm-orb__inner--icon {
                            display: inherit;
                        }
                    }
                }
                &--s {
                    .#{$ns}orb__inner--icon {
                        @include orb-icon($orb-icon-size-small);
                    }
                    .#{$ns}orb__picture {
                        @include orb-image($orb-size-small);
                    }
                }

                &--m {
                    .#{$ns}orb__inner--icon {
                        @include orb-icon($orb-icon-size-medium);
                    }
                    .#{$ns}orb__picture {
                        @include orb-image($orb-size-medium);
                    }
                }
                &--l {
                    .#{$ns}orb__inner--icon {
                        @include orb-icon($orb-icon-size-large);
                    }
                    .#{$ns}orb__picture {
                        @include orb-image($orb-size-large);
                    }
                }
            }
        }
        &--right {
            .#{$ns}orb {
                .#{$ns}orb {
                    left: 115px;
                    top: 0;
                }
            }
        }
        &--left {
            .#{$ns}orb {
                .#{$ns}orb {
                    right: 115px;
                    top: 0;
                }
            }
        }
        &--left-down {
            .#{$ns}orb {
                .#{$ns}orb {
                    left: -16px;
                    bottom: -2px;
                }
            }
        }
    }

    &--image {
        position: relative;
    }

    &__picture {
        position: absolute;
        top: 0;
        left: 0;
    }
    &--no-shadow {
        box-shadow: none;
    }
    &--centered {
        margin: 0 auto;
        @include respond(small) {
            margin: 0;
        }
    }
    &--back-button {
        background-color: $sidebar-filters__toggle-icon-background;
        color: $sidebar-filters__button-text-color;

        &:hover {
            background-color: $sidebar-filters__toggle-icon-background-hover;
        }
    }
}
_::-webkit-full-page-media,
.#{$ns}orb {
    border: 1px solid transparent;
    &--counter {
        .#{$ns}orb__inner {
            padding-top: 2px;
        }
    }
} // Safari fix
