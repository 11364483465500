.#{$ns}information-bar {
    background-color: $color-white;
    padding: 8px 30px;
    min-height: 17px;
    @include respond(small) {
        padding: 8px 20px;
    }
    a {
        margin-left: 20px;
    }
}
