.#{$ns}partial__body {
    @include respond-gt(medium) {
        max-height: 230px;
        .#{$ns}scrollable__tbody {
            max-height: 220px;
        }
    }

    &:not(.counted) {
        width: 100% !important;
    }
    &:not(.cm-table-groups__list) {
        thead {
            opacity: 0;
        }
        @include respond-gte(medium) {
            tfoot {
                opacity: 0;
            }
        }
    }
    &.counted {
        &:not(.cm-table-groups__list) {
            thead {
                position: absolute;
                left: -9999em;
                top: -9999em;
            }
            @include respond-gte(medium) {
                tfoot {
                    position: absolute;
                    left: -9999em;
                    top: -9999em;
                }
            }
        }
        &.cm-table-groups__list .cm-table-groups__thead {
            position: absolute;
            left: -9999em;
            top: -9999em;
        }
    }
    .#{$ns}partial__head {
        &.counted {
            tbody {
                display: none;
            }
        }
    }

    .#{$ns}partial__foot {
        &.counted {
            thead {
                position: absolute;
                left: -9999em;
            }
        }
    }
}
