.cm-review-modal {
    @include respond(small) {
        margin: 10px;
    }

    &__list {
        padding: 5px;
        font-weight: $font-weight-bold;

        &:nth-of-type(odd) {
            background-color: $table-tr-stripped-color-odd;
        }

        &:nth-of-type(even) {
            background-color: $table-tr-stripped-color-even;
        }

        &--attributes {
            font-weight: $font-weight-normal;
            width: 50%;
            float: left;
            display: inline-block;
            @include respond(small) {
                clear: left;
                display: block;
                width: 100%;
            }
        }

        &-item {
            word-break: break-all;
            display: inline-block;
            cursor: pointer;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .#{$ns}dropdown .v-select .dropdown-menu {
        max-height: 110px;
    }

    textarea {
        height: 100px;
    }
}
