/*
 * hy.frontend.core
 * 
 * NOTE: this file is not subject to copyright transfer
 * 
 * UWAGA: plik nie objęty przekazaniem praw autorskich
 * 
 * 
 */





/*------------------------------------*\
    #LISTS
\*------------------------------------*/

////
/// @group lists
/// @author marcin.krasowski
////

/// defines unordered list bullet size
$list-bullet-size: 5 !default;
/// defines unordered list bullet radius
$list-bullet-radius: 100% !default;
/// defines ordered list number prefix
$list-number-prefix: '' !default;
/// defines ordered list number suffix
$list-number-suffix: '.' !default;
/// defines list item left padding
$list-padding: 20 !default;

// reset styles
ul, ol {
    list-style: none;
    padding: 0;
    margin: 0;
}

li {
    font-size: ms-size($p-scale);
    line-height: $p-line-height;

    @include respond-lte(small) {
        font-size: ms-size($p-scale-small);
    }

    @include respond(medium) {
        font-size: ms-size($p-scale-medium);
    }
}




/*------------------------------------*\
    LISTS - CMS
\*------------------------------------*/

.#{$ns}cms {
    
    ul, ol {
        margin: ms-size($margin-top-scale) 0 ms-size($margin-bottom-scale);
    }
    
    li {        
        &:before {
            display: inline-block;
        }
    }
    
    // unordered list - bullet witch chosen size and shape
    ul {
        li {
            position: relative;
            padding-left: size($list-bullet-size + $list-padding);
            
            &:before {
                content: '';
                position: absolute;
                top: size($list-bullet-size);
                left: 0;
                width: size($list-bullet-size);
                height: size($list-bullet-size);
                background-color: $body-color;
                border-radius: size($list-bullet-radius);
            }
        }
    }
    
    // ordered list - number with chosen prefix
    ol {
        counter-reset: ol-counter;
        
        li {
            &:before {
                content: $list-number-prefix counter(ol-counter) $list-number-suffix;
                counter-increment: ol-counter;
                margin-right: size($list-padding);
            }
        }
    }
}
