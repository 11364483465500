.#{$ns}breadcrumbs {
    padding: 0 0 20px;
    position: relative;
    z-index: 2;
    display: inline-block;
    .o-breadcrumbs {
        .o-breadcrumbs__item {
            padding: 0;
            float: none;
            display: inline;
            .o-breadcrumbs__separator {
                .g-icon::before {
                    font-size: 8px !important;
                }
            }

            &.disabled {
                a,
                span {
                    color: $color-blue1;
                }

                a {
                    pointer-events: none;
                    cursor: default;
                    text-decoration: none;
                }
            }

            &:last-child {
                font-weight: $font-weight-bold;
                .o-breadcrumbs__separator {
                    .g-icon::before {
                        font-weight: $font-weight-bold;
                    }
                }

                &.disabled {
                    font-size: 21px;
                }
            }
        }
    }
}
