@mixin scrollbar($color, $track, $size, $radius, $paddings: 0) {
    /* ===== Scrollbar CSS ===== */
    /* Firefox */
    & {
        scrollbar-width: thin;
        scrollbar-color: $color $track;
    }

    /* Chrome, Edge, and Safari */
    &::-webkit-scrollbar {
        width: $size + ($paddings * 2);
        height: $size;
    }

    &::-webkit-scrollbar-track {
        background: $track;
    }

    &::-webkit-scrollbar-thumb {
        background-color: $color;
        border-radius: $radius;
        border: none;

        @if $paddings > 0 {
            border: $paddings solid transparent;
            background-clip: content-box;
        }
    }
}
