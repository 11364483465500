/*
 * hy.frontend.core
 * 
 * NOTE: this file is not subject to copyright transfer * 
 * UWAGA: plik nie objęty przekazaniem praw autorskich * 
 * 
 */





////
/// TYPOGRAPHY3
/// @group tools
/// @author marcin.krasowski
////

/// Sets heading style, based on modular scaling
/// @param {Number} $scale-name - modular scale step
/// @param {String} $font-$font-family - font family
/// @param {String | Number} $font-$font-weight - font weight
/// @param {String} $font-$color - text color
@mixin heading-style($scale, $font-family, $font-weight, $color, $line-height) {
    font-size: ms($scale);
    font-family: $font-family;
    font-weight: $font-weight;
    line-height: $line-height;
    margin: 0 0 ms($margin-bottom-scale);
    color: $color;
}
