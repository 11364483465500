.#{$ns}separator {
    &--brand1 {
        border-color: $separator-brand1-border-color;
    }
    &--gray2 {
        border-color: $color-gray2;
    }
}
.padding--small {
    padding-bottom: 10px;
}
.section-with-separator {
    min-height: 150px;
    & + .section-with-separator {
        padding-top: 30px;
        margin-top: 30px;
        border-top: 1px solid $color-blue5;
    }
}
