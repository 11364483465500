@import '../../settings';

.#{$ns}switch {
    box-sizing: border-box;
    border-radius: 15px;
    border: 1px solid $color-gray3;
    padding: 4px;
    //margin-top: 10px;
    color: $switch-color;
    font-weight: 700;
    display: flex;
    align-items: center;
    @include respond(small) {
        border-radius: 20px;
    }

    .o-radio {
        margin: 0;
        padding: 0;
        flex: 1 1 auto;
        min-width: 30px;
    }

    input {
        & ~ .#{$custom-input-class} {
            display: none;
            &-label {
                display: flex;
                justify-content: center;
                align-items: center;
                box-sizing: border-box;
                width: 100%;
                height: 20px;
                padding: 4px 8px;
                border-radius: 20px;
                transition: background-color 0.5s ease;
                font-weight: 700;
                white-space: nowrap;
                @include respond(small) {
                    height: 32px;
                    font-size: 16px;
                }
            }
        }

        &:checked {
            & ~ .#{$custom-input-class} {
                &-label {
                    background-color: $switch-checked-background-color;
                    color: $switch-checked-color;
                }
            }
        }
    }
    &--triple {
        @include respond(small) {
            height: 106px;
        }
    }
    &.is-disabled {
        background: #f6f6f6;
        input:checked ~ .o-ci-label {
            color: #f6f6f6;
            background: #cccccc;
        }
        input ~ .o-ci-label {
            color: #cccccc;
        }
    }
    &--bright {
        input {
            ~ .o-ci-label {
                color: $switch-unchecked-color-bright;
            }
            &:checked ~ .o-ci-label {
                color: $switch-color-bright;
            }
        }
        border: 1px solid $switch-border-bright;
        background-color: $switch-background-bright;
    }
}
