.#{$ns}grouped-item {
    $self: &;
    &__button {
        padding: 8px 0;
        display: flex;
        margin-bottom: 10px;
        min-height: 36px;
        box-sizing: border-box;
        background: transparent;
        border: none;
        width: 100%;
        &:focus {
            outline: none;
        }
    }
    &--0 {
        #{$self}__button {
            padding-left: 15px;
            @include respond(small) {
                padding-left: 10px;
            }
        }
    }
    &--1 {
        #{$self}__button {
            padding-left: 30px;
            @include respond(small) {
                padding-left: 20px;
            }
        }
    }
    &--2 {
        #{$self}__button {
            padding-left: 45px;
            @include respond(small) {
                padding-left: 30px;
            }
        }
    }
    &--3 {
        #{$self}__button {
            padding-left: 60px;
            @include respond(small) {
                padding-left: 40px;
            }
        }
    }
    .cm-scrollable__part--main.is-expanded {
        .cm-shadow--bottom {
            box-shadow: -3px 3px 3px 0px rgba(0, 0, 0, 0.07);
        }
    }
    &__arrow {
        padding-left: 2px;
        padding-right: 20px;
        @include respond(small) {
            padding-right: 10px;
        }
    }
    &__label {
        padding-left: 10px;
        font-weight: $font-weight-bold;
        max-width: 75%;
        text-align: left;
    }
    &__table {
        position: relative;
        .simplebar-vertical {
            right: auto;
            left: 0;
        }
    }
}
