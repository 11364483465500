.#{$ns}recent-documents {
    &__header {
        float: left;
        display: inline-block;
        width: 45%;
        @include respond(medium) {
            width: 100%;
        }
        @include respond(small) {
            width: 50%;
        }
        a {
            display: block;
            text-decoration: none;
            &:hover {
                text-decoration: underline;
                cursor: pointer;
            }
            &.disabled {
                &:hover {
                    text-decoration: none;
                    cursor: default;
                    color: $link-hover-color;
                }
            }
        }
    }
    &__container {
        width: 100%;
        display: flex;

        @include respond-lt(medium) {
            flex-direction: column;
        }
    }
    &__list-container {
        width: 50%;
    }
    &__item {
        position: relative;
        display: inline-block;
        width: 50%;
        float: left;
        @include respond-lte(medium) {
            width: 100%;
        }
        &--flex {
            position: initial;
            display: block;
            float: initial;
            width: 100%;
        }
        a {
            text-decoration: none;
            display: inline-block;
            width: 190px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-bottom: 0;
            &:hover {
                text-decoration: underline;
                cursor: pointer;
            }
            @include respond(small) {
                margin-right: 10px;
            }
        }
        &:hover {
            @include respond-gt(medium) {
                .#{$ns}recent-documents__hidden {
                    display: block;
                }
            }
        }
    }
    &__hidden {
        text-align: left;
        display: none;
        position: absolute;
        white-space: initial;
        top: 40px;
        left: 0;
        background-color: #ffffff;
        width: 100%;
        padding: 25px;
        box-sizing: border-box;
        box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.3);
        z-index: 1;
        &:after {
            position: absolute;
            top: -8px;
            left: 5px;
            display: block;
            content: '';
            width: 0;
            height: 0;
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            border-bottom: 8px solid #ffffff;
        }
        li {
            margin-bottom: 10px;
        }
    }
}
