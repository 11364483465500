$body-bg-color: initial !default;

:root {
    --app-height: 100%;
}

html,
body {
    min-height: 100%;
    height: var(--app-height);
    background-color: $body-bg-color;
    
}

.#{$ns}container {
    position: relative;
    &.sidebar-locked {
        padding-right: 477px;
        width: 100%;
    }
}

.#{$ns}main {
    &--bottom-toogle-group {
        padding-bottom: 80px;
    }
}
[v-cloak] {
    display: none;
}
