.#{$ns}table--mobile {
    tr {
        height: 48px;
    }
    @include respond-gt(medium) {
        .#{$ns}table-groups__thead th {
            text-align: right;
        }
    }
    &.#{$ns}table--double-expander {
        tr.is-hidden.first-level,
        tr.is-hidden.third-level {
            display: none;
        }
        .first-level,
        .second-level {
            .expander {
                td {
                    padding-left: 5px;
                }
                &.third-level {
                    td:last-of-type {
                        .cm-table__content {
                            min-width: 60px;
                        }
                    }
                    td:nth-of-type(2) {
                        .cm-table__content {
                            padding-right: 15px;
                        }
                    }
                    td:nth-of-type(3) {
                        .cm-table__content {
                            padding-right: 10px;
                        }
                    }
                }
            }
        }
        .is-expanded {
            tr.is-hidden.first-level {
                display: table-row;
                & > td {
                    padding-top: 0;
                }
                .#{$ns}table__content.second-level {
                    td {
                        padding-left: 15px;
                    }
                }
            }
            &.third-level {
                .is-hidden.third-level {
                    display: table-row;
                }
            }
        }
    }
    font-weight: $font-weight-normal;
    th:first-child,
    td:first-child {
        padding-left: 0;
        text-align: left;
    }
    tbody {
        th {
            color: $table-td-color;
            overflow: hidden;

            padding-left: 0;
            padding-right: 0;
        }
        tr.is-hidden {
            border: 0;
            display: none;
            td {
                padding: 0;
            }
        }
        &.is-expanded {
            tr {
                border: 0;
            }
            tr:last-child {
                display: table-row;
            }
        }
        &.expander {
            td,
            th {
                &:first-child {
                    & > div,
                    & > p {
                        display: flex;
                    }
                    .#{$ns}expand-trigger {
                        cursor: pointer;
                        span {
                            &:not(.g-icon):not(.date) {
                                //min-width: 77px;
                            }
                            &.g-icon {
                                padding-right: 10px;
                                @include respond-gte(large) {
                                    padding-top: 5px;
                                }
                            }
                        }
                    }
                    .cm-table__value.no-wrap {
                        white-space: nowrap;
                    }
                }
                &:not(:first-of-type) {
                    p {
                        white-space: nowrap;
                    }
                }
            }
            .cm-table-groups__thead th p {
                white-space: normal;
            }
        }
    }

    .#{$ns}table__summary {
        td:first-child,
        th:first-child {
            text-align: left;
            padding-left: 10px;
        }
        tr td .cm-table__footer__content {
            padding-left: 15px !important;
        }
        td:last-child {
            .cm-table__footer__content {
                padding-right: 10px !important;
            }
        }
    }
    .#{$ns}table-groups__summary,
    .#{$ns}table__summary {
        td:first-child,
        th:first-child {
            .cm-table__content {
                padding-left: 24px;
            }
        }
    }
    &-scroll {
        th {
            text-align: right;
            &:first-child {
                text-align: left;
            }
        }
    }
    &__cell {
        display: flex;
        justify-content: space-between;
        padding: 0;
    }
}
