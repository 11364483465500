.error-page {
    position: absolute;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    left: 0;
    top: 0;
    //box-sizing: border-box;
    &-bg {
        position: fixed;
        z-index: -1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #f5f5f5;
        //box-sizing: border-box;
    }
    &__content {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        margin: 0 auto;
        padding: 30px;
        @include respond(small) {
            padding: 20px;
        }
        background-color: #f5f5f5;
        box-sizing: border-box;
        a.o-btn {
            text-decoration: none;
            color: #1c343f;
            line-height: 30px;
        }
    }
    &__number {
        font-size: 15em;
        line-height: 1em;
        margin-bottom: 0;
        font-weight: normal;
        @include respond(small) {
            font-size: 10em;
        }
    }
    &__description {
        color: #4e4e50;
    }
    .cm-footer {
        position: absolute;
        right: 30px;
        @include respond(small) {
            right: 10px;
        }
        bottom: 0;
    }
}
