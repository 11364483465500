.#{$ns}table-multiple-expand {
    width: 100%;
    text-align: left;
    font-weight: $font-weight-bold;
    background-color: $table-background-color;
    &__wrapper {
        overflow: hidden;
        &__content {
            max-height: 350px;
            overflow-y: auto;
            @include scrollbar($body-color, transparent, 4px, 2px);
        }
    }
    &__cell {
        padding: 15px 10px;
        @include respond(small) {
            padding: 15px 5px;
        }
        &--first {
            text-align: left;
            display: flex;

            &-l2 {
                padding-left: 40px;
            }

            &-l3 {
                padding-left: 70px;
            }
        }
    }

    &__dot {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        margin-right: 8px;
        position: relative;
        top: 50%;
        transform: translateY(50%);
    }

    &__arrow {
        display: inline-block;
        width: 30px;

        .o-btn--link {
            padding-left: 5px;
            padding-right: 5px;
        }
    }

    &__selected-row {
        background-color: $table-fixed-expand-selected-background-color;
        border-color: $table-fixed-expand-selected-background-color;
    }

    tr {
        border-bottom: 1px solid $table-between-row-border-color;
    }

    th {
        background-color: $table-thead-th-background-color;
        color: $table-th-color;
        font-weight: $table-th-font-weight;
    }

    td {
        color: $table-td-color;
        font-weight: $table-td-font-weight;
    }
}
