.cm-clustered-bar-chart {
    width: 100%;
    height: 400px;
    position: relative;
    left: -50px;
}

.cm-divergent-bar-chart {
    width: 100%;
}

.cm-donut-chart {
    width: 100%;
}

.cm-line-chart {
    &__chart {
        width: 100%;
        height: 350px;
        left: -15px;
    }

    &__legend {
        width: 100%;
        height: 20px;

        &-container {
            width: 100%;
            display: flex;
            justify-content: space-between;
        }

        &-title {
            white-space: nowrap;
            color: $chart-legend-title-color;
        }
    }
}

.cm-mixed-line-bar-chart {
    width: 100%;
    height: 500px;
}

.cm-progress-chart {
    width: 100%;
    height: 140px;
    position: relative;
}

.cm-stacked-bar-chart {
    width: 100%;
}

.cm-area-stacked-chart {
    &__chart {
        width: 100%;
        height: 500px;
    }

    &__legend {
        width: 100%;

        &-container {
            width: 100%;
            display: flex;
            justify-content: space-between;
        }

        &-title {
            white-space: nowrap;
            color: $chart-legend-title-color;
        }
    }

    &__custom-legend {
        width: 100%;
    }

}

.custom-legend {
    position: absolute;
    right: 35px;
    &__title {
        display: inline-flex;
        float: left;
        margin-right: 10px;
    }
    &__labels {
        display: inline-flex;
        justify-content: space-between;
        width: 290px;
        p {
            display: inline-block;
            font-weight: $font-weight-bold;
        }
    }
    &__colors {
        display: flex;
    }
    &__item {
        display: inline-block;
        width: 45px;
        height: 15px;
    }
}
