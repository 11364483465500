$scrollable-table-tfoot-th-color: $color-white !default;

.ag-body-horizontal-scroll-viewport {
    overflow-x: scroll;
}

.interactive-table {
    .ag-theme-alpine .ag-root-wrapper-body {
        border: none;
    }
    .ag-row .ag-cell {
        display: flex;
        align-items: center;
        font-weight: 400;
        color: $body-color;
    }

    .ag-row-group {
        color: $body-color;
    }

    .ag-header-cell-label {
        color: $scrollable-table-th-color;
    }

    .ag-pinned-left-header {
        border-right: none;
    }

    .ag-pinned-right-header {
        border-left: none;
    }

    .ag-loading {
        .ag-loading-text {
            font-weight: 400;
            font-size: 0.7875rem;
            color: $body-color;
        }
    }

    .ag-row-even,
    .ag-row-odd {
        border: none;
    }

    .ag-pinned-left-cols-container {
        border-right: 1px solid #dde2eb;
    }

    .ag-pinned-right-cols-container {
        border-left: 1px solid #dde2eb;
    }

    .ag-pinned-left-floating-bottom {
        color: white;
        .ag-row-even,
        .ag-row-odd {
            background-color: $scrollable-table-tfoot-th-background-color !important;
            border-bottom: 1px solid #dde2eb;

            .ag-cell-value {
                color: $scrollable-table-tfoot-th-color !important;
            }
        }
    }

    .ag-body-horizontal-scroll:not(.ag-scrollbar-invisible) .ag-horizontal-right-spacer:not(.ag-scroller-corner) {
        border-left: none;
    }

    .ag-body-horizontal-scroll:not(.ag-scrollbar-invisible) .ag-horizontal-left-spacer:not(.ag-scroller-corner) {
        border: none;
    }

    .ag-floating-bottom {
        border: none;
        .ag-floating-bottom-container {
            .ag-row-pinned {
                background-color: $scrollable-table-tfoot-th-background-color !important;
                border-bottom: 1px solid #dde2eb;

                .ag-cell-value.ag-cell {
                    color: $scrollable-table-tfoot-th-color !important;

                    * {
                        color: $scrollable-table-tfoot-th-color !important;
                    }
                }
            }
        }
        .ag-pinned-right-floating-bottom {
            min-width: 154px;
            max-width: 154px;

            .ag-row-pinned {
                border-bottom: 1px solid #dde2eb;
                background-color: $scrollable-table-tfoot-th-background-color !important;

                .ag-cell-value.ag-cell {
                    color: $body-color !important;
                }
            }
        }
    }

    .ag-root ::-webkit-scrollbar {
        width: 4px;
    }

    .ag-root ::-webkit-scrollbar:horizontal {
        height: 4px;
    }

    .ag-horizontal-right-spacer {
        border-left: none;
    }

    .ag-root ::-webkit-scrollbar-button {
        background: transparent;
    }
    .ag-root ::-webkit-scrollbar-track-piece {
        background: transparent;
    }
    .ag-root ::-webkit-scrollbar-thumb,
    .ag-root ::-webkit-scrollbar-thumb:horizontal {
        background: currentColor;
        border-radius: 20px;
    }

    .cm-load-indicator {
        &:before {
            background-color: transparent;
            background-size: 2rem;
        }
    }
}

.right-align {
    justify-content: flex-end;

    .ag-header-cell-label {
        justify-content: flex-end;
    }

    // JUSTOWANIE HEADERA
    //.ag-header-cell-comp-wrapper {
    //    .ag-cell-label-container {
    //        .ag-header-cell-label {
    //            justify-content: flex-end;
    //        }
    //    }
    //}
}

.center-align {
    justify-content: center;

    .ag-header-cell-label {
        justify-content: center;
    }

    // JUSTOWANIE HEADERA
    //.ag-header-cell-comp-wrapper {
    //    .ag-cell-label-container {
    //        .ag-header-cell-label {
    //            justify-content: center;
    //        }
    //    }
    //}
}
