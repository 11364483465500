.js-scrollbar-measure {
    width: 100px;
    height: 100px;
    overflow: scroll;
    position: absolute;
    top: -9999px;
}

.u-custom-scrollbar {
    @include scrollbar($body-color, transparent, 4px, 15px, 2px);
}
