/*
 * hy.frontend.core
 * 
 * NOTE: this file is not subject to copyright transfer
 * 
 * UWAGA: plik nie objęty przekazaniem praw autorskich
 * 
 * 
 */





/*------------------------------------*\
    #TABLE GRID [G]
\*------------------------------------*/
////
/// TABLE GRID [G]
/// @group grid
/// @author marcin.krasowski
////

/// Defines tab le grid row class
$table-grid-row-class: 'table-row' !default;
/// Defines table grid column class
$table-grid-column-class: $grid-column-class !default;




// wrapper used for aligning table grid to regular one (negative margins on row)
.l-#{$table-grid-row-class}__wrapper {
    @include squish(-$grid-gutter);

    &--collapse {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

    // disable table grid on small
    @include respond-lte(small) {
        // change gutter if necessary
        @if ($grid-gutter-small != $grid-gutter) {
            @include squish(-$grid-gutter-small);
        }

        &.no-#{$table-grid-row-class}-small {
            margin-left: 0;
            margin-right: 0;

            .l-#{$table-grid-row-class} {
                display: block;
                width: auto;
                @include squish(-$grid-gutter);

                // change gutter if necessary
                @if ($grid-gutter-small != $grid-gutter) {
                    @include squish(-$grid-gutter-small);
                }

                & > .l-#{$table-grid-column-class} {
                    display: block;
                    float: left;
                }
            }
        }
    }

    // disable table grid on medium
    @include respond(medium) {
        // change gutter if necessary
        @if ($grid-gutter-medium != $grid-gutter) {
            @include squish(-$grid-gutter-medium);
        }

        &.no-#{$table-grid-row-class}-medium {
            margin-left: 0;
            margin-right: 0;

            .l-#{$table-grid-row-class} {
                display: block;
                width: auto;
                @include squish(-$grid-gutter);

                // change gutter if necessary
                @if ($grid-gutter-medium != $grid-gutter) {
                    @include squish(-$grid-gutter-medium);
                }

                & > .l-#{$table-grid-column-class} {
                    display: block;
                    float: left;
                }
            }
        }
    }

    // disable table grid on large
    @include respond-gte(large) {
        &.no-#{$table-grid-row-class}-large {
            margin-left: 0;
            margin-right: 0;

            .l-#{$table-grid-row-class} {
                display: block;
                width: auto;
                @include squish(-$grid-gutter);

                & > .l-#{$table-grid-column-class} {
                    display: block;
                    float: left;
                }
            }
        }
    }
}




// enable table grid
.l-#{$table-grid-row-class} {
    display: table;
    table-layout: fixed;
    width: 100%;
    margin-left: 0;
    margin-right: 0;

    & > .l-#{$table-grid-column-class} {
        display: table-cell;
        float: none;
    }
}
