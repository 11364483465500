/*------------------------------------*\
    #Mobile navigation
\*------------------------------------*/
////
/// @group navigation
/// @author marcin.krasowski
////





/// Defines margin from the viewport edge
$off-canvas-margin-horizontal:      50 !default;
/// Defines maximal width of the content
$off-canvas-max-width:              500 !default;
/// Defines content background
$off-canvas-background:             $color-white !default;
/// Defines overlay opacity
$off-canvas-overlay-opacity:        0.3 !default;





.o-off-canvas {
    position: relative;
    
    &__trigger {
        position: relative;
    }
    
    &__content {
        position: fixed;
        right: 0;
        top: 0;
        transform: translateX(100%);
        z-index: 100;

        padding-right: size($off-canvas-margin-horizontal);
        margin-right: size(-($off-canvas-margin-horizontal));

        height: 100%;
        width: 100%;
        max-width: size($off-canvas-max-width);
        background: $off-canvas-background;
        box-sizing: border-box;
        overflow: auto;
        visibility: hidden;

        @include transition-standard(transform, visibility);
    }
    
    &--left {
        padding-right: size($off-canvas-margin-horizontal);
        margin-right: size(-($off-canvas-margin-horizontal));
    }
    
    &--right {
        padding-left: size($off-canvas-margin-horizontal);
        margin-left: size(-($off-canvas-margin-horizontal));
    }
    
    &--collapse {
        .o-off-canvas__content {
            max-width: none;
            padding: 0;
            margin: 0;
        }
    }
    
    &:after {
        content: '';
        position: fixed;
        background: $color-black;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 9;
        opacity: 0;
        visibility: hidden;
        cursor: pointer;
        @include transition-standard(opacity, visibility);
    }
    
    &.is-open {
        &:after {
            opacity: $off-canvas-overlay-opacity;
            visibility: visible;
        }
        
        .o-off-canvas__content {
            visibility: visible;
            transform: translateX(0);
        }
    }
}



