.vb > .vb-dragger {
    z-index: 5;
    width: 4px;
    height: 4px;
    right: 0;
    bottom: 0;
    cursor: pointer;
}

.vb > .vb-dragger > .vb-dragger-styler {
    background-color: currentColor; // TODO THEME
    margin: 0;
    border-radius: 20px;
    height: 100%;
    display: block;
    .cm-main & {
        background-color: $vuebar-dragger-styler;
    }
}
.#{$ns}filters__list > .vb-dragger {
    right: 8px;
}

.styleguideVertical {
    height: 100px;
    &.vb > .vb-dragger > .vb-dragger-styler {
        background-color: currentColor; // TODO THEME
    }
}

.styleguideHorizontal {
    .vb-content {
        width: 100%;
        p {
            width: 3600px;
        }
    }
    &.vb > .vb-dragger {
        z-index: 5;
        left: 0;
        bottom: 0;
        cursor: pointer;
        height: 4px;
    }

    &.vb > .vb-dragger > .vb-dragger-styler {
        background-color: currentColor; // TODO THEME
        margin: 0;
        border-radius: 20px;
        height: 100%;
        display: block;
    }
}
