.#{$ns}subfilters {
    .#{$ns}cashflow-pacing {
        @include respond-gte(large) {
            position: absolute;
            visibility: hidden;
            opacity: 0;
            z-index: 10;
            background-color: $color-white;
            transition: visibility 0s ease 0.3s, opacity 0.3s ease;
            padding: 20px 0;
            margin-right: 10px;
            width: 100%;

            &__vertical-spacer {
                border-right: 1px solid $color-gray3;
            }

            &__secondary-pacing {
                padding-left: 25px;
            }
        }

        @include respond-lte(medium) {
            .l-col {
                float: left;
            }

            &__horizontal-spacer {
                border-top: 1px solid $color-gray3;
            }

            .o-btn {
                max-width: 100%;
                &:hover {
                    border-color: $color-white;
                }
            }
        }

        &__title {
            padding-bottom: 15px;

            @include respond-lte(medium) {
                padding-top: 20px;
                padding-bottom: 0;
                margin-top: 20px;
            }
        }

        &__link {
            @include respond-lte(medium) {
                text-align: center;
            }

            button {
                padding-top: 3px;
            }
        }

        input {
            text-align: center;

            &.error {
                border-color: $color-red;
            }
        }

        label {
            font-weight: $font-weight-normal;
        }

        &__expanded {
            visibility: visible;
            opacity: 1;
            transition-delay: 0s;
        }

        &__matrix-label {
            padding-top: 0;
        }

        &__error-alert {
            font-weight: $font-weight-bold;
            color: $color-red;
            padding: 15px 0;

            @include respond-lte(medium) {
                text-align: center;
            }
        }
    }
}
