/*------------------------------------*\
    #CUSTOM FORMS
\*------------------------------------*/

////
/// @group custom-forms
/// @author marcin.krasowski
////

/*------------------------------------*\
    GENERAL
\*------------------------------------*/

/// Defines custom checkbox wrapper class
$custom-checkbox-class:      'o-checkbox' !default;
/// Defines custom radio wrapper class
$custom-radio-class:         'o-radio'    !default;

/// Defines custom input class to be used inside a wrapper
/// @see $custom-checkbox-class
/// @see $custom-radio-class
$custom-input-class:         'o-ci'       !default;
/// Defines custom input label class to be used inside a wrapper
/// @see $custom-checkbox-class
/// @see $custom-radio-class
$custom-input-label-class:   'o-ci-label' !default;

/*------------------------------------*\
    CUSTOM INPUT
\*------------------------------------*/

// general
/// Defines custom input height
$custom-input-height:            17           !default;
/// Defines custom input width
$custom-input-width:             17           !default;
/// Defines space between custom input and text
$custom-input-and-text-space:    10           !default;

// background
/// Defines custom input background
$custom-input-background:        $color-white !default;
/// Defines custom input border color
$custom-input-border-color:      $color-gray2 !default;
/// Defines custom input border style
$custom-input-border-style:      solid        !default;
/// Defines custom input border width
$custom-input-border-width:      2            !default;
/// Defines custom input border radius
$custom-input-border-radius:     0            !default;

// disabled
/// Defines custom disabled input color
$custom-input-disabled-color:             $body-color                !default;
/// Defines custom disabled input background
$custom-input-disabled-background:        $color-gray3               !default;
/// Defines custom disabled input border color
$custom-input-disabled-border-color:      $custom-input-border-color !default;
/// Defines custom input disabled label color
$custom-input-disabled-label-color:       $body-color                !default;
/// Defines custom checked input disabled label color
$custom-input-disabled-checked-background: $custom-input-disabled-background !default;

// focus
/// Enabled custom focus states, otherwise standard outline is used
$custom-input-focus-custom:            false                      !default;
/// Defines custom focused input color
$custom-input-focus-color:             $body-color                !default;
/// Defines custom focused input background
$custom-input-focus-background:        $custom-input-background   !default;
/// Defines custom focused input border color
$custom-input-focus-border-color:      $color-brand1              !default;

// focus
/// Defines custom hovered input color
$custom-input-hover-color:             inherit                    !default;
/// Defines custom hovered input border color
$custom-input-hover-border-color:      inherit                    !default;

// tick
/// Defines custom input tick color
$custom-input-tick-color:        $color-gray3  !default;
/// Defines custom input tick font-family
$custom-input-tick-font-family:  'CustomIcons' !default;
/// Defines custom input tick size
$custom-input-tick-size:         16            !default;

/*------------------------------------*\
    CUSTOM CHECKBOX
\*------------------------------------*/

// general
/// Defines custom checkbox height
$custom-checkbox-height:               $custom-input-height           !default;
/// Defines custom checkbox width
$custom-checkbox-width:                $custom-input-width            !default;

// background
/// Defines custom checkbox background
$custom-checkbox-background:           $custom-input-background       !default;
/// Defines custom checkbox border color
$custom-checkbox-border-color:         $custom-input-border-color     !default;
/// Defines custom checkbox border style
$custom-checkbox-border-style:         $custom-input-border-style     !default;
/// Defines custom checkbox border width
$custom-checkbox-border-width:         $custom-input-border-width     !default;
/// Defines custom checkbox border radius
$custom-checkbox-border-radius:        $custom-input-border-radius    !default;

/// Defines custom disabled checkbox color
$custom-checkbox-disabled-color:          $custom-input-disabled-color        !default;
/// Defines custom disabled checkbox background
$custom-checkbox-disabled-background:     $custom-input-disabled-background   !default;
/// Defines custom disabled checkbox border color
$custom-checkbox-disabled-border-color:   $custom-input-disabled-border-color !default;
/// Defines custom disabled checkbox label color
$custom-checkbox-disabled-label-color:    $custom-input-disabled-label-color  !default;

// focus
/// Defines custom focused checkbox color
$custom-checkbox-focus-color:             $custom-input-focus-color        !default;
/// Defines custom focused checkbox background
$custom-checkbox-focus-background:        $custom-input-focus-background   !default;
/// Defines custom focused checkbox border color
$custom-checkbox-focus-border-color:      $custom-input-focus-border-color !default;

// focus
/// Defines custom hovered checkbox color
$custom-checkbox-hover-color:          $custom-input-hover-color        !default;
/// Defines custom hovered checkbox border color
$custom-checkbox-hover-border-color:   $custom-input-hover-border-color    !default;

// tick
/// Defines custom checkbox tick symbol
$custom-checkbox-tick:                 'check-mark'                   !default;
/// Defines custom checkbox tick color
$custom-checkbox-tick-color:           $custom-input-tick-color       !default;
/// Defines custom checkbox tick font-family
$custom-checkbox-tick-font-family:     $custom-input-tick-font-family !default;
/// Defines custom checkbox tick size
$custom-checkbox-tick-size:            $custom-input-tick-size        !default;
/// Defines custom checkbox tick offset from left
$custom-checkbox-tick-offset-left:     3                              !default;
/// Defines custom checkbox tick offset from top
$custom-checkbox-tick-offset-top:     -3                              !default;
/// Defines custom checkbox tick font family
$custom-checkbox-tick-font-family:     $custom-input-tick-font-family !default;

/*------------------------------------*\
    CUSTOM RADIO
\*------------------------------------*/

// general
/// Defines custom radio height
$custom-radio-height:               $custom-input-height              !default;
/// Defines custom radio width
$custom-radio-width:                $custom-input-height              !default;

// background
/// Defines custom radio background
$custom-radio-background:           $custom-input-background          !default;
/// Defines custom radio border color
$custom-radio-border-color:         $custom-input-border-color        !default;
/// Defines custom radio border style
$custom-radio-border-style:         $custom-input-border-style        !default;
/// Defines custom radio border width
$custom-radio-border-width:         $custom-input-border-width        !default;
/// Defines custom radio border radius
$custom-radio-border-radius:        50%                               !default;

/// Defines custom disabled radio color
$custom-radio-disabled-color:          $custom-input-disabled-color   !default;
/// Defines custom disabled radio background
$custom-radio-disabled-background:     $custom-input-disabled-background   !default;
/// Defines custom disabled radio border color
$custom-radio-disabled-border-color:   $custom-input-disabled-border-color !default;
/// Defines custom disabled radio label color
$custom-radio-disabled-label-color:    $custom-input-disabled-label-color  !default;

// focus
/// Defines custom focused radio color
$custom-radio-focus-color:             $custom-input-focus-color        !default;
/// Defines custom focused radio background
$custom-radio-focus-background:        $custom-input-focus-background   !default;
/// Defines custom focused radio border color
$custom-radio-focus-border-color:      $custom-input-focus-border-color !default;

// focus
/// Defines custom hovered radio color
$custom-radio-hover-color:          $custom-input-hover-color           !default;
/// Defines custom hovered radio border color
$custom-radio-hover-border-color:   $custom-input-hover-border-color    !default;

// tick
/// Defines custom radio tick symbol. If empty, it will be rendered as a simple dot.
$custom-radio-tick:                 ''                                !default;
/// Defines custom radio tick color
$custom-radio-tick-color:           $custom-input-tick-color          !default;
/// Defines custom radio tick font-family
$custom-radio-tick-font-family:     $custom-input-tick-font-family    !default;
/// Defines custom radio tick size
$custom-radio-tick-size:            $custom-input-tick-size / 2       !default;
/// Defines custom radio tick offset from left
$custom-radio-tick-offset-left:     0                                 !default;
/// Defines custom radio tick offset from top
$custom-radio-tick-offset-top:      0                                 !default;
/// Defines custom radio tick font family
$custom-radio-tick-font-family:     $custom-input-tick-font-family    !default;


/// Sets initial styles on custom input:
/// * hides real input offscreen
/// * absolutely places custom input elements:
///     * background
///     * tick
@mixin custom-input-init() {
    display: inline-block;
    position: relative;
    padding-left: size(($custom-input-width + $custom-input-and-text-space), $rel-unit, $form-label-font-size);
    padding-top: 0;
    padding-bottom: 0;
    margin: spacing($form-input-margin);

    @if ($form-input-margin-medium != $form-input-margin) {
        @include respond-lte(medium) {
            margin: spacing($form-input-margin-medium);
        }
    }

    @if ($form-input-margin-small != $form-input-margin and $form-input-margin-small != $form-input-margin-medium) {
        @include respond-lte(small) {
            margin: spacing($form-input-margin-small);
        }
    }
    
    input {
        position: absolute;
        left: -9999px;

        ~ .#{$custom-input-class} {
            position: absolute;
            cursor: pointer;
            display: inline-block;
            vertical-align: middle;
            left: 0;

            &:before, &:after {
                content: '';
                display: inline-block;
                position: relative;
                box-sizing: border-box;

                @include transition-standard-fast(border, background);
            }

            &:after {
                @include transition-standard-fast(color);
            }
        }

        ~ .#{$custom-input-label-class} {
            line-height: size($custom-input-height);
        }
    }
}

/// Sets styles on custom input wrapper
@mixin custom-input() {
    input ~ .#{$custom-input-class} {
        @content;
    }
}

/// Sets custom input background styles
@mixin custom-input-background() {
    input ~ .#{$custom-input-class}:before {
        @content;
    }
}

/// Sets custom input tick styles
@mixin custom-input-icon() {
    input ~ .#{$custom-input-class}:after {
        @content;
    }
}

/// Sets custom unchecked input background styles
@mixin custom-input-unchecked-background() {
    input:not(:checked) ~ .#{$custom-input-class}:before {
        @content;
    }
}

/// Sets custom unchecked input tick styles
@mixin custom-input-unchecked-icon() {
    input:not(:checked) ~ .#{$custom-input-class}:after {
        @content;
    }
}

/// Sets custom label for unchecked input
@mixin custom-input-unchecked-label() {
    input:not(:checked) ~ .#{$custom-input-label-class} {
        @content;
    }
}

/// Sets custom checked input background styles
@mixin custom-input-checked-background() {
    input:checked ~ .#{$custom-input-class}:before {
        @content;
    }
}

/// Sets custom checked input tick styles
@mixin custom-input-checked-icon() {
    input:checked ~ .#{$custom-input-class}:after {
        @content;
    }
}

/// Sets custom label for checked input
@mixin custom-input-checked-label() {
    input:checked ~ .#{$custom-input-label-class} {
        @content;
    }
}

/// Sets custom hovered input background styles
@mixin custom-input-hover-background() {
    input:hover ~ .#{$custom-input-class}:before {
        @content;
    }
}

/// Sets custom hovered input tick styles
@mixin custom-input-hover-icon() {
    input:hover ~ .#{$custom-input-class}:after {
        @content;
    }
}

/// Sets standard focus 
@mixin custom-input-focus() {
    input:focus ~ .#{$custom-input-class} {
        @content;
    }
}

/// Sets custom focused input background styles
@mixin custom-input-focus-background() {
    input:focus ~ .#{$custom-input-class}:before {
        @content;
    }
}

/// Sets custom focused input tick styles
@mixin custom-input-focus-icon() {
    input:focus ~ .#{$custom-input-class}:after {
        @content;
    }
}

/// Sets custom focused and checked input background styles
@mixin custom-input-focus-checked-background() {
    input:checked:focus ~ .#{$custom-input-class}:before {
        @content;
    }
}

/// Sets custom focused and checked input tick styles
@mixin custom-input-focus-checked-icon() {
    input:checked:focus ~ .#{$custom-input-class}:after {
        @content;
    }
}

/// Sets custom focused styles on label
@mixin custom-input-focus-label() {
    input:focus ~ .#{$custom-input-label-class} {
        @content;
    }
}

/// Sets custom invalid input background styles
@mixin custom-input-invalid-background() {
    input#{$form-input-invalid-class} ~ .#{$custom-input-class}:before {
        @content;
    }
}

/// Sets custom invalid input tick styles
@mixin custom-input-invalid-icon() {
    input#{$form-input-invalid-class} ~ .#{$custom-input-class}:after {
        @content;
    }
}

/// Sets custom disabled input label styles
@mixin custom-input-disabled-label() {
    input {
        &:disabled ~ .#{$custom-input-label-class} {
            @content;
        }
    }
}

/// Sets custom disabled input background styles
@mixin custom-input-disabled-background() {
    input {
        &:disabled ~ .#{$custom-input-class}:before {
            @content;
        }
    }
}

/// Sets custom disabled input tick styles
@mixin custom-input-disabled-icon() {
    input {
        &:disabled ~ .#{$custom-input-class}:after {
            @content;
        }
    }
}

/// Sets custom disabled checked input background styles
@mixin custom-input-disabled-checked-background() {
    input {
        &:disabled:checked ~ .#{$custom-input-class}:before {
            @content;
        }
    }
}

/// Sets custom disabled checked input background styles
@mixin custom-input-disabled-checked-icon() {
    input {
        &:disabled:checked ~ .#{$custom-input-class}:after {
            @content;
        }
    }
}

/// Sets custom readonly input tick styles
@mixin custom-input-readonly-background() {
    input {
        &[readonly] ~ .#{$custom-input-class}:before {
            @content;
        }
    }
}

/// Sets custom readonly input tick styles
@mixin custom-input-readonly-icon() {
    input {
        &[readonly] ~ .#{$custom-input-class}:after {
            @content;
        }
    }
}

/// Sets custom readonly checked input tick styles
@mixin custom-input-readonly-checked-icon() {
    input {
        &[readonly]:checked ~ .#{$custom-input-class}:after {
            @content;
        }
    }
}

/*------------------------------------*\
    CUSTOM INPUT
\*------------------------------------*/

.#{$custom-checkbox-class}, .#{$custom-radio-class} {
    @include custom-input-init;
}

.#{$custom-checkbox-class}--up, .#{$custom-radio-class}--up {
    padding-left: 0;
    padding-top: size(($custom-input-width + $custom-input-and-text-space), $rel-unit, $form-label-font-size);
    position: relative;
    @include custom-input {
        top: 0;
    }
}

.#{$custom-checkbox-class}-block, .#{$custom-radio-class}-block {
    padding-left: size(($custom-input-width + $custom-input-and-text-space), $rel-unit, $form-label-font-size);
}



/*------------------------------------*\
    CUSTOM CHECKBOX
\*------------------------------------*/

.#{$custom-checkbox-class} {
    min-height: size($custom-checkbox-height);
    min-width: size($custom-checkbox-width);

    // size
    
    @include custom-input() {
        height: size($custom-checkbox-height);
        width: size($custom-checkbox-width);
    }
    
    // default state
    
    @include custom-input-background() {
        border: size($custom-checkbox-border-width) $custom-checkbox-border-style $custom-checkbox-border-color;
        border-radius: size($custom-checkbox-border-radius);
        background: $custom-checkbox-background;
        height: size($custom-checkbox-height);
        width: size($custom-checkbox-width);
    }
    
    @include custom-input-icon() {
        opacity: 0;
        font-size: size($custom-checkbox-tick-size);
        font-weight: $font-weight-normal;
        color: $custom-checkbox-tick-color;
        content: icon-char($custom-checkbox-tick);
        line-height: 100%;
        position: absolute;

        left: size($custom-checkbox-tick-offset-left);
        top: size($custom-checkbox-tick-offset-top);
        
        @if $custom-checkbox-tick-font-family != '' {
            font-family: $custom-checkbox-tick-font-family;
        }
    }

    // hovered state

    @include custom-input-hover-icon() {
        color: $custom-checkbox-hover-color;
    }

    @include custom-input-hover-background() {
        border-color: $custom-checkbox-hover-border-color;
    }

    // checked state

    @include custom-input-checked-icon() {
        opacity: 1;
    }

    // disabled state

    @include custom-input-disabled-background() {
        background: $custom-checkbox-disabled-background;
        border-color: $custom-checkbox-disabled-border-color;
    }

    @include custom-input-disabled-icon() {
        color: $custom-checkbox-disabled-color;
    }

    @include custom-input-disabled-checked-background() {
        background-color: $custom-input-disabled-checked-background;
        border-color: $custom-input-disabled-checked-background;
    }

    @include custom-input-disabled-checked-icon() {
        color: $custom-checkbox-disabled-color;
    }

    @include custom-input-disabled-label() {
        color: $custom-checkbox-disabled-label-color;
    }

    // focused state
    @if ($custom-input-focus-custom) {
        @include custom-input-focus-background() {
            background: $custom-checkbox-focus-background;
            border-color: $custom-checkbox-focus-border-color;
        }

        @include custom-input-focus-icon() {
            color: $custom-checkbox-focus-color;
        }

        @include custom-input-focus-checked-background() {
            background-color: $custom-checkbox-focus-background;
            border-color: $custom-checkbox-focus-border-color;
        }

        @include custom-input-focus-checked-icon() {
            color: $custom-checkbox-focus-color;
        }
    } @else {
        @include custom-input-focus() {
            outline: size($acc-width) $acc-color solid;
            outline-offset: size($acc-offset);
        }
    }

    @include custom-input-invalid-background {
        border-color: $form-input-invalid-border-color;
        border-width: size($form-input-invalid-border-width);
    }

    @include custom-input-invalid-icon {
        color: $form-input-invalid-color;
    }
    
    
    //TODO: readonly
}




/*------------------------------------*\
    CUSTOM RADIO
\*------------------------------------*/

.#{$custom-radio-class} {
    min-height: size($custom-radio-height);
    min-width: size($custom-radio-width);

    // size
    
    @include custom-input() {
        height: size($custom-radio-height);
        width: size($custom-radio-width);
    }

    // default state

    @include custom-input-background() {
        border: size($custom-radio-border-width) $custom-radio-border-style $custom-radio-border-color;
        border-radius: size($custom-radio-border-radius);
        background: $custom-radio-background;
        height: size($custom-radio-height);
        width: size($custom-radio-width);
    }

    @include custom-input-icon() {
        opacity: 0;
        font-weight: $font-weight-normal;
        content: $custom-radio-tick;
        position: absolute;

        // if not using symbol as a tick, we use a simple dot
        @if($custom-radio-tick == '') {
            width: size($custom-radio-tick-size);
            height: size($custom-radio-tick-size);

            top: 50%;
            left: 50%;
            margin-top: size(calc($custom-radio-tick-size / -2));
            margin-left: size(calc($custom-radio-tick-size / -2));

            //background-color: $custom-radio-tick-color;
            border-radius: 100%;
        } @else {
            left: size($custom-radio-tick-offset-left);
            top: size($custom-radio-tick-offset-top);
        }

        // if not using symbol as a tick, we use a simple dot
        @if($custom-radio-tick == '') {
            font-size: 1em;
        } @else {
            font-size: size($custom-radio-tick-size);
        }
        
        color: $custom-radio-tick-color;

        @if $custom-radio-tick-font-family != '' {
            font-family: $custom-radio-tick-font-family;
        }
    }

    // hovered state

    @include custom-input-hover-icon() {
        border-color: $custom-radio-hover-color;
    }

    @include custom-input-hover-background() {
        border-color: $custom-radio-hover-border-color;
    }

    // checked state

    @include custom-input-checked-icon() {
        opacity: 1;
    }

    // disabled state

    @include custom-input-disabled-background() {
        background: $custom-radio-disabled-background;
        border-color: $custom-radio-disabled-border-color;
    }

    @include custom-input-disabled-icon() {
        color: $custom-radio-disabled-color;
    }

    @include custom-input-disabled-checked-background() {
        background-color: $custom-input-disabled-checked-background;
        border-color: $custom-input-disabled-checked-background;
    }

    @include custom-input-disabled-checked-icon() {
        color: $custom-radio-disabled-color;
        // if not using symbol as a tick, we use a simple dot
        @if($custom-radio-tick == '') {
            background-color: $custom-radio-disabled-color;
        }
    }

    @include custom-input-disabled-label() {
        color: $custom-radio-disabled-label-color;
    }

    // focused state

    @if ($custom-input-focus-custom) {
        @include custom-input-focus-background() {
            background: $custom-radio-focus-background;
            border-color: $custom-radio-focus-border-color;
        }

        @include custom-input-focus-icon() {
            color: $custom-radio-focus-color;
        }

        @include custom-input-focus-checked-background() {
            background-color: $custom-radio-focus-background;
            border-color: $custom-radio-focus-border-color;
        }

        @include custom-input-focus-checked-icon() {
            color: $custom-radio-focus-color;
        }
    } @else {
        @include custom-input-focus() {
            @include acc-focus;
        }
    }

    @include custom-input-invalid-background {
        border-color: $form-input-invalid-border-color;
        border-width: size($form-input-invalid-border-width);
    }

    @include custom-input-invalid-icon {
        color: $form-input-invalid-color;
    }
    
    //TODO: readonly
}



.o-checkbox-btn, .o-radio-btn {
    display: inline-block;
    width: $button-width;
    min-width: 0;
    min-height: 0;
    padding-left: 0;
    
    @include custom-input() {
        display: none;
    }
    
    input {
        & ~ .#{$custom-input-label-class} {
            line-height: inherit;
        }
    }

    @include custom-input-checked-label() {
        @extend %button-hover;
    }

    @include custom-input-focus-label {
        @include acc-focus;
    }
}


/*------------------------------------*\
    FAKE RADIO
\*------------------------------------*/
@mixin fake-radio {
    box-sizing: border-box;
    position: relative;
    display: inline-block;
    height: size($custom-radio-height);
    width: size($custom-radio-width);

    &:before {
        content: '';
        box-sizing: border-box;
        display: block;
        border: size($custom-radio-border-width) $custom-radio-border-style $custom-radio-border-color;
        border-radius: size($custom-radio-border-radius);
        background: $custom-radio-background;
        height: size($custom-radio-height);
        width: size($custom-radio-width);
    }

    &:after {
        opacity: 0;
        font-weight: $font-weight-normal;
        content: $custom-radio-tick;
        position: absolute;
        display: block;
        @if($custom-radio-tick == '') {
            width: size($custom-radio-tick-size);
            height: size($custom-radio-tick-size);

            top: 50%;
            left: 50%;
            margin-top: size(- $custom-radio-tick-size / 2);
            margin-left: size(- $custom-radio-tick-size / 2);

            //background-color: $custom-radio-tick-color;
            border-radius: 100%;
        } @else {
            left: size($custom-radio-tick-offset-left);
            top: size($custom-radio-tick-offset-top);
        }
        // if not using symbol as a tick, we use a simple dot
        @if($custom-radio-tick == '') {
            font-size: 1em;
        } @else {
            font-size: size($custom-radio-tick-size);
        }

        color: $custom-radio-tick-color;

        @if $custom-radio-tick-font-family != '' {
            font-family: $custom-radio-tick-font-family;
        }
    }

    @content;
}

@mixin fake-radio-hover-background {
    &:before {
        border-color: $custom-radio-hover-border-color;
        @content;
    }
}

@mixin fake-radio-hover-icon {
    &:after {
        border-color: $custom-radio-hover-color;
        @content;
    }
}

@mixin fake-radio-checked-background {
    &:before {
        @content;
    }
}

@mixin fake-radio-checked-icon {
    &:after {
        opacity: 1;
        @content;
    }
}
