/*
 * hy.frontend.core
 * 
 * NOTE: this file is not subject to copyright transfer * 
 * UWAGA: plik nie objęty przekazaniem praw autorskich * 
 * 
 */





/*------------------------------------*\
    #TRANSITIONS
\*------------------------------------*/

////
/// @group tools
/// @author marcin.krasowski
////

/// Defines standard transition duration
$transition-standard-duration: 0.4s !default;
/// Defines standard slow transition duration
$transition-standard-duration-slow: 0.8s !default;
/// Defines standard fast transition duration
$transition-standard-duration-fast: 0.2s !default;
/// Defines standard transition easing
$transition-standard-ease: ease !default;



/// Sets custom transition on element
/// @param {Number} $duration - transition duration
/// @param {String} $ease - transition easing type
/// @param {String | List} $properties - list of properties for transition
@mixin transition($duration, $ease, $properties) {
    $val: '';
    
    @for $i from 1 through length($properties) {
        $comma: if($val == '', '', ', ');
        $val: unquote('#{$val}#{$comma}#{nth($properties, $i)} #{$duration} #{$ease}');
    }
    transition: $val;
}

/// Sets standard transition on element
/// @param {String | List} $property - list of properties for transition
@mixin transition-standard($properties...) {
    @include transition($transition-standard-duration, $transition-standard-ease, $properties);
}

/// Sets standard fast transition on element
/// @param {String | List} $properties - list of properties for transition
@mixin transition-standard-fast($properties...) {
    @include transition($transition-standard-duration-fast, $transition-standard-ease, $properties);
}

/// Sets standard slow transition on element
/// @param {String | List} $properties - list of properties for transition
@mixin transition-standard-slow($properties...) {
    @include transition($transition-standard-duration-slow, $transition-standard-ease, $properties);
}
