.#{$ns}table-simple-expand {
    width: 100%;
    text-align: left;
    font-weight: $font-weight-bold;
    background-color: $table-background-color;
    tr {
        border-bottom: 1px solid $table-between-row-border-color;
    }
    th {
        background-color: $table-thead-th-background-color;
        color: $table-th-color;
        font-weight: $table-th-font-weight;
    }
    th,
    td {
        padding: 15px 10px;
        @include respond(small) {
            padding: 15px 5px;
        }
        &:first-child {
            text-align: left;
        }
    }
    td {
        color: $table-td-color;
        font-weight: $table-td-font-weight;
    }
    &__tbody {
        background-color: $table-tr-stripped-color-odd;
        &.has_expander {
            .item-to-expand {
                display: none;
            }
        }
        &.is-expanded {
            .item-to-expand {
                display: table-row;
            }
        }
        &--even {
            background-color: $table-tr-stripped-color-even;
        }
    }
    &__cell {
        &--first {
            width: 1px;
            padding-left: 5px;
            padding-right: 5px;
        }
    }
    .o-btn--link {
        padding-left: 5px;
        padding-right: 5px;
    }
    .list-to-expand {
        display: none;
    }
    .has_expander.is-expanded + .list-to-expand {
        display: table-row;
    }
    &__li {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
    }
}
