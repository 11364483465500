.#{$ns}notification {
    &-box {
        max-width: calc(100vw - 60px);
        width: 100%;
        box-sizing: border-box;
        border-bottom: 4px solid $icons-with-background-background-color;
        background: $color-white;
        box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.2);
        padding: size(15) size(45) size(15) size(40);
        position: relative;
        height: auto;
        min-height: size(20);
        float: right;
        clear: both;
        &__icon {
            margin-left: size(-30);
            float: left;
        }
        &__close {
            position: absolute;
            top: 0;
            right: 0;
            padding: size(10);
            line-height: 1em;
        }
    }
    &__list {
        position: fixed;
        top: 100px;
        right: 0;
        width: 100%;
        max-width: 505px;
        padding: 0 30px;
        box-sizing: border-box;
        z-index: 100;
        @include respond(small) {
            top: 150px;
        }
        li + li {
            padding-top: 10px;
            clear: both;
        }
    }
}
