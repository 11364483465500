.#{$ns}company-details-boxes {
    &__group {
        display: flex;
        flex-wrap: wrap;
    }

    &__group--wide &__row {
        flex: 0 0 100%;
    }

    &__row {
        flex: 0 0 100%;

        @include respond-gt(medium) {
            flex: 0 0 calc(100% * 1 / 3);
        }
    }

    &__item {
        padding: 15px 15px 15px 0;
        position: relative;
    }
}
