.#{$ns}box {
    box-sizing: border-box;
    background-color: $boxes-bg-color;
    box-shadow: -1px 1px 20px 0px rgba(0, 0, 0, 0.15);
    width: 100%;
    height: 100%;
    padding: 30px;
    @include respond(small) {
        padding: 15px;
    }
    position: relative;

    &__button--close {
        background-color: transparent;
        color: $boxes-button-close-color;
        border-width: 0;
        position: absolute;
        right: 15px;
        top: 17px;
        span:before {
            font-size: 15px;
            font-weight: $font-weight-bold;
        }
    }
    &__header {
        font-size: 16px;
        font-weight: $font-weight-bold;
    }
    &__content {
        min-height: 400px;
    }
    .amcharts-chart-div {
        height: 260px;
        @include respond(small) {
            width: 180px;
        }
    }
    .o-chart__legend {
        svg:not(:root) {
            overflow: visible;
        }
    }
}

.#{$ns}boxes__row {
    position: relative;
    height: auto;
    @include respond-gt(medium) {
        display: flex;
        justify-content: center;
    }
    .is-hidden {
        width: 0;
        opacity: 0;
        height: 0;
        overflow: hidden;
        @include transition-standard-slow(width);
        @include respond(small) {
            height: 0;
        }
        .o-chart,
        .#{$ns}table {
            opacity: 0;
            @include transition-standard-slow(opacity);
        }

        &.active {
            overflow: visible;
            @include respond-gt(medium) {
                width: 33.33333%;
            }
            width: 100%;
            height: auto;
            @include transition-standard(width);
            opacity: 1;
            .o-chart,
            .#{$ns}table {
                opacity: 1;
                @include transition-standard(opacity);
                transition-delay: 0.3s;
            }
        }
    }
    @include respond-lte(medium) {
        .active {
            margin-top: 100px;
            display: block;
            height: auto;

            &:last-of-type {
                margin-top: 200px;
            }
        }
        .prev-active {
            position: absolute;
        }
    }
}
