.cm-benchmark-popup {
    &__selectors {
        display: flex;
        justify-content: space-between;

        @include respond(small) {
            border: 1px solid $o-separator-color;
            border-radius: 15px;
            padding: 15px;
        }

        & > button {
            align-self: flex-end;
            background-color: $icons-with-background-background-color;
            color: $icons-with-background-color;
        }

        .dropdown-menu {
            max-height: 200px !important;
        }

    }

    &__autocomplete {
        .cm-autocomplete {
            box-sizing: border-box;
            border: 1px solid #ccc;
            border-radius: 15px;

            &__input {
                border: 0;
                z-index: 0;
            }

            &.open {
                input,
                input:hover,
                input:focus {
                    background-color: $color-white;
                }
            }

            &__error {
                margin: 7px 0;
            }
        }
    }

    &__alert-icon {
        background-color: $color-error;
        color: $color-white;
    }
}
