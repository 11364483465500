.userSettingsRow {
    .userSettingsColumn {
        margin: 0 auto;
        float: none;
        .switchTab {
            label {
                color: $color-gray4;
            }
            .fadingElement {
                opacity: 0;
                &.inputIsVisible {
                    opacity: 1;
                    transition: opacity 0.5s ease;
                }
            }
        }
        .o-btn {
            @include respond(small) {
                height: 40px;
                border-radius: 20px;
                font-size: 16px;
            }
        }
    }
}
