.#{$ns}input {
    width: 100%;

    input {
        @include respond(small) {
            height: 40px;
            border-radius: 20px;
            font-size: 1.27em;
        }
    }

    &--with-button {
        display: inline-block;
        position: relative;

        input {
            height: 32px;
            padding-right: 35px;

            &:focus {
                outline: none;
            }

            @include respond(small) {
                height: 40px;
                border-radius: 20px;
                font-size: 16px;
            }
        }

        .#{$ns}orb {
            position: absolute;
            right: 3px;
            top: 3px;
            @include respond(small) {
                top: 6px;
                right: 5px;
            }
        }
    }

    &__group {
        border: size($form-input-border-width) solid $form-input-border-color;
        border-radius: size($form-input-border-radius + 4);
        padding: 2px;

        input[type='text'] {
            border: none;
        }

        margin-bottom: 50px;
    }

    /*
        &-with-tree {
            &__tree {
                display: none;
            }
            input:focus + &__tree {
                display: block;
            }
        }
    */
    &.input-with-hint {
        & + .hint-container .#{$ns}hint {
            display: none;
            position: absolute;
            left: 0;
            margin-top: 10px;
            padding: 10px 10px 10px 30px !important;
            opacity: 0;
            transition: opacity 0.3s ease;
            z-index: 100;
            box-sizing: border-box;
            list-style-type: disc !important;

            &::after {
                display: block;
                content: '';
                position: absolute;
                top: -5px;
                left: 10px;
                width: 0;
                height: 0;
                border-left: 5px solid transparent;
                border-right: 5px solid transparent;
                border-bottom: 5px solid $color-white;
            }

            .#{$ns}__item {
                width: 100%;
                float: left;
            }
        }

        &.hint-visible {
            & + .hint-container .#{$ns}hint {
                display: table;
                opacity: 1;
                box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.3);
                background-color: $color-white;

                &__item {
                    p:not(:first-child) {
                        padding-left: 10px;
                    }
                }

                @include respond-lte(medium) {
                    padding: 10px;
                }
            }
        }
    }

    //removes arrows for input with type number

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }
}
