@import '../settings';

.#{$ns}details-box {
    &__container {
        background-color: $detail-box-bg-color;
        border-radius: 15px;
        min-width: 33%;
        &--shadow {
            box-shadow: 0px 3px 3px rgba(86, 86, 86, 0.3);
        }
        &--spacing {
            padding: 15px;
        }
        &--h-100 {
            height: 100%;
            box-sizing: border-box;
        }
        &--longer {
            background-color: $detail-box-bg-color-longer;
        }
    }
    &__header {
        height: 62px;
        display: flex;
        align-items: center;
        padding: 0 18px;
    }
    &__icon {
        margin-left: auto;
        &:hover {
            cursor: pointer;
        }
    }
    &__content {
        background-color: $detail-box-content-bg-color;
        width: 100%;
        padding: 15px;
        box-sizing: border-box;
        vertical-align: top;
        overflow: hidden;
        border-radius: 0 0 15px 15px;
    }
    &__item-name {
        width: 50%;
        color: $color-gray4;
    }
    &__item-value {
        width: 50%;
        color: $color-gray4;
        &--fullWidth {
            width: 100%;
        }
    }
    &__item-element {
        display: flex;
    }
    &__column-item {
        min-width: 33%;
        padding: 5px 5px 15px 0;
    }
}
