.expander {
    &__element,
    .item-to-expand,
    .item-to-expand-2-lvl ul {
        max-height: 0;
        transition: max-height 0.3s, visibility 0.3s;
        overflow: hidden;
        visibility: hidden;
    }

    &.is-expanded,
    &__item.is-expanded {
        .expander__element:not(.item-to-expand-2-lvl),
        .item-to-expand {
            max-height: 400px !important;
            visibility: visible !important;
        }
        .expander.is-expanded .item-to-expand-2-lvl ul {
            max-height: 400px !important;
            visibility: visible !important;
        }
    }
}
