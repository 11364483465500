.cm-exposure-box {
    &__buttons {
        flex-direction: row;
        display: flex;
        width: 100%;

        & > button {
            align-self: flex-end;
            background-color: $icons-with-background-background-color;
            color: $color-white;
            margin-right: 20px;
        }
    }
    &__wrapper {
        max-height: 50vh;
        overflow: auto;
        @include scrollbar($body-color, transparent, 4px, 2px);
    }
    &__table {
        .cm-table-multiple-expand__cell {
            width: 25%;
            white-space: nowrap;
            &:first-child {
                white-space: unset;
                width: 50%;
            }
        }
    }

    .py-20 {
        padding: 30px 0 30px 0;
    }

    .cm-orb {
        background-color: $icons-with-background-background-color;
        color: $color-black;
        margin-left: 10px;
    }

    .title {
        font-size: medium;
        font-weight: 900;
        align-self: center;

        &__small {
            font-size: small;
        }
    }
    .cm-orb__inner {
        line-height: 1;
    }
}
