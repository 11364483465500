$table-dark: #e8e8e8;

.#{$ns}admin-panel {
    --ag-header-column-separator-color: #e8e8e8;
    --ag-border-color: #e8e8e8;
    position: relative;
    padding-top: 20px;
    width: 100%;

    &__pagination {
        height: 50px;
        margin-top: 5px;
        &__status {
            display: block;
        }
        &__page {
            display: block;
            text-align: center;
            & > .o-btn,
            & > span {
                vertical-align: middle;
            }
        }
        button {
            width: auto;
            margin: 0 3px;
            padding: 1px 6px;
            border: none;
            font-weight: $font-weight-normal;
            font-size: 1em;
            text-decoration: none;
            &.o-btn {
                color: $link-color;
                &:disabled {
                    background: none;
                    color: $color-gray5;
                    text-decoration: none;
                    &:hover,
                    &:focus,
                    &:active {
                        background: none;
                        color: $color-gray5;
                        text-decoration: none;
                    }
                }
                &:hover,
                &:focus,
                &:active {
                    background: none;
                    color: $link-hover-color;
                    text-decoration: underline;
                }
                &:focus {
                    color: #8db9cd;
                }
            }
        }
    }
    &__add-user,
    &__edit-user {
        &__user-basic-data {
            .#{$ns}form__field:not(:first-child) h4.h5 {
                margin-top: 51px;
            }
            .#{$ns}form__field__close {
                margin: -4px 0 0 20px;
                padding: 0;
                border: 0;
            }
            .#{$ns}add-contact-address {
                cursor: pointer;
            }
            .none-opt {
                .dropdown-menu li:first-child {
                    a {
                        font-weight: $font-weight-normal !important;
                        font-style: italic !important;
                        color: $color-gray4 !important;
                    }
                    &:hover,
                    &.highlight {
                        a {
                            color: $color-white !important;
                        }
                    }
                }
            }
        }
        &__access-rights {
            .modal-open,
            .modal-default {
                width: auto;
                max-width: none;
            }
            .modal-open {
                float: left;
            }
            .modal-default {
                float: right;
            }
        }
    }
    &__user-details {
        &__user-basic-data {
            .#{$ns}list__field:not(:first-child) h4.h5 {
                margin-top: 63px;
            }
        }
    }
    &__document-profile {
        textarea {
            resize: none;
        }
    }
    &__checkboxes {
        display: flex;
        ul {
            max-width: 33.33333%;
            box-sizing: border-box;
            padding-right: 20px;
        }
        li {
            padding: 3px;
        }
    }
    &__user-table {
        .cm-orb__inner {
            line-height: 2;
        }
    }
    &__tooltip {
        p {
            display: inline-block;
            line-height: 36px;
            width: calc(100% - 50px);
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .cm-tooltip {
            display: inline-block;
            position: absolute;
            right: 10px;
            &__container {
                padding: 10px !important;
                background-color: white;
                position: absolute;
                right: -200px;
                top: -10px;
                width: 200px;
                box-shadow: 0 0 33px -17px rgba(0, 0, 0, 0.34);
            }
        }
    }
    &__grid {
        display: grid;
        &--5 {
            grid-template-columns: repeat(5, 1fr);
            grid-gap: 10px 20px;
            @include respond-lt(large) {
                grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
            }
        }
        &--1-4 {
            grid-template-columns: min-content repeat(4, 1fr);
            grid-gap: 10px 20px;
            @include respond-lt(medium) {
                grid-template-columns: 1fr;
                grid-gap: 10px;
            }
        }
    }
}
