/*
 * hy.frontend.core
 * 
 * NOTE: this file is not subject to copyright transfer * 
 * UWAGA: plik nie objęty przekazaniem praw autorskich * 
 * 
 */





/*------------------------------------*\
    #CONTENT UNDERLINE
\*------------------------------------*/

////
/// @group hacks
/// @author marcin.krasowski
////

/// defines list of browsers for which content underline fix should be applied
$link-fix-content-underline-for-browsers: ie !default;

/// removes text-decoration on element's :before/:after content
/// @link http://stackoverflow.com/a/21902566
@mixin fix-content-underline() {

    @if $link-fix-content-underline-for-browsers == 'all' {
        &:before, &:after {
            text-decoration: underline;
        }
        &:before, &:after {
            text-decoration: none;
        }
    }
    @else if $link-fix-content-underline-for-browsers != '' {
        @include support-browsers($link-fix-content-underline-for-browsers) {
            &:before, &:after {
                text-decoration: underline;
            }
            &:before, &:after {
                text-decoration: none;
            }
        }
    }

}
