$table-background-color: transparent !default;

.#{$ns}table {
    $self: &;
    width: 100%;
    text-align: right;
    font-weight: $font-weight-bold;
    background-color: $table-background-color;

    tr {
        border-bottom: 1px solid $table-between-row-border-color;
    }

    th {
        box-sizing: border-box;
        background-color: $table-thead-th-background-color;
        color: $table-th-color;
        font-weight: $table-th-font-weight;
        position: relative;

        &:first-child {
            text-align: left;
        }

        &.display-color {
            position: relative;
        }

        &.is-sortable {
            #{$self}__content {
                padding-right: 30px;
            }
        }
    }

    td {
        color: $table-td-color;
        font-weight: $table-td-font-weight;

        &.hover {
            background-color: $table-td-hover-background !important;
        }
    }

    &__content {
        padding: 15px 10px;
        box-sizing: border-box;
        @include respond(small) {
            padding: 15px 5px;
        }
    }

    &__thead.counted {
        position: absolute;
        left: -9999em;
        top: -9999em;
    }

    &__thead th {
        .cm-table__sort__arrow {
            display: none;
            position: absolute;
            top: 50%;

            &.g-icon {
                &--down,
                &--up {
                    right: 5px;
                    cursor: pointer;

                    &:before {
                        color: #cccccc;
                    }

                    &.sort {
                        &:before {
                            color: $body-color;
                        }
                    }
                }

                &--down {
                    transform: translateY(calc(-50% + 5px));
                }

                &--up {
                    transform: translateY(calc(-50% - 5px));
                }
            }
        }

        .cm-table__content {
            white-space: nowrap;

            p {
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }

            .g-icon--move {
                display: none;
            }

            .sorted .g-icon {
                display: inline-block;
            }
        }

        .cm-table__header__label {
            width: 99em;
            white-space: nowrap;
        }

        &.hover,
        &.sortable-chosen,
        &[draggable~='true'] {
            background-color: $table-td-hover-background !important;
            position: relative;
            z-index: 1;
            vertical-align: middle;

            .cm-table__content {
                display: flex;
                align-items: baseline;
            }

            .g-icon {
                display: block;

                &--move {
                    &:hover {
                        cursor: move;
                    }
                }

                &--down,
                &--up {
                    right: 6px;
                }
            }

            .cm-table__header__label {
                white-space: normal;
                overflow: visible;
                word-break: normal;
                font-size: 10px;
                line-height: 15px;
            }
        }
    }

    &--spacing-right {
        th,
        td {
            &:last-child {
                padding-right: 40px;
                @include respond(small) {
                    padding-right: 10px;
                }
            }
        }
    }

    &__text {
        max-width: 250px;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &__content {
        .cm-tooltip--visible {
            display: inline-block;
        }

        &--arrow {
            .g-icon {
                margin-right: 10px;
            }
        }

        &--narrow {
            width: 260px;
            justify-content: space-between;

            &-m {
                width: 275px;
            }
        }

        &--centered {
            display: flex;
            width: 300px;

            @include respond-lt(large) {
                width: 130px;
            }
        }

        .periodic-col {
            color: $periodic-column-color;
        }

        .g-icon--arrow_down,
        .g-icon--arrow_up {
            padding-right: 5px;
        }
    }

    &__row-number {
        display: inline-block;
        width: 16% !important;
        padding-right: 0 !important;
        box-sizing: border-box;

        .cm-table__content {
            min-width: auto;
        }

        & + td {
            display: inline-block;
            width: 84%;
            box-sizing: border-box;
            background: none !important;

            .cm-table__content {
                border-top-width: 0 !important;
            }
        }
    }

    &-groups__summary {
        background-color: $color-blue6;

        th,
        td {
            font-weight: $font-weight-bold;
        }
    }

    &__summary {
        background-color: $table-summary-background-color;

        th,
        td {
            color: $color-white;
            font-weight: $font-weight-bold;
            background-color: $table-tfoot-th-background-color;
        }
    }

    &,
    .cm-table-groups.bulk-actions .cm-partial__body {
        tbody.expander {
            max-width: 100%;

            .cm-partial__row {
                width: 100%;
                text-align: right;

                td {
                    .o-checkbox {
                        box-sizing: border-box;
                    }

                    .cm-table__value {
                        &--single .cm-table__value {
                            max-width: 85% !important;
                            display: inline-block;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            overflow: hidden;
                        }

                        max-width: 83%;
                    }
                }
            }
        }
    }

    &-groups {
        &__list {
            table + table {
                .#{$ns}table-groups__thead {
                    display: none;
                }
            }
        }

        .#{$ns}table &__tfoot {
            background: $color-blue6;
        }

        &__name {
            p {
                color: $color-black;
                font-weight: $font-weight-bold;
                text-align: left;
                max-width: 90%;
            }
        }

        &__row--large {
            height: 47px;
        }

        &__standard-list,
        &__thead,
        &__summary {
            font-weight: $font-weight-bold;

            td:first-child,
            th:first-child {
                text-align: left;
            }
        }
    }

    &--generic {
        thead {
            text-align: left;

            th {
                font-weight: $table-th-font-weight;

                &:hover:not(:first-of-type) {
                    cursor: pointer;
                }
            }

            .g-icon {
                font-weight: $font-weight-bold;
                color: $color-blue3;
            }
        }

        tbody {
            @include respond-lt(large) {
                max-height: 70vh;
                overflow: scroll;
            }

            tr {
                background-color: $table-td-background-color;
                text-align: left;
                font-weight: $font-weight-normal;

                td {
                    &.show .cm-table__content {
                        position: relative;
                        width: 350px;
                        height: 35px;

                        p {
                            margin-left: 0 !important;
                        }

                        .cm-table__container--hover {
                            margin-top: 5px;
                        }

                        .cm-table__hidden-content {
                            display: none;
                            background-color: #ffffff;
                            box-shadow: -2px 3px 19px -6px rgba(0, 0, 0, 0.26);
                            padding: 6px;
                            border-radius: 40px;
                            position: absolute;
                            top: -3px;
                            left: 0;
                            box-sizing: border-box;
                        }

                        & > div {
                            padding: 5px 0;
                        }

                        a {
                            text-decoration: none;
                            transform: translateY(3px);
                        }

                        &:hover {
                            overflow: visible;

                            p {
                                opacity: 0;
                            }

                            a {
                                display: inline-block;
                                cursor: pointer;
                                text-decoration: underline;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                                overflow: hidden;
                                max-width: 330px;
                            }

                            .cm-table__hidden-content {
                                display: inline-block;
                            }

                            & > div {
                                padding: 5px 0;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                                overflow: hidden;
                                max-width: 198px;
                            }
                        }
                    }

                    .cm-table__content {
                        max-width: unset;
                        border-top-color: transparent !important;
                    }
                }

                &.#{$ns}partial__row {
                    td.show {
                        p {
                            //max-width: 100%;
                        }
                    }
                }

                .o-checkbox {
                    padding-left: 0.5em;

                    input ~ .o-ci {
                        top: 3px;

                        &:before {
                            background-color: transparent;
                            border: 1px solid $custom-checkbox-icon-unchecked-color;
                        }
                    }

                    input {
                        &:checked {
                            & ~ .#{$custom-input-class}:before {
                                border: 1px solid $custom-checkbox-checked-border-color;
                                background-color: $custom-checkbox-checked-border-color;
                            }

                            & ~ .#{$custom-input-class}:after {
                                color: $color-white;
                            }
                        }
                    }
                }
            }
        }

        td .cm-table__content {
            padding: 10px;
        }

        .#{$ns}table__expand {
            &-item {
                padding-top: 15px;
                padding-left: 34px;
            }

            &-trigger {
                &:hover {
                    cursor: pointer;
                }
            }
        }
    }

    &--simple {
        th,
        td {
            padding: 15px 10px;
            @include respond(small) {
                padding: 15px 5px;
            }
        }

        p {
            white-space: nowrap;

            span.#{$ns}table__legend-label {
                white-space: normal;
                display: block;
            }
        }

        p:not(.#{$ns}table__legend-label) {
            @include respond-gte(large) {
                white-space: nowrap;
            }
        }
    }

    &--extended {
        margin: 0;

        &__wrapper {
            overflow-x: auto;
        }

        td,
        th {
            padding: 0;

            .cm-table__content {
                padding: 16px 10px 0 10px;
                height: 48px;
                white-space: nowrap;
                min-width: 80px;
                box-sizing: border-box;
                overflow: hidden;
                text-overflow: ellipsis;

                p {
                    //padding-top: 15px;
                }

                &--removed {
                    color: $color-gray3;
                }
            }

            &:first-child {
                .cm-table__content {
                    p span:not(.is-full):not(.is-empty) {
                        display: inline-block;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        max-width: 300px;
                    }

                    &:hover {
                        p span:not(.is-full):not(.is-empty) {
                            max-width: unset;
                        }
                    }
                }
            }

            &.with-checkbox {
                &:first-child {
                    .cm-table__content {
                        width: fit-content !important;
                        padding-right: 0;

                        p {
                            width: fit-content !important;
                            margin-block-end: 0;
                            margin-block-start: 0;
                        }

                        p span {
                            max-width: 200px !important;
                        }

                        &:hover {
                            p span {
                                max-width: unset !important;
                            }
                        }
                    }
                }
            }
        }

        thead {
            td,
            th {
                border-bottom: 1px solid $color-gray2;

                .#{$ns}table__content {
                    padding-top: 16px;
                }
            }
        }

        tbody {
            td,
            th {
                &:not(:first-child) {
                    background: $table-td-background-color;

                    .cm-table__content {
                        border-top: 1px solid $table-between-row-border-color;
                    }
                }
            }

            tr {
                position: relative;
                border: none;

                td {
                    .cm-table__content {
                        p {
                            display: inline-block;
                        }

                        &:nth-of-type(2) p {
                            span {
                                transform: translateY(5px);
                            }
                        }
                    }
                }

                &:first-child {
                    td,
                    th {
                        //padding-top: 1px;
                    }

                    .cm-table__content {
                        border: none;
                    }
                }
            }
        }

        .#{$ns}table__tbody-name + tr,
        tr:first-child {
            td > .cm-table__content {
                border: none;
            }
        }

        tfoot {
            td:not(:first-child) {
                background: $table-extended-footer-background-color;
                color: $color-white;
            }
        }
    }

    &--diversification {
        thead {
            th {
                color: $link-color;
                border-top: 1px solid $color-gray2;
            }
        }

        tbody {
            tr {
                td {
                    color: $color-black;
                    font-weight: $font-weight-bold;

                    &:first-child {
                        text-align: left;
                    }
                }
            }
        }
    }

    &__footer {
        background-color: $table-footer-background-color;
        color: #fff;
        padding: 25px 15px;

        .o-btn {
            width: auto;

            &:not(.o-btn--link) {
                padding-left: 15px;
                padding-right: 15px;
            }

            &--link {
                color: $table-bar-link-color;

                &:hover,
                &:active,
                &:focus,
                &:visited,
                &:visited:hover,
                &:focus:hover {
                    color: $table-bar-link-color;
                }
            }
        }
    }

    &__bar {
        position: fixed;
        bottom: 0;
        z-index: 8;

        h4 {
            @include respond-gte(medium) {
                margin: 0 !important;
            }
        }

        &:not(&--modal) {
            margin-left: -31px;
            @include respond(small) {
                width: 100%;
            }
        }

        &--modal {
            box-sizing: border-box;
            position: relative;
            margin-right: 0;
            margin-left: 0;
        }

        &__link-container {
            @include respond(small) {
                text-align: left !important;
            }
        }

        &__link {
            margin-right: 30px;
            color: $table-bar-link-color !important;

            &:hover,
            &:active,
            &:focus,
            &:visited,
            &:visited:hover,
            &:focus:hover {
                color: $table-bar-link-color;
            }

            @include respond(small) {
                display: block !important;
                margin-right: 0;
            }
        }
    }

    &--stripped {
        tbody {
            tr {
                border-bottom: none;
                height: 48px;

                td {
                    background-color: transparent !important;

                    .o-checkbox {
                        padding-left: 0.5em;
                        margin-left: 3px;
                        line-height: 19px;
                        vertical-align: middle;

                        input ~ .o-ci {
                            &:before {
                                border: 1px solid $custom-checkbox-icon-unchecked-color;
                            }
                        }

                        input {
                            &:checked {
                                & ~ .#{$custom-input-class}:before {
                                    border: 1px solid $custom-checkbox-checked-border-color;
                                    background-color: $custom-checkbox-checked-border-color;
                                }

                                & ~ .#{$custom-input-class}:after {
                                    color: $color-white;
                                }
                            }
                        }

                        &.cm-checkbox--icon {
                            margin-top: -1.5px;
                            margin-bottom: -1.5px;
                        }
                    }

                    &.show .cm-table__container--hover {
                        & > p {
                            max-width: 200px;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            vertical-align: middle;
                        }
                    }

                    &.show .cm-table__content {
                        position: relative;

                        &--removed {
                            color: $color-gray3;
                        }

                        .g-icon--padlock,
                        .g-icon--deleted {
                            margin-top: -1.5px;
                            margin-bottom: -1.5px;
                        }

                        .cm-table__hidden-content {
                            display: none;
                            background-color: #ffffff;
                            box-shadow: -2px 3px 19px -6px rgba(0, 0, 0, 0.26);
                            padding: 6px;
                            border-radius: 40px;
                            position: absolute;
                            left: 4px;
                            top: 9px;
                            box-sizing: border-box;
                            min-width: 100%;
                        }

                        .cm-table__container--right > .cm-table__hidden-content {
                            left: auto;
                            right: 0;
                        }

                        a {
                            text-decoration: none;
                            position: relative;
                            bottom: 0;
                        }

                        &:hover {
                            overflow: visible;

                            p {
                                opacity: 0;
                            }

                            a {
                                display: inline-block;
                                cursor: pointer;
                                text-decoration: underline;
                                white-space: nowrap;
                            }

                            .cm-table__hidden-content {
                                display: inline-block;

                                .cm-table__content--removed {
                                    transform: translateY(2px);
                                }

                                span {
                                    overflow: visible;
                                    max-width: inherit;
                                }
                            }

                            & > div {
                                white-space: nowrap;
                                text-overflow: ellipsis;
                            }
                        }
                    }

                    .cm-table__content {
                        max-width: inherit;
                        border-top-color: transparent !important;

                        .cm-table__hidden-content {
                            display: inline-block;

                            .cm-tooltip {
                                display: none;
                            }
                        }

                        &:hover {
                            .cm-table__hidden-content .cm-tooltip {
                                display: inline-block;
                            }
                        }
                    }
                }
            }

            tr:nth-child(even) {
                background-color: $table-tr-stripped-color-even;
            }
        }

        .cm-scrollable__part .cm-scrollable__col tbody tr td {
            pointer-events: none;

            div.cm-table__content {
                pointer-events: auto;
                white-space: nowrap;

                &:first-of-type,
                &:first-of-type p {
                    min-width: auto;
                    width: 30px;
                }

                &:nth-of-type(2) {
                    padding: 5px;
                    height: 30px;

                    & > .cm-table__container--hover {
                        padding: 5px 10px;

                        & > p > span {
                            transform: none;
                        }

                        & > span {
                            transform: translateY(-6px);
                            display: inline-block;
                        }
                    }

                    &:hover {
                        & > .cm-table__container--hover {
                            border-radius: 40px;
                            background-color: white;
                            box-shadow: -2px 3px 19px -6px rgba(0, 0, 0, 0.26);

                            .cm-scrollable__part .cm-scrollable__col tbody tr td {
                                width: auto;
                            }

                            .cm-tooltip .cm-tooltip__button {
                                transform: translateY(-2px);
                            }

                            & > p > span {
                                max-width: inherit;
                                overflow: visible;
                            }

                            & > span {
                                transform: translateY(-2px);
                            }
                        }
                    }
                }
            }

            &:hover {
                position: absolute;
            }
        }

        .cm-table--mobile tbody {
            &:nth-of-type(odd) {
                background-color: $table-tr-stripped-color-odd;
            }

            &:nth-of-type(even) {
                background-color: $table-tr-stripped-color-even;
            }
        }
    }

    &--documents {
        font-weight: $font-weight-normal;

        thead {
            tr {
                th {
                    vertical-align: top;
                    word-wrap: break-word;
                }
            }
        }

        tbody {
            td {
                //padding: 0 10px;
                @include respond-gt(medium) {
                    background-color: $scrollable-table-td-background-color;
                }
            }
        }

        .#{$ns}table-groups__standard-list,
        .#{$ns}table-groups__thead,
        .#{$ns}table-groups__summary {
            font-weight: $font-weight-normal;
        }

        .cm-expand-trigger {
            .date {
                max-width: 39px;
                word-wrap: break-word;
                display: block;
            }
        }
    }

    &--top-companies {
        .cm-table {
            width: auto;
            min-width: 100%;
        }

        .cm-scrollable__content {
            max-height: 70vh;
        }

        tr {
            border-bottom-width: 0;
        }

        th .cm-table__content {
            padding: 15px 8px;
        }

        td {
            vertical-align: top;
            border-bottom: 1px solid $table-between-row-border-color;
            background-color: $scrollable-table-td-background-color;

            &:nth-child(1),
            &:nth-child(2) {
                background-color: $color-white;
                border-bottom-width: 0;
                font-weight: $font-weight-bold;
            }

            .cm-table__content {
                padding: 10px 8px;
            }
        }

        th,
        td {
            &:nth-child(1) {
                .cm-table__content {
                    width: 30px !important;
                }
            }

            &:nth-child(2) {
                .cm-table__content {
                    width: 130px !important;
                }
            }

            &:nth-child(9) {
                white-space: nowrap;
            }
        }

        .cm-scrollable__tfoot {
            .cm-partial__row:last-of-type {
                td {
                    border-bottom-width: 0;
                }
            }
        }

        &-mobile {
            .#{$ns}table-groups__summary,
            .#{$ns}table__summary {
                td:first-child,
                th:first-child {
                    .cm-table__content {
                        padding-left: 0;
                    }
                }
            }
        }
    }

    &--align-l &:not(.bulk-actions) {
        text-align: left;

        thead {
            th {
                text-align: left;
            }

            th:last-of-type {
                text-align: right;

                .cm-table__content {
                    &.u-flex {
                        justify-content: flex-end;
                        -webkit-justify-content: flex-end;
                    }
                }
            }
        }

        tbody {
            tr {
                td:last-of-type {
                    text-align: right;

                    .cm-table__content {
                        &.u-flex {
                            justify-content: flex-end;
                            -webkit-justify-content: flex-end;
                        }
                    }
                }
            }
        }
    }

    &__legend {
        &-color {
            width: 30px;
            height: 15px;
            float: left;
            margin-right: 10px;
            @include respond-lte(medium) {
                min-width: 15px !important;
                width: 15px;
            }
        }

        &-label {
            overflow: hidden;
        }

        &-underline {
            display: block;
            position: absolute;
            width: 50px;
            height: 3px;
            bottom: -2px;
            right: 0;
        }
    }

    &--spacing-right {
        th,
        td {
            &:last-child {
                .cm-table__legend-underline {
                    right: 30px;
                    @include respond(small) {
                        right: 5px;
                    }
                }
            }
        }
    }

    &--first-static {
        .cm-scrollable {
            .cm-scrollable__part--thead {
                border-bottom: 1px solid $color-gray7;
                margin-bottom: 0;

                .cm-scrollable__col--thead {
                    box-shadow: 2px -4px 4px 0 rgba(0, 0, 0, 0.1);

                    .cm-scrollable__top-left {
                        .cm-table__content {
                            font-weight: $font-weight-bold;
                        }
                    }
                }
            }

            .cm-scrollable__wrapper {
                .cm-scrollable__thead {
                    table thead tr {
                        border: none;

                        th {
                            border: none;
                        }
                    }
                }
            }

            .cm-scrollable__part--main {
                div .cm-scrollable__col {
                    box-shadow: 2px -4px 4px 0 rgba(0, 0, 0, 0.1);
                    z-index: 1;

                    table tbody tr {
                        background-color: $color-white;
                    }
                }
            }
        }
    }

    &__options-popup {
        position: absolute;
        right: 5px;
        z-index: 999;
        min-width: 100px;
        background-color: $color-white;
        border: 1px solid rgb(246, 246, 246);
        border-radius: 5px;
        box-sizing: border-box;
        padding: 15px;
        box-shadow: rgba(86, 86, 86, 0.15) 0px 5px 10px;

        ul {
            background-color: $color-white !important;
            padding: 0 !important;
            border-bottom: none !important;
        }

        li:last-child {
            margin-bottom: 0 !important;
        }
    }

    &__tooltip {
        pointer-events: none;
        position: absolute;
        padding: 3px 10px;
        border-radius: 5px;
        text-align: center;
        top: -14px;
        left: 10px;
        width: 120px;
        box-shadow: rgba(86, 86, 86, 0.15) 0px 5px 10px;
        background-color: $color-white;
    }

    &__unpublished-message {
        background-color: $color-gray1;

        &__text {
            padding-top: 2px;
        }
    }

    &__investment-path {
        align-items: baseline;
    }

    &--transparent {
        tr:nth-child(odd),
        th {
            background: none;
        }
    }

    &--archive {
        tbody tr {
            td {
                vertical-align: top;
                font-weight: $font-weight-bold;

                .cm-table__content--collapsed {
                    display: none;
                }
            }
        }

        .cm-table__content:not(.cm-table__content--fixed-width) {
            min-width: 100px;
        }

        .cm-table__content--expanded {
            .cm-table__content--collapsed {
                display: block;
            }
        }

        .cm-table__content--colspan {
            width: 100%;

            tbody tr {
                background-color: inherit;
                height: auto;

                td {
                    white-space: pre-wrap;
                    word-break: break-word;

                    &:nth-child(1) {
                        &,
                        & .cm-table__content {
                            width: 250px;
                        }
                    }
                }

                &:not(:first-of-type) td {
                    font-weight: $font-weight-normal;
                }
            }
        }
    }

    &__form-label,
    &__form-field {
        width: 50%;
    }
}

.#{$ns}generic-table {
    @include respond-lt(large) {
        max-height: 60vh;
        overflow: scroll;
    }
}

.#{$ns}table--stripped,
.#{$ns}super-table--stripped {
    .cm-table--mobile .cm-table__expand-list,
    .cm-table--mobile .cm-table__expand-list-multiple {
        background-color: inherit;

        td {
            background-color: inherit !important;

            ul {
                background-color: inherit;
            }
        }

        &-main {
            .cm-table-groups__standard-list {
                .cm-table__content {
                    span {
                        text-overflow: initial;
                        white-space: normal;
                    }
                }
            }
        }
    }
}


.cash-flows-table {
    .ag-root-wrapper-body {
        border: none !important;
    }

    .ag-cell-value {
        font-weight: $table-td-font-weight;
        color: $table-td-color;
    }

    .ag-row,
    .ag-header {
        border-bottom: 1px solid $table-between-row-border-color !important;
    }
}