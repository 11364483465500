.#{$ns}expand-trigger {
    text-decoration: none;
    border: none;
    background: transparent;
    .g-icon.g-icon--arrow-down {
        color: $arrow-icon-color;
    }
    .is-trigger-after & {
        padding-right: 0;
        padding-left: 10px;
        float: right;
    }
}
