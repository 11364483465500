.#{$ns}logo {
    &,
    img {
        display: block;
    }
    &__cartridge {
        cursor: pointer;
    }
    img {
        max-width: 168px;
        max-height: 50px;
        width: auto;
    }
}
