.#{$ns}tabs {
    margin: 20px 0 30px 0; //for dev, to remove
    border-bottom: $tab-border;

    &__tab {
        display: inline-block;
        position: relative;
        top: $tab-border-width;
        z-index: 5;

        min-width: $tab-width;
        min-height: $tab-height;
        line-height: $tab-height;
        padding: 0 10px;

        text-align: center;
        font-size: $tab-font-size;
        cursor: pointer;

        &:not(:first-child) {
            margin-left: -$tab-border-width;
        }

        &--active {
            border: $tab-border;
            border-bottom: $tab-border-width solid $color-white;
            font-weight: $active-tab-font-weight;
        }

        &--disabled {
            color: $color-gray3;
            cursor: default;
        }
    }
}
