.#{$ns}tree {
    $element-width: 10vw;
    $element-width-small: 6vw;

    &.scrollable {
        min-width: 888px;

        .#{$ns}simplebar & {
            overflow-x: visible;
        }

        .#{$ns}tree {

            &__header-container {
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
            }

            &__header {
                width: 10vw;
                box-sizing: border-box;
                padding-left: 30px;
                display: flex;

                .o-checkbox {
                    margin-bottom: -3px;
                    padding-left: 1em;
                }

                &--switches {
                    width: auto;
                    min-width: 130px;
                    text-align: center;
                }
            }

            &--child {
                ul:not(.vs__dropdown-menu) {
                    @include respond(small) {
                        padding-left: calc(#{$element-width-small});
                    }
                }
            }
        }
    }

    .#{$ns}modal &,
    .#{$ns}admin-panel__form & {
        &--child {
            & > ul {
                padding-left: calc(#{$element-width} / 2);
                @include respond(small) {
                    padding-left: calc(#{$element-width-small} / 2);
                }
            }
        }
    }

    .#{$ns}modal & {
        &__name__label {
            outline-offset: -2px;
        }

        &__nav {
            &__radio {
                margin-right: 10px !important;
                label {
                    padding-left: 18px;
                }
            }
        }
    }

    .#{$ns}admin-panel__form & {
        &__nav {
            &__radio {
                margin-right: 20px;
            }
        }
    }

    &__nav {
        display: flex;
        flex-grow: 1;
        border-bottom: 1px solid $color-gray3;
        justify-content: space-between;
        align-items: flex-end;
        padding-bottom: 5px;

        &__all-radio {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;

            .o-ci-label {
                color: $link-color;
            }

            label:hover .o-ci-label {
                color: $link-hover-color;
            }
        }

        &__radio-header {
            font-weight: $font-weight-bold;
            margin-bottom: 5px;
        }

        &__radio-group {
            display: flex;
            justify-content: flex-end;
        }

        &__btn {
            margin-right: 20px;
        }
    }

    &--child {
        ul {
            padding-left: $element-width;
            @include respond(small) {
                padding-left: calc(#{$element-width-small} / 2);
                ul .#{$ns}tree__name:not(.has-children) {
                    padding-left: 30px;
                }
            }
        }
    }

    a {
        text-decoration: none;
    }

    .o-checkbox.is-disabled {
        cursor: default;
        @include custom-input-background() {
            background: none;
            border: none;
        }
    }

    &__headers {
        border-bottom: 1px solid $color-gray2;

        &__item {
            display: inline-block;
            box-sizing: border-box;
            min-width: $element-width;
            padding: 10px;
            font-weight: $font-weight-bold;
            color: $color-gray4;
        }
    }

    &__button {
        display: flex;
        align-items: center;

        form {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;

            .#{$ns}tree__input-container {
                display: flex;
                justify-content: flex-start;
                align-items: center;

                input {
                    float: left;
                }
            }

            .cm-input {
                float: left;
                width: auto;
                margin-right: 5px;
            }
        }

        &--ok,
        &--cancel {
            padding: 10px 5px 10px 5px !important;
            cursor: pointer;
            text-decoration: underline;
            float: right;

            &::before {
                content: none !important;
            }

            &:focus {
                outline: 2px $color-blue4 solid !important;
                outline-offset: -2px !important;
            }
        }
    }

    &__item {
        &__content {
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid $color-gray2;

            &.no-toggle-arrow {
                padding-left: 30px;
            }
        }

        form {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;

            .#{$ns}tree__input-container {
                display: flex;
                justify-content: flex-start;
                align-items: center;

                input {
                    float: left;
                }
            }
        }
    }

    &__label {
        min-width: calc(#{$element-width} - 40px);
        padding: 10px;
        display: inline-block;

        &__list {
            white-space: initial;
        }
    }

    &__name {
        display: flex;
        font-weight: $font-weight-bold;

        label {
            padding-top: 10px;
            padding-bottom: 10px;
        }

        &__label {
            float: left;
            display: inline-block;
            text-decoration: none;
            padding: 10px;

            &::before {
                @include font-styles;
                font-size: size($icon-xsmall-size);
                content: icon-char(arrow-down);
            }

            &:hover,
            &:focus {
                text-decoration: none;
            }
        }
        &__notification {
            display: flex;
            align-items: center;
        }

        &__upload {
            min-width: 130px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &__no-checkbox {
            display: inline-block;
            font-weight: $font-weight-normal;
            padding: 10px 0;
        }

        &.is-open &__label {
            &::before {
                content: icon-char(chevron_up);
            }
        }
    }

    &__name,
    &__button {
        form {
            .cm-input {
                width: $element-width;
                @include respond(small) {
                    width: 120px;
                }
                min-width: 90px;
            }

            .cm-select {
                .vs__dropdown-toggle {
                    width: $element-width;
                    @include respond(small) {
                        width: 120px;
                    }
                    min-width: 90px;
                }
            }

            .cm-select {
                width: $element-width;
                @include respond(small) {
                    width: 120px;
                }
                min-width: 90px;
            }
        }
    }

    &__action {
        display: inline-block;
        padding: 0;

        &__button {
            display: inline-block;
            float: right;
            text-decoration: underline;
            cursor: pointer;
            padding: 10px;

            &:focus {
                outline: 2px $color-blue4 solid !important;
                outline-offset: -2px !important;
            }
        }
    }

    &__addColumn {
        display: inline-block;
        padding: 0;
        text-align: right;

        &__checkbox {
            margin-right: 15px;
            display: inline-block;
            padding-bottom: 10px;
            padding-top: 10px;
        }
    }

    &--selectable {
        .#{$ns}tree__name:not(.has-children) {
            padding-left: 30px;
        }
    }
}
