.cm-double-datepicker {
    position: relative;
    border: 1px solid $color-gray3;
    background-color: $color-white;
    border-radius: 15px;
    height: 30px;

    &.disabled {
        background: $form-input-disabled-background;
        color: $form-input-disabled-color;
    }

    @include respond(small) {
        height: 40px;
        border-radius: 20px;
    }

    .dp__input {
        border: none;
    }

    .el-input__icon {
        color: $datepicker-input-icon-color !important;
        height: 28px;
        @include respond(small) {
            height: 40px;
        }
    }

    &.l-row {
        display: flex;
        margin: 0;
        align-items: flex-start;

        &::after {
            display: none;
        }
    }

    .dateto__container,
    .datefrom__container {
        flex-shrink: 1;
        float: none;
        position: static;
        padding: 0;
        width: auto;

        .el-date-editor .el-picker-panel {
            top: 30px !important;
            left: 0 !important;
        }
    }

    .dateto__container {
        display: flex;
        align-items: center;

        &:before {
            content: '-';
            font-weight: bold;
            color: black;
            display: block;
        }
    }

    .el-date-editor.el-input {
        min-width: 1px;
        width: 75px !important;
        @include respond(small) {
            width: 120px !important;
        }
        position: static;

        .el-input__inner {
            height: 28px;
            padding-right: 5px;
            padding-left: 5px;
            border: none;
            margin: 0;

            &::placeholder {
                color: $color-gray5 !important;
            }
        }
    }

    .datefrom__container > .el-date-editor > .el-input__inner {
        padding-left: 10px;
    }

    .datefrom__container {
        .el-picker-panel {
            transform: translateX(0);
        }
    }

    .dateto__container {
        .el-picker-panel {
            transform: translateX(100%);
            @include respond(small) {
                transform: translate(0, 95%);
            }
        }
    }

    .el-picker-panel {
        .available.current.starting:after {
            background-color: $double-datepicker-current-background !important;
        }

        height: 370px !important;
        border: none !important;
        box-shadow: 0 2px 2px $color-gray5 !important;

        .el-picker-panel__body-wrapper {
            &::after,
            &::before {
                display: none;
            }
        }
    }

    input {
        font-size: 0.85em;
    }

    .cm-subfilters--side & {
        .el-picker-panel {
            max-height: 350px !important;
            position: absolute !important;

            &__content tr th {
                color: black !important;
            }
        }

        .dateto__container {
            .el-picker-panel {
                transform: translate(0, 95%) !important;
            }
        }

        .el-date-picker__header {
            color: $datepicker-side-header-color !important;

            button,
            button:hover {
                color: $datepicker-side-header-button-hover !important;
            }

            .el-date-picker__header-label:hover {
                color: $datepicker-side-header-hover !important;
            }
        }
    }
}
