.cm-form-tooltip {
    display: none;
    position: relative;

    &__icon {
        display: inline-block;
        margin-left: 10px;

        .cm-orb__inner .cm-orb__inner--icon::before {
            font-size: 15px !important;
        }
    }

    &__container {
        z-index: 6;
        top: -30px;
        left: 15px;
        position: absolute;

        &.in-table {
            top: 24px;
            left: 10px;
            min-width: 75px;
            max-width: 345px;
            width: 999em;
            text-align: left;

            p {
                white-space: normal;
            }
        }

        &.rotate {
            left: auto;
            right: 0;
            text-align: right;
        }

        &.to-bottom {
            top: auto;
            bottom: 24px;
        }

        &.reset-width {
            width: 500px;
        }

        @include respond(small) {
            p {
                white-space: initial;
            }
            width: 150px;
            top: -45px;
            left: -100px;
        }
    }

    &__content {
        display: inline-block;
        background-color: $form-tooltip-content-background-color;
        color: $color-white;
        border-radius: 5px;
        border: 1px solid $color-gray1;
        z-index: 6;
        height: auto;
        padding: 5px 10px;
        font-weight: 400;
        text-align: left;
        // box-shadow: 0 0 33px -17px rgba(0, 0, 0, 0.34);
        p {
            white-space: nowrap;
        }

        &.in-table {
            p {
                white-space: normal;
            }
        }
    }

    &.in-table {
        position: absolute;

        .cm-orb {
            box-shadow: none;
        }
    }
}
