/*
 * hy.frontend.core
 * 
 * NOTE: this file is not subject to copyright transfer * 
 * UWAGA: plik nie objęty przekazaniem praw autorskich * 
 * 
 */






@mixin clearfix() {
    &:after {
        content: ' ';
        display: block;
        clear: both;
    }
}
