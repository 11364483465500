.#{$ns}virtual-list {

    @include respond-gte(large) {
        max-height: 100% !important;
    }

    .cm-documents--new & {
        max-height: 260px;
    }

    @include respond-lte(medium) {
        max-height: 70vh;
        overflow-x: hidden;
        overflow-y: auto;
    }

    &__wrapper {
        &--mobile {
            &--selected {
                margin-bottom: 200px;
                @include respond(medium) {
                    margin-bottom: 85px;
                }
            }
        }
    }

    &--company-search {
        @include respond-gte(large) {
            height: 92vh;
        }

        .#{$ns}partial__row {
            td,
            th {
                width: 9%;
                background-color: $scrollable-table-td-background-color;

                &:nth-child(1) {
                    width: 11%;
                }

                &:nth-child(2),
                &:nth-child(3) {
                    width: 8%;
                }

                &:nth-child(8) {
                    width: 25%;
                }

                &:nth-child(9),
                &:nth-child(10) {
                    width: 6%;
                }
            }
        }

        .#{$ns}table--mobile {
            .#{$ns}partial__row {
                td,
                th {
                    width: 33%;

                    &:nth-child(1) {
                        width: 25%;
                    }

                    &:nth-child(3) {
                        width: 42%;
                    }
                }
            }
        }
    }
}
