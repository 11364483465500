.cm-calculated-formula-form {
    &__selectors {
        display: flex;
        justify-content: space-between;

        @include respond(small) {
            border: 1px solid $o-separator-color;
            border-radius: 15px;
            padding: 15px;
        }

        & > button {
            align-self: flex-end;
            background-color: $icons-with-background-background-color;
            color: $icons-with-background-color;
        }

        .dropdown-menu {
            max-height: 200px !important;
        }

    }

    &__add-button {
        display: flex;
        align-items: center;

        .cm-orb {
            background-color: $icons-with-background-background-color;
            color: $icons-with-background-color;
        }
    }

    &__button {
        box-sizing: border-box;
        height: 20px;
        width: fit-content;
        padding: 2px 10px;
        border: 1px solid $selected-filter-background;
        border-radius: 15px;
        background-color: $selected-filter-background;
        color: $color-white;
        position: relative;
    }

    &__number-input {
        width: 100px !important;
        flex-grow: 1;
    }

    &__input {
        position: relative;
        box-sizing: border-box;
        width: $modal-width-l - 70px; // - width of paddings and scroll
        min-height: 100px;
        border: 1px solid $form-input-border-color;
        border-radius: 20px;
        padding: 5px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        .formulaSubElement {
            box-sizing: border-box;
            display: flex;
            min-width: 20px;
            min-height: 20px;
            cursor: pointer;
            flex-wrap: wrap;
            justify-content: flex-start;
            align-items: center;
            height: fit-content;
            padding: 5px 0;

            .g-icon {
                padding: 0 3px;
            }

            &:active,
            &:hover {
                border-bottom: 1px solid $button-hover-background;
            }
        }

        .operationContainer {
            box-sizing: border-box;
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            align-items: center;
            height: fit-content;
            padding: 5px 0;

            .g-icon {
                padding: 0 3px;
            }
        }

        .selectedElement {
            box-sizing: border-box;
            border: 1px solid $icons-with-background-background-color;
            border-radius: 5px;
        }

    }
}

