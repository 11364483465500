/*
 * hy.frontend.core
 * 
 * NOTE: this file is not subject to copyright transfer * 
 * UWAGA: plik nie objęty przekazaniem praw autorskich * 
 * 
 */





/*------------------------------------*\
    #IMAGES
\*------------------------------------*/

////
/// @group images
/// @author marcin.krasowski
////

img {
    max-width: 100%;
}
