.#{$ns}mobile-exp-table {
    text-align: right;
    font-weight: $font-weight-bold;
    .item-to-expand {
        display: none;
    }
    .has_expander.is-expanded + .item-to-expand {
        display: table-row;
    }
    &__table {
        width: 100%;
        background-color: $mobile-exp-table-background;
    }
    &__th {
        background-color: $mobile-exp-table-th-background;
        font-weight: $font-weight-bold;
        box-sizing: border-box;
        align-items: center;
        color: $mobile-exp-table-th-color;
    }
    &__tr {
        border-bottom: 1px solid $mobile-exp-table-tr-border-color;
    }
    .cm-list-expanded__list {
        background-color: transparent;
    }
    .o-btn--link {
        margin-right: 10px;
    }
}
