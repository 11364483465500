.cm-statement {
    display: table;
    height: 150px;
    width: 70vw;
    background-color: white;
    box-shadow: 0px 0px 33px -17px rgba(0, 0, 0, 0.34);
    position: fixed;
    bottom: 0px;
    right: 0;
    z-index: 100;
    padding: 20px 10px 10px 20px;
    &__header {
        display: inline-block;
        font-size: 1.6em;
        vertical-align: middle;
        font-weight: $font-weight-bold;
        padding-left: 15px;
    }
    &__subheader {
        display: inline-block;
        font-size: 1.2em;
        vertical-align: middle;
        font-weight: bold;
        padding-left: 45px;
    }
    &__content {
        height: 90px;
        &--container {
            max-height: 90px;
            padding-left: 45px;
            margin-top: 15px;
        }
        &--element {
            display: inline-block;
            padding-right: 10px;
            max-height: 50px;
            &:nth-of-type(even) {
                background-color: $table-tr-stripped-color-even;
            }
        }
    }
    &__icon {
        display: inline-block;
        vertical-align: middle;
        text-align: right;
        width: 30px;
        padding-left: 0;
        float: left;
        &-close {
            font-size: 30px;
            float: right;
            &:hover {
                cursor: pointer;
            }
        }
    }
    &__loader {
        &--container {
            display: block;
            text-align: center;
            height: 100%;
        }
        &--spinner {
            display: block;
            width: 50px;
            padding-bottom: 20px;
            margin: 0 auto;
        }
        &--text {
            font-size: 1.2em;
            vertical-align: middle;
            font-weight: bold;
        }
    }
}
