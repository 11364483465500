.o-msg.o-msg--context {
    .o-msg__icon {
        vertical-align: middle;
        .g-icon--alert {
            display: block;
            &::before {
                font-size: 24px;
            }
        }
    }
}
.o-msg--small.o-msg.o-msg--context {
    .o-msg__icon .g-icon--alert {
        line-height: 1.1;
        &::before {
            font-size: 14px;
        }
    }
}

.o-msg--alert {
    .o-off-canvas__content & {
        margin-top: 10px;
        .g-icon--alert::before {
            background: $color-red2;
            border-radius: 50%;
        }
        &.o-msg.o-msg--context.o-msg--icon .o-msg__text {
            color: $color-red2;
        }
    }
}
