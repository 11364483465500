.cm-table-bubble {
    max-width: 234px;
    white-space: nowrap;
    font-weight: $font-weight-bold;
    overflow: hidden;
    text-overflow: ellipsis;

    tr.hover &,
    &.is-hover {
        cursor: pointer;
        position: fixed;
        z-index: 100;
        background-color: $color-white;
        padding: 5px 10px;
        border-radius: 15px;
        box-shadow: 0 4px 8px rgba(86, 86, 86, 0.5);
        transform: translate(-10px, 8px);
        max-width: 1000px;
        overflow: visible;
    }
    &--abs {
        &.is-hover {
            position: absolute;
            top: 0;
        }
    }
    .o-btn--link {
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
