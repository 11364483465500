$table-multiple-fixed-column-width: 250px;
$table-multiple-fixed-column-mobile-width: 150px;

.#{$ns}table-fixed-multiple-expand {
    width: 100%;
    text-align: left;
    font-weight: $font-weight-bold;
    background-color: $table-background-color;

    &__wrapper {
        margin-left: $table-multiple-fixed-column-width;
        position: relative;

        @include respond(small) {
            margin-left: $table-multiple-fixed-column-mobile-width;
        }

        .simplebar-mask {
            left: -$table-multiple-fixed-column-width;

            @include respond(small) {
                left: -$table-multiple-fixed-column-mobile-width;
            }
        }
        .simplebar-offset {
            left: $table-multiple-fixed-column-width;

            @include respond(small) {
                left: $table-multiple-fixed-column-mobile-width;
            }
        }
        .simplebar-content-wrapper {
            position: static;
            overflow: auto hidden !important;
        }
        .simplebar-horizontal {
            bottom: -10px;
        }
        .simplebar-vertical {
            display: none;
        }
    }

    &__cell {
        box-sizing: border-box;
        height: 50px;
        padding: 15px 10px;
        @include respond(small) {
            padding: 15px 5px;

        }
        &--first {
            width: $table-multiple-fixed-column-width;
            display: flex;
            align-items: center;
            padding-top: 0;
            padding-bottom: 0;
            position: absolute;
            left: -$table-multiple-fixed-column-width;
            border-bottom: 1px solid $table-between-row-border-color;
            text-align: left;
            display: flex;

            @include respond(small) {
                width: $table-multiple-fixed-column-mobile-width;
                left: -$table-multiple-fixed-column-mobile-width;
            }

            &-l2 {
                padding-left: 40px;
            }

            &-l3 {
                padding-left: 70px;
            }

        }
    }

    &__arrow {
        display: inline-block;
        width: 30px;

        .o-btn--link {
            padding-left: 5px;
            padding-right: 5px;
        }
    }

    &__column {
        background-color:  $table-background-color;

        &--dark {
            background-color: $table-between-row-border-color;
        }
    }

    tr {
        border-bottom: 1px solid $table-between-row-border-color;
    }

    th {
        color: $table-th-color;
        font-weight: $table-th-font-weight;
    }

    td {
        color: $table-td-color;
        font-weight: $table-td-font-weight;
    }
}
