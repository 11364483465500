.#{$ns}masterFilters {
    padding: 20px;
    .#{$ns}filters {
        padding-bottom: 0;
        &__list {
            padding-left: 0;
        }
        &__field {
            padding-left: 0;
        }
        &__group {
            padding: 0;
            margin: 0;
        }
        &__list__content {
            padding-right: 10px;
        }
        &__select-actions {
            padding-left: 0;
        }
    }
    .simplebar-track {
        &.simplebar-vertical {
            right: 0;
            left: auto;
        }
    }
}

.#{$ns}selected-master-filter {
    padding: 20px 20px 0;
}
