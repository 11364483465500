.#{$ns}grouped-table {
    max-height: 220px;
    overflow: hidden auto;
    position: static !important;
    table {
        margin-top: -47px;
    }
    &.vb > .vb-dragger {
        left: 0;
    }
    .#{$ns}expand-trigger {
        padding: 0 20px 0 10px;
    }
    .#{$ns}list-expanded__list {
        padding-right: 20px;
    }
}
