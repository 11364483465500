/*
 * hy.frontend.core
 * 
 * NOTE: this file is not subject to copyright transfer
 * 
 * UWAGA: plik nie objęty przekazaniem praw autorskich
 * 
 * 
 */





/*------------------------------------*\
    #PAGE [P]
\*------------------------------------*/

////
/// PAGE [P]
/// @group layout
/// @author marcin.krasowski
////

$page-width: 1200px !default;
$page-padding: $grid-gutter !default;
$page-padding-medium: $page-padding !default;
$page-padding-small: $page-padding-medium !default;
$page-xlarge-width-em: 50em !default;

$page-full-row-class: 'full-row' !default;
$page-row-class: 'page-row' !default;





body {
    overflow-x: hidden;
}

/// container with 100% width (e.g. for different sections)
.l-#{$page-full-row-class} {
    @include container(100%);
}

/// centered container with specific maximum width
@mixin page-row {
    @include container($page-width);

    padding-left: $page-padding;
    padding-right: $page-padding;

    @include respond-lte(medium) {
        padding-left: $page-padding-medium;
        padding-right: $page-padding-medium;
    }

    @include respond-lte(small) {
        padding-left: $page-padding-small;
        padding-right: $page-padding-small;
    }
}

.l-#{$page-row-class} {
    @include page-row;
    &.l-#{$page-row-class}--min {
        @include respond(xlarge) {
            min-width: $page-xlarge-width-em;
        }
    }
}
