@import '../../settings';

.#{$ns}checkbox {
    padding-left: 25px;

    input ~ .#{$custom-input-label-class} {
        font-weight: $font-weight-normal;
    }
    &--round {
        input {
            & ~ .#{$custom-input-class}:before {
                border: 1px solid $color-blue5;
                border-radius: 13px;
                background-color: $color-blue5;
            }
        }
    }
    &--navy-blue {
        input {
            &:checked {
                & ~ .#{$custom-input-class}:before {
                    border: 1px solid $custom-checkbox-checked-border-color;
                    background-color: $custom-checkbox-checked-border-color;
                }
                & ~ .#{$custom-input-class}:after {
                    color: $color-white;
                }
            }
            &:disabled {
                & ~ .#{$custom-input-class}:before {
                    background-color: $color-gray2;
                    border-color: $color-gray2;
                }
                &:checked {
                    & ~ .#{$custom-input-class}:before {
                        background-color: $color-gray2;
                        border-color: $color-gray2;
                    }
                    & ~ .#{$custom-input-class}:after {
                        color: $color-gray6;
                    }
                }
            }
        }
        &.is-any-child-checked {
            @include custom-input-background() {
                border: 3px solid $custom-checkbox-any-child-color;
            }
            input:checked {
                & ~ .#{$custom-input-class}:before {
                    background-color: $custom-checkbox-any-child-color;
                    border: 1px solid $custom-checkbox-any-child-color;
                }
            }
            input:disabled {
                & ~ .#{$custom-input-class}:before {
                    background-color: $color-gray7;
                    border-color: $color-gray7;
                }
                &:checked {
                    & ~ .#{$custom-input-class}:before {
                        background-color: $color-gray7;
                        border-color: $color-gray7;
                    }
                    & ~ .#{$custom-input-class}:after {
                        color: $color-gray5;
                    }
                }
            }
        }
    }
    .o-off-canvas__content & {
        input {
            & ~ .#{$custom-input-class}:before {
                border-color: $custom-checkbox-canvas-unchecked-border-color;
                background-color: $custom-checkbox-canvas-unchecked-background-color;
            }
            &:checked {
                & ~ .#{$custom-input-class}:before {
                    border-color: $custom-checkbox-canvas-checked-border-color;
                    background-color: $custom-checkbox-canvas-checked-background-color;
                }
                & ~ .#{$custom-input-class}:after {
                    color: $custom-checkbox-canvas-tick-color;
                }
            }
        }
    }
    &--light {
        input {
            & ~ .#{$custom-input-class}:before {
                border-color: $custom-checkbox-light-unchecked-border-color;
                background-color: $custom-checkbox-light-unchecked-background-color;
            }
            &:checked {
                & ~ .#{$custom-input-class}:before {
                    border-color: $custom-checkbox-light-checked-border-color;
                    background-color: $custom-checkbox-light-checked-background-color;
                }
                & ~ .#{$custom-input-class}:after {
                    color: $custom-checkbox-light-tick-color;
                }
            }
        }
    }

    &--light,
    .o-off-canvas__content & {
        @include custom-input-disabled-checked-background() {
            background-color: $custom-input-disabled-checked-background;
            border-color: $custom-input-disabled-checked-background;
        }
        @include custom-input-disabled-checked-icon() {
            color: $custom-checkbox-disabled-color;
        }
        @include custom-input-disabled-background {
            border-color: $custom-input-disabled-checked-background;
        }
    }

    &--icon {
        padding-right: 0.5em;
        padding-left: 0;
        input {
            & ~ .is-empty {
                color: $custom-checkbox-icon-unchecked-color;
            }
            & ~ .is-full {
                display: none;
                color: $custom-checkbox-icon-checked-color;
                margin-right: 0;
            }
            &:checked {
                & ~ .is-full {
                    display: inline-block;
                }
                & ~ .is-empty {
                    display: none;
                }
            }
            &:disabled {
                & ~ .is-full {
                    color: $color-gray3;
                }
            }
        }
    }
    &--fake {
        @include custom-input-init;
        padding-left: 0;
        min-height: size($custom-checkbox-height);
        min-width: size($custom-checkbox-width);
        .#{$custom-input-class} {
            position: absolute;
            cursor: pointer;
            display: inline-block;
            vertical-align: middle;
            left: 0;
            height: size($custom-checkbox-height);
            width: size($custom-checkbox-width);

            &:before, &:after {
                content: '';
                display: inline-block;
                position: relative;
                box-sizing: border-box;

                @include transition-standard-fast(border, background);
            }
            &::before {
                border: size($custom-checkbox-border-width) $custom-checkbox-border-style $custom-checkbox-border-color;
                border-radius: size($custom-checkbox-border-radius);
                background: $custom-checkbox-background;
                height: size($custom-checkbox-height);
                width: size($custom-checkbox-width);
            }

            &:after {
                @include transition-standard-fast(color);
                opacity: 0;
                font-size: size($custom-checkbox-tick-size);
                font-weight: $font-weight-normal;
                color: $custom-checkbox-tick-color;
                content: icon-char($custom-checkbox-tick);
                line-height: 100%;
                position: absolute;

                left: size($custom-checkbox-tick-offset-left);
                top: size($custom-checkbox-tick-offset-top);

                @if $custom-checkbox-tick-font-family != '' {
                    font-family: $custom-checkbox-tick-font-family;
                }
            }
        }

        &.is-checked {
            .#{$custom-input-class}:after {
                opacity: 1;
            }
        }
    }
}
