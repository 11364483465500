.cm-power-bi {
    &__wrapper {
        height: 66vw;
        max-width: 100vw;
    }
    &__container {
        width: 100%;
        height: 100%;
        iframe {
            border: none;
        }
    }
}
