.#{$ns}navigation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    margin: 0 -30px 20px -70px;
    padding: 0 30px 0 70px;
    width: 100%;
    background-color: $navigation__lvl0-background;
    min-height: 60px;
    height: 50px;
    @include respond(small) {
        margin: 0 -20px 20px -70px;
        padding: 0 20px 0 70px;
    }
    @include respond-lte(medium) {
        overflow: hidden;
    }
    &__shadow {
        position: absolute;
        right: 0;
        width: 100%;
        height: 100%;
        z-index: 101;
        visibility: hidden;
        transform: translateX(100%);
        transition: transform 0.4s ease, visibility 0.4s ease;
        @include respond(medium) {
            box-shadow: 0 9px 6px -7px $navigation__menu-top-shadow;
        }
        @include respond(small) {
            box-shadow: 0 4px 8px $navigation__menu-top-shadow;
            width: 100%;
        }
        &.is-open {
            visibility: visible;
            transform: translateX(0);
        }
        @include respond-gt(medium) {
            display: none;
        }
    }
    &__nav {
        display: flex;
        align-items: center;
    }
    .o-mask {
        position: fixed;
        top: 60px;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        z-index: 99;
        background-color: rgba(0, 0, 0, 0.5);
        .cm-information-bar + .l-page-row & {
            top: 93px;
            @include respond(small) {
                top: 161px;
            }
        }
    }

    .o-navigation {
        font-size: size(14, $rel-unit);
        &__expand-arrow {
            padding-right: 0;
            padding-left: 10px;
        }
        &__link + .o-navigation__cell {
            @include respond-lte(medium) {
                display: none;
            }
        }
        .o-navigation__item,
        .o-navigation__lvl1__item {
            .o-navigation__lvl0__link--arrow,
            a > span.o-navigation__lvl1__item__link--arrow {
                &::after {
                    display: inline-block;
                    position: absolute;
                    content: '';
                    right: 0;
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 8px 5px 0 5px;
                    border-color: $navigation__mobile-arrow-color transparent transparent transparent;
                }
            }
        }

        .o-navigation__lvl1__item {
            a > span.o-navigation__lvl1__item__link--arrow {
                &::after {
                    @include respond-lte(medium) {
                        margin-right: 45px;
                        transform: rotate(180deg);
                        transition: transform 0.3s ease;
                    }

                    @include respond-gt(medium) {
                        top: 40%;
                        margin-right: 10px;
                        border-color: $navigation__lvl1-arrow-color transparent transparent transparent;
                        transform: rotate(270deg);
                    }
                }
            }

            &:not(.is-expanded) {
                a > span.o-navigation__lvl1__item__link--arrow {
                    &::after {
                        @include respond-lte(medium) {
                            transform: rotate(0deg);
                            transition: transform 0.3s ease;
                        }
                    }
                }
            }
        }

        .o-navigation__item {
            .o-navigation__lvl0__link--arrow {
                &::after {
                    @include respond-lte(medium) {
                        margin-right: 20px;
                        transform: rotate(180deg);
                        transition: transform 0.3s ease;
                    }
                }
            }

            &:not(.is-expanded) {
                .o-navigation__lvl0__link--arrow {
                    &::after {
                        @include respond-lte(medium) {
                            transform: rotate(0deg);
                            transition: transform 0.3s ease;
                        }
                    }
                }
            }
        }

        @include respond-gt(medium) {
            .o-navigation__child:hover,
            .o-navigation__cell:hover + .o-navigation__child,
            .o-navigation__cell.is-active + .o-navigation__child,
            .show-list {
                display: block;
                visibility: visible;
            }

            .show-child-list {
                display: block;
                visibility: visible;
            }
        }

        @include respond-lt(medium) {
            &.o-off-canvas__content {
                padding: 20px 10px 0;
                background-color: $navigation__lvl0-background;
            }
        }

        @include respond-lte(medium) {
            right: 0;
            top: 60px;
            .cm-information-bar + .l-page-row & {
                top: 93px;
                @include respond(small) {
                    top: 161px;
                }
            }
        }
        @include respond(medium) {
            &.o-off-canvas__content {
                padding: 0;
                width: 415px;
                background-color: $navigation__lvl0-background;
            }
            &__list {
                padding: 20px 0;
                background-color: inherit;
            }
            &__item {
                padding: 0;
            }
        }

        &__lvl0 {
            &__list {
                @include respond-gt(medium) {
                    display: flex;
                }

                .o-navigation__cell {
                    &.is-hidden {
                        visibility: hidden;
                        &.show-trigger {
                            visibility: visible;
                        }
                    }
                }

                @keyframes expand {
                    from {
                        opacity: 0;
                    }
                    to {
                        opacity: 1;
                    }
                }

                .o-navigation__lvl0 {
                    &__item {
                        min-width: 30px;
                        padding-left: 10px;

                        @include respond-lte(medium) {
                            padding-left: 0;
                            background-color: $navigation__lvl0-background;
                            transition: max-height 200ms ease-out, visibility 200ms,
                                background-color 200ms 200ms ease-out;

                            &.is-expanded {
                                background-color: $navigation__mobile__lvl0-background-expanded;
                                animation: expand 200ms ease-out;
                                transition: max-height 200ms ease-out, visibility 200ms;

                                .o-navigation__lvl0 {
                                    &.expander__element {
                                        max-height: 900px !important;
                                    }
                                }
                            }
                        }
                    }

                    &__link {
                        @include respond-lte(medium) {
                            font-size: size(14, $rel-unit);
                            padding: 16px 0 16px 20px;
                            display: flex;
                        }
                    }
                }
            }
            &__item {
                margin-right: 30px;
            }
        }

        &__lvl1 {
            padding-top: 0;
            @include respond-gt(medium) {
                position: absolute;
                left: 100%;
            }

            &--reversed {
                @include respond-gt(medium) {
                    left: initial;
                    right: 100% !important;
                }
            }

            &__item {
                @include respond-lte(medium) {
                    margin: 0 5px;
                    background-color: $navigation__mobile__lvl0-background-expanded;
                    transition: max-height 400ms ease-out, visibility 400ms, background-color 200ms 300ms ease-out;

                    &.is-expanded {
                        background-color: $navigation__lvl2-background;
                        animation: expand 200ms ease-out;
                        transition: max-height 200ms ease-out, visibility 200ms;
                        .o-navigation__lvl2__list {
                            max-height: 800px !important;
                            visibility: visible !important;
                        }
                    }
                }
            }

            &__link {
                padding: 10px;
                transition: none;

                @include respond-lte(medium) {
                    padding-left: 35px;
                    padding-top: 16px;
                    padding-bottom: 16px;
                }

                & > .cm-orb {
                    float: right;
                }
            }
        }

        &__link {
            display: block;
            position: relative;
            white-space: nowrap;
            color: $navigation__lvl0-text-color;

            &:hover,
            &:active,
            &.is-active {
                text-decoration: none;
                cursor: pointer;
            }

            &:focus {
                text-decoration: none;
                color: $link-hover-color;
            }

            &.is-active {
                font-weight: $font-weight-bold;
            }

            @include respond-lte(medium) {
                color: $navigation__mobile__lvl1-text-color;
                &:hover,
                &:active,
                &:focus {
                    &::after {
                        display: none;
                    }
                }
                &.is-active {
                    &::after {
                        background-color: $color-white;
                    }
                }
            }
        }
        &__lvl0__link {
            &,
            &:focus {
                color: $navigation__lvl0-text-color;
            }
            font-weight: 400;
        }

        &__expand-link {
            display: none;

            @include respond-gt(medium) {
                display: block;
                visibility: hidden;
            }
        }

        &__child {
            position: absolute;
            padding-top: 15px;
            z-index: 6;

            .l-page-row {
                padding: 0;
            }

            @include respond-lte(medium) {
                position: static;
                padding-top: 0;
            }

            @include respond-gt(medium) {
                overflow: visible !important;
            }
        }
        &__lvl1__list {
            @include respond-gt(medium) {
                .o-navigation__item {
                    position: relative;
                }
                .o-navigation__child {
                    top: 0;
                }
            }
        }
        .o-navigation__cell:hover + .o-navigation__child,
        .o-navigation .o-navigation__cell.is-active + .o-navigation__child {
            @include respond-gt(medium) {
                animation: anim 200ms linear;
            }
        }

        @keyframes anim {
            0% {
                display: block;
                opacity: 0;
            }
            100% {
                opacity: 1;
            }
        }

        &__lvl0__item:last-child {
            .o-navigation__child {
                right: 10px;
            }

            @include respond-gt(medium) {
                .o-navigation__lvl1__list {
                    &::after {
                        left: 15% !important;
                    }
                }
            }
        }

        &__lvl1__list {
            box-sizing: border-box;
            position: relative;
            min-width: 210px;
            padding: 10px 0;
            box-shadow: 0 4px 4px rgba(86, 86, 86, 0.15) !important;
            background-color: $navigation__lvl1-background;

            @include respond-lte(medium) {
                background-color: transparent;
                box-shadow: none;
                padding: 0;
            }
            &::after {
                display: block;
                content: '';
                position: absolute;
                top: -5px;
                left: 10px;
                width: 0;
                height: 0;
                border-left: 5px solid transparent;
                border-right: 5px solid transparent;
                border-bottom: 5px solid $navigation__lvl1-background;
                @include respond-lte(medium) {
                    display: none;
                }
            }
            .o-navigation__item {
                display: flex;
                flex-direction: column;

                .o-navigation__lvl1__item__link {
                    color: $navigation__mobile__lvl1-text-color;

                    @include respond-gte(large) {
                        color: $navigation__lvl1-text-color;
                    }
                }

                &:hover {
                    .o-navigation__lvl1__item__link {
                        color: $navigation__lvl1-text-color-hover;
                        transition: none;
                        background-color: $navigation__lvl2-background;

                        @include respond-gte(large) {
                            background-color: $navigation__lvl1-background-hover;
                        }

                        &--arrow {
                            &::after {
                                @include respond-gt(medium) {
                                    border-color: $navigation__lvl1-arrow-color-hover transparent transparent
                                        transparent;
                                }
                            }
                        }
                    }
                }

                @include respond-lte(medium) {
                    font-size: size(13, $rel-unit);
                }
            }
        }

        &__lvl2__list {
            box-sizing: border-box;
            position: relative;
            min-width: 200px;
            padding: 10px 0;
            margin-top: -25px;
            box-shadow: 0px 4px 4px rgba(86, 86, 86, 0.15);
            background-color: $navigation__lvl2-background;
            max-height: 0;
            transition: max-height 0.3s, visibility 0.3s;
            overflow: hidden;

            .o-navigation__item {
                display: flex;
                &:first-of-type {
                    @include respond-lte(medium) {
                        padding-top: 15px;
                    }
                }
                &:hover {
                    .o-navigation__lvl2__item__link {
                        color: $navigation__lvl2-text-color-hover;

                        @include respond-gt(medium) {
                            background-color: $navigation__lvl2-background-hover;
                        }
                    }
                }

                .o-navigation__lvl2__item__link {
                    padding: 10px;
                    color: $navigation__lvl2-text-color;
                    transition: none;

                    @include respond-lte(medium) {
                        padding: 16px 0 16px 55px;
                    }

                    &:hover {
                        background-color: $navigation__lvl2-background-hover;
                    }
                    &:focus {
                        outline: 2px solid $navigation__lvl1-background;
                    }
                }

                @include respond-lte(medium) {
                    font-size: size(13, $rel-unit);
                }
            }
        }

        &__modal-img {
            padding-left: 20px;
            margin-top: 20px;
        }
    }
}

.o-navigation__trigger {
    margin: 0;
    border: none;
    @include respond(small) {
        position: absolute;
        top: 24px;
        right: 20px;
        width: 24px;
        height: auto;
        cursor: pointer;
        &::before {
            @include font-styles;
            content: icon-char(menu);
            font-size: 20px;
            width: auto;
            position: static;
        }
    }
    &:hover,
    &:focus {
        text-decoration: none;
    }
}

.o-navigation__menu-trigger--mobile {
    text-decoration: none;
    outline: none;
    border: none !important;
    display: block;
    margin-left: 10px;
    background: none;
    color: $color-white;
    z-index: 102;
    @include respond-lte(medium) {
        margin-left: 10px;
        margin-right: -5px;
    }
    &:hover,
    &:focus {
        text-decoration: none;
    }
}
