@import '../../settings';

.#{$ns}radio {
    padding-left: 25px;
    color: $radio-color;
    font-weight: $font-weight-bold;
    input:checked:disabled ~ .o-ci:before {
        border: 5px solid $color-gray6;
    }

    @include custom-input-icon() {
        display: none;
    }
    @include custom-input-checked-background() {
        border: 5px solid $custom-radio-default-checked-background;
        background-color: $color-white;
        transition: border 0.2s ease;
    }

    &--yellow {
        @include custom-input-icon() {
            font-size: size($custom-checkbox-tick-size);
            font-weight: $font-weight-normal;
            color: $custom-checkbox-tick-color;
            content: icon-char($custom-checkbox-tick);
            line-height: 100%;
            position: absolute;
            margin: 0;

            left: size($custom-checkbox-tick-offset-left);
            top: size($custom-checkbox-tick-offset-top);

            @if $custom-checkbox-tick-font-family != '' {
                font-family: $custom-checkbox-tick-font-family;
            }
        }
        @include custom-input-background() {
            border: 1px solid $color-blue5;
            background-color: $color-blue5;
        }
        @include custom-input-checked-background() {
            border: 1px solid $custom-radio-checked-border;
            background-color: $custom-radio-checked-background;
        }
        @include custom-input-checked-icon() {
            color: $color-black;
        }
    }
    &--white-bg {
        @include custom-input-background() {
            border: 1px solid $color-white;
            background-color: $color-white;
        }
    }
}
