.#{$ns}footer {
    &-list {
        padding-top: 40px;
        padding-bottom: 30px;
        text-align: right;
        @include respond-lte(medium) {
            padding-top: 20px;
            padding-bottom: 66px;
        }
        &__item {
            display: inline-block;
            font-weight: $font-weight-bold;
            margin-left: 20px;
            @include respond(small) {
                margin-left: 0;
                margin-right: 20px;
            }
        }
    }
}
