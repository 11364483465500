.#{$ns}form-statement {
    font-weight: $font-weight-bold;
    font-size: 14px;
    margin-bottom: 20px;
    .#{$ns}orb {
        margin-right: 15px;
    }

    &.is-hidden {
        display: none;

        &.active {
            display: table;
        }
    }
}
