.o-off-canvas__content {
    transform: translateX(100%);
    padding: 30px;

    @include respond(small) {
        padding: 20px;
    }
    &.is-open {
        position: fixed !important;
        visibility: visible;
        transform: translateX(0);
    }
}
.o-off-canvas__close {
    position: absolute;
    top: 30px;
    right: 30px;
    color: $color-white;
    @include respond(small) {
        top: 20px;
        right: 20px;
    }
}
.o-off-canvas__title {
    padding-right: 40px;
    @include respond-lte(medium) {
        margin-bottom: 40px;
    }
}
