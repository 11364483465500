.invalid-text {
    width: auto;
    height: 20px;
    padding: 0 5px;
    line-height: 20px;
    display: inline-block;
    position: absolute;
    background-color: $color-error;
    border-radius: 5px;
    color: white;
    font-size: 0.6em;
    text-align: center;
    z-index: 200;

    &--button {
        width: auto;
        height: auto;
        top: -5px;
        left: 30px;
    }
    .arrow {
        &__down {
            position: absolute;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-top: 5px solid $color-error;
            width: 0;
            height: 0;

            &--mobile {
                top: 20px;
            }
            &--right {
                right: 10px;
            }
            &--left {
                left: 10px;
            }
        }
    }
    .cell-invalid & {
        position: absolute;
        top: -9px;
        right: 0;
        font-size: 12px;
        z-index: 10;
    }
}
