.#{$ns}filter-tree {
    $self: &;
    &__dropdown__field {
        max-width: 220px;
    }
    &__name__field {
        max-width: 400px
    }
    &__top {
        display: flex;
        justify-content: space-between;
    }
    &__bottom {
        padding-top: 40px;
        &__buttons {
            padding-top: 10px;
        }
        .o-btn {
            margin: 16px 16px 0 0;
        }
    }
    &__actions {
        display: flex;
        &__item + &__item {
            margin-left: 16px;
        }
    }
    &__info {
        padding: 10px;
        background: $filters-tree-bg;
    }
    &__wrapper {
        max-height: 50vh;
        overflow: auto;
        padding: 3px 0 3px 3px;
        @include scrollbar($body-color, transparent, 4px, 2px);
    }
    &__el {
        display: flex;
        padding: 13px 0;
        &--node {
            margin-left: -20px;
            margin-top: -1px;
            padding-left: 20px;
            border-style: solid;
            border-color: $filters-tree-border;
            border-width: 1px 0;
        }
        &--child {
            border-bottom: 1px solid $filters-tree-border;
            .cm-checkbox {
                padding-left: 10px;
            }
            & + #{$self}__list {
                margin-top: -1px;
            }
        }
        .o-checkbox {
            input:disabled {
                & ~ .o-ci-label {
                    color: $body-color;
                }
            }
        }
    }
    &__list--nodes {
        background: $filters-tree-bg;
        #{$self}__item__button--arrow {
            text-decoration: none;
            padding-left: 20px;
            width: 100%;
            &:hover,
            &:focus {
                text-decoration: underline;
            }
            &,
            &:hover:focus {
                border-style: solid;
                border-color: $filters-tree-border;
                border-width: 1px 0;
            }
        }
    }
    &__list--items {
        padding-left: 30px;
    }
    &__item &__item {
        padding-left: 20px;
    }
    &__item {
        &__button {
            line-height: 1;
            margin-right: 8px;
            color: $filters-tree-color-active;
            &:disabled {
                color: $filters-tree-color-disabled;
            }
            &--ia {
                &,
                &:hover {
                    background: $filters-tree-color-active-bg;
                }
                margin-right: 4px;
            }
            &--arrow {
                display: flex;
                align-items: center;
                font-weight: normal;
                text-decoration: none;
            }
            .g-icon + &__label {
                padding-left: 10px;
            }
        }
        &__actions {
            display: flex;
            align-items: flex-start;
        }
    }
    &__parent {
        display: flex;
        padding: 13px 0;
        border-bottom: 1px solid $filters-tree-border;
    }
    &__parent + &__list {
        padding-left: 50px;
    }
    .g-icon {
        &::before {
            font-size: size($filters-tree-icon-size);
        }
    }
    .o-separator {
        margin-top: 8px;
        margin-bottom: 20px;
    }
    .cm-load-indicator {
        min-height: 4rem;
    }
}
