/*
 * hy.frontend.core
 *
 * NOTE: this file is not subject to copyright transfer
 *
 * UWAGA: plik nie objęty przekazaniem praw autorskich
 *
 *
 */





/*------------------------------------*\
    #HELPERS
\*------------------------------------*/

////
/// HELPERS [U]
/// Utilities classes
/// @group utilities
/// @author marcin.krasowski
////

/// Defines utlity space for margin
$u-space:  60px !default;
/// Defines utlity space for margin
$u-space-medium: calc($u-space / 2) !default;
/// Defines utlity space for margin
$u-space-small: calc($u-space / 3) !default;

/// Defines utlity small space modifier for margin
$u-space-s:  0.5 !default;
/// Defines utlity medium space modifier for margin
$u-space-m:  1.5 !default;
/// Defines utlity large space modifier for margin
$u-space-l:  2 !default;
/// Defines utlity x-large space modifier for margin
$u-space-xl: 3 !default;

$font-weight-medium: 600 !default;


@mixin helper-margin($property, $space) {
    @if $property == 'top' {
        margin-top: $space !important;
    }

    @if $property == 'right' {
        margin-right: $space !important;
    }

    @if $property == 'bottom' {
        margin-bottom: $space !important;
    }

    @if $property == 'left' {
        margin-left: $space !important;
    }
}

@mixin helper-spacing($property, $size: 1) {
    @include helper-margin($property, ($u-space * $size));

    @if ($u-space-medium != $u-space) {
        @include respond-lte(medium) {
            @include helper-margin($property, ($u-space-medium * $size));
        }
    }

    @if ($u-space-small != $u-space and $u-space-small != $u-space-medium) {
        @include respond-lte(small) {
            @include helper-margin($property, ($u-space-small * $size));
        }
    }
}


/*------------------------------------*\
    FLOATS
\*------------------------------------*/

.u-left {
    float: left !important;
    @include respond-lte(small) {
        float: none !important;
    }
}

.u-right {
    float: right !important;
    @include respond-lte(small) {
        float: none !important;
    }
}
.u-right--small {
    @include respond-lte(small) {
        float: right !important;
    }
}

.u-clear-left {
    clear: left !important;
}

.u-clear-right {
    clear: right !important;
}

.u-clear-both {
    clear: both !important;
}





/*------------------------------------*\
    LAYOUTS
\*------------------------------------*/

.u-vertical-top {
    vertical-align: top !important;
}

.u-vertical-bottom {
    vertical-align: bottom !important;
}

.u-vertical-middle {
    vertical-align: middle !important;
}

.u-overflow {
    overflow: hidden !important;
}

.u-overflow-auto {
    overflow: auto !important;
}

.u-position-static {
    position: static !important;
}
.u-position-relative {
    position: relative !important;
}
.u-small-position-static {
    @include respond-lte(small) {
        position: static !important;
    }
}



/*------------------------------------*\
    DISPLAYS
\*------------------------------------*/

.u-hide {
    display: none !important;
}

.u-hide--soft {
    display: none;
}

.u-block {
    display: block !important;
}

.u-inline-block {
    display: inline-block !important;
}

.u-inline {
    display: inline !important;
}
.u-flex {
    display: flex !important;
    display:-webkit-flex !important;
}
.u-inline-flex {
    display: inline-flex !important;
    display:-webkit-inline-flex !important;
}

.u-medium-flex {
    @include respond(medium) {
        display: flex !important;
        display: -webkit-flex !important;
    }
}
.u-gt-small-flex {
    @include respond-gt(small) {
        display: flex !important;
        display: -webkit-flex !important;
    }
}

.u-justify-flex-end {
    justify-content: flex-end;
    -webkit-justify-content: flex-end;
}

.u-justify-flex-start {
    justify-content: flex-start !important;
    -webkit-justify-content: flex-start !important;
}
.u-justify-space-between {
    justify-content: space-between;
    -webkit-justify-content: space-between;
}
.u-justify-center {
    justify-content: center;
    -webkit-justify-content: center;
}

.u-flex-wrap {
    flex-wrap: wrap;
}

.u-flex-direction-column {
    flex-direction: column;
    -webkit-flex-direction: column;
}

.u-flex-direction-row {
    flex-direction: row;
    -webkit-flex-direction: row;
}

.u-align-items-baseline {
    align-items: baseline;
}
.u-align-items-center {
    align-items: center;
}
.u-align-items-end {
    align-items: flex-end;
}
/*------------------------------------*\
    TEXTS AND FONTS
\*------------------------------------*/

.u-text-left {
    text-align: left !important;
}

.u-text-center {
    text-align: center !important;
}

.u-text-right {
    text-align: right !important;
}



.u-small-text-right {
    @include respond-lte(small) {
        text-align: right !important;
    }
}

.u-medium-text-right {
    @include respond(medium) {
        text-align: right !important;
    }
}

.u-large-text-right {
    @include respond-gte(large) {
        text-align: right !important;
    }
}

.u-text-underline {
    text-decoration: underline !important;
}

.u-text-line-through {
    text-decoration: line-through !important;
}

.u-text-no-decoration {
    text-decoration: none !important;
}

.u-text-uppercase {
    text-transform: uppercase !important;
}

.u-text-lowercase {
    text-transform: lowercase !important;
}

.u-text-capitalize {
    text-transform: capitalize !important;
}

.u-text-no-transform {
    text-transform: none !important;
}

.u-text-overflow-ellipsis {
    text-overflow: ellipsis;
}

.u-font-light {
    font-weight: lighter !important;
}

.u-font-medium {
    font-weight: $font-weight-medium !important;
}

.u-font-bold, .u-strong {
    font-weight: bold !important;
}

.u-font-italic {
    font-style: italic !important;
}

.u-font-normal {
    font-weight: normal !important;
}

.u-font-small, small {
    @if($small-scale != undefined) {
        font-size: ms-size($small-scale);
    } @else {
        font-size: size($small-size, $rel-unit);
    }
}

.u-font-large {
    @if($large-scale != undefined) {
        font-size: ms-size($large-scale);
    } @else {
        font-size: size($large-size, $rel-unit);
    }
}
.u-font-base {
    font-size: size($font-size-base, $rel-unit) !important;
}

.u-font-base-rem {
    font-size: size($font-size-base, rem, 16) !important;
}

.u-nowrap {
    white-space: nowrap;
}

.u-break-all {
    word-break: break-all;
}

.u-no-outline {
    outline: none;
}



/*------------------------------------*\
    SPACINGS
\*------------------------------------*/

.u-no-spacing {
    padding: 0 !important;
    margin: 0 !important;
}

@include respond-lte(medium) {
    .u-medium-no-spacing {
        padding: 0 !important;
        margin: 0 !important;
    }
}

@include respond-lte(small) {
    .u-small-no-spacing {
        padding: 0 !important;
        margin: 0 !important;
    }
}

.u-margin-auto{
    margin: 0 auto !important;
}

@include respond(small) {
    .u-small-margin-auto {
        margin: 0 auto !important;
    }
}

.u-spacing-bottom    {@include helper-spacing(bottom);      }
.u-spacing-bottom-s  {@include helper-spacing(bottom, $u-space-s); }
.u-spacing-bottom-l  {@include helper-spacing(bottom, $u-space-l);   }
.u-spacing-bottom-xl {@include helper-spacing(bottom, $u-space-xl);   }

@include respond-lte(medium) {
    .u-medium-spacing-bottom    {@include helper-spacing(bottom);      }
    .u-medium-spacing-bottom-s  {@include helper-spacing(bottom, $u-space-s); }
    .u-medium-spacing-bottom-l  {@include helper-spacing(bottom, $u-space-l);   }
    .u-medium-spacing-bottom-xl {@include helper-spacing(bottom, $u-space-xl);   }
}

@include respond-lte(small) {
    .u-small-spacing-bottom    {@include helper-spacing(bottom);      }
    .u-small-spacing-bottom-s  {@include helper-spacing(bottom, $u-space-s); }
    .u-small-spacing-bottom-l  {@include helper-spacing(bottom, $u-space-l);   }
    .u-small-spacing-bottom-xl {@include helper-spacing(bottom, $u-space-xl);   }
}



.u-spacing-top    {@include helper-spacing(top);      }
.u-spacing-top-s  {@include helper-spacing(top, $u-space-s); }
.u-spacing-top-l  {@include helper-spacing(top, $u-space-l);   }
.u-spacing-top-xl {@include helper-spacing(top, $u-space-xl);   }

@include respond-lte(medium) {
    .u-medium-spacing-top    {@include helper-spacing(top);      }
    .u-medium-spacing-top-s  {@include helper-spacing(top, $u-space-s); }
    .u-medium-spacing-top-l  {@include helper-spacing(top, $u-space-l);   }
    .u-medium-spacing-top-xl {@include helper-spacing(top, $u-space-xl);   }
}

@include respond-lte(small) {
    .u-small-spacing-top    {@include helper-spacing(top);      }
    .u-small-spacing-top-s  {@include helper-spacing(top, $u-space-s); }
    .u-small-spacing-top-l  {@include helper-spacing(top, $u-space-l);   }
    .u-small-spacing-top-xl {@include helper-spacing(top, $u-space-xl);   }
}



.u-spacing-left    {@include helper-spacing(left);      }
.u-spacing-left-s  {@include helper-spacing(left, $u-space-s); }
.u-spacing-left-l  {@include helper-spacing(left, $u-space-l);   }
.u-spacing-left-xl {@include helper-spacing(left, $u-space-xl);   }

@include respond-lte(medium) {
    .u-medium-spacing-left    {@include helper-spacing(left);      }
    .u-medium-spacing-left-s  {@include helper-spacing(left, $u-space-s); }
    .u-medium-spacing-left-l  {@include helper-spacing(left, $u-space-l);   }
    .u-medium-spacing-left-xl {@include helper-spacing(left, $u-space-xl);   }
}

@include respond-lte(small) {
    .u-small-spacing-left    {@include helper-spacing(left);      }
    .u-small-spacing-left-s  {@include helper-spacing(left, $u-space-s); }
    .u-small-spacing-left-l  {@include helper-spacing(left, $u-space-l);   }
    .u-small-spacing-left-xl {@include helper-spacing(left, $u-space-xl);   }
}



.u-spacing-right    {@include helper-spacing(right);      }
.u-spacing-right-s  {@include helper-spacing(right, $u-space-s); }
.u-spacing-right-m  {@include helper-spacing(right, $u-space-m); }
.u-spacing-right-l  {@include helper-spacing(right, $u-space-l);   }
.u-spacing-right-xl {@include helper-spacing(right, $u-space-xl);   }

@include respond-lte(medium) {
    .u-medium-spacing-right    {@include helper-spacing(right);      }
    .u-medium-spacing-right-s  {@include helper-spacing(right, $u-space-s); }
    .u-medium-spacing-right-l  {@include helper-spacing(right, $u-space-l);   }
    .u-medium-spacing-right-xl {@include helper-spacing(right, $u-space-xl);   }
}

@include respond-lte(small) {
    .u-small-spacing-right    {@include helper-spacing(right);      }
    .u-small-spacing-right-s  {@include helper-spacing(right, $u-space-s); }
    .u-small-spacing-right-l  {@include helper-spacing(right, $u-space-l);   }
    .u-small-spacing-right-xl {@include helper-spacing(right, $u-space-xl);   }
}





/*------------------------------------*\
    PADDINGS
\*------------------------------------*/

.u-no-padding {
    padding: 0 !important;
}

.u-no-padding-t {
    padding-top: 0 !important;
}

.u-no-padding-b {
    padding-bottom: 0 !important;
}

.u-no-padding-l {
    padding-left: 0 !important;
}

.u-no-padding-r {
    padding-right: 0 !important;
}

@include respond-lte(small) {
    .u-small-no-padding {
        padding: 0 !important;
    }

    .u-small-no-padding-t {
        padding-top: 0 !important;
    }

    .u-small-no-padding-b {
        padding-bottom: 0 !important;
    }

    .u-small-no-padding-l {
        padding-left: 0 !important;
    }

    .u-small-no-padding-r {
        padding-right: 0 !important;
    }
}

@include respond(medium) {
    .u-medium-no-padding {
        padding: 0 !important;
    }

    .u-medium-no-padding-t {
        padding-top: 0 !important;
    }

    .u-medium-no-padding-b {
        padding-bottom: 0 !important;
    }

    .u-medium-no-padding-l {
        padding-left: 0 !important;
    }

    .u-medium-no-padding-r {
        padding-right: 0 !important;
    }
}

@include respond-gte(large) {
    .u-large-no-padding {
        padding: 0 !important;
    }

    .u-large-no-padding-t {
        padding-top: 0 !important;
    }

    .u-large-no-padding-b {
        padding-bottom: 0 !important;
    }

    .u-large-no-padding-l {
        padding-left: 0 !important;
    }

    .u-large-no-padding-r {
        padding-right: 0 !important;
    }
}




/*------------------------------------*\
    MARGINS
\*------------------------------------*/

.u-no-margin {
    margin: 0 !important;
}

.u-no-margin-t {
    margin-top: 0 !important;
}

.u-no-margin-b {
    margin-bottom: 0 !important;
}

.u-no-margin-l {
    margin-left: 0 !important;
}

.u-no-margin-r {
    margin-right: 0 !important;
}

@include respond-lte(small) {
    .u-small-no-margin {
        margin: 0 !important;
    }

    .u-small-no-margin-t {
        margin-top: 0 !important;
    }

    .u-small-no-margin-b {
        margin-bottom: 0 !important;
    }

    .u-small-no-margin-l {
        margin-left: 0 !important;
    }

    .u-small-no-margin-r {
        margin-right: 0 !important;
    }
}

@include respond(medium) {
    .u-medium-no-margin {
        margin: 0 !important;
    }

    .u-medium-no-margin-t {
        margin-top: 0 !important;
    }

    .u-medium-no-margin-b {
        margin-bottom: 0 !important;
    }

    .u-medium-no-margin-l {
        margin-left: 0 !important;
    }

    .u-medium-no-margin-r {
        margin-right: 0 !important;
    }
}

@include respond-gte(large) {
    .u-large-no-margin {
        margin: 0 !important;
    }

    .u-large-no-margin-t {
        margin-top: 0 !important;
    }

    .u-large-no-margin-b {
        margin-bottom: 0 !important;
    }

    .u-large-no-margin-l {
        margin-left: 0 !important;
    }

    .u-large-no-margin-r {
        margin-right: 0 !important;
    }
}




/*------------------------------------*\
    BORDERS
\*------------------------------------*/

.u-no-border {
    border: none !important;
}

.u-no-border-t {
    border-top: none !important;
}

.u-no-border-b {
    border-bottom: none !important;
}

.u-no-border-l {
    border-left: none !important;
}

.u-no-border-r {
    border-right: none !important;
}





/*------------------------------------*\
    CURSORS
\*------------------------------------*/

.u-cursor-pointer {
    cursor: pointer !important;
}

.u-cursor-text {
    cursor: text !important;
}





/*------------------------------------*\
    WIDTHS
\*------------------------------------*/

.u-w-auto {
    width: auto !important;
}

.u-w-100 {
    width: 100% !important;
}

.u-w-max-100 {
    max-width: 100% !important;
}

.u-w-small-100 {
    @include respond(small) {
        width: 100% !important;
    }
}

.u-w-medium-100 {
    @include respond(medium) {
        width: 100% !important;
    }
}

.u-w-large-100 {
    @include respond-gte(large) {
        width: 100% !important;
    }
}




/*------------------------------------*\
    ANIMATE
\*------------------------------------*/

.u-animate-height {
    @include transition-standard-fast(height, min-height);
}





/*------------------------------------*\
    JAVASCRIPT
\*------------------------------------*/

.has-js {
    .js-hide {
        display: none;
    }

    .js-acc-hide {
        @include acc-hide;
    }
}

.no-js {
    .no-js-hide {
        display: none;
    }

    .no-js-acc-hide {
        @include acc-hide;
    }
}
