.#{$ns}autocomplete {
    position: relative;
    &__list-container {
        display: none;
        border: 1px solid $color-gray6;
        border-radius: 15px;
        padding-top: 0;
        padding-bottom: 0;
        position: absolute;
        z-index: 1;
        box-sizing: border-box;
        max-height: 200px;
        width: auto;
        min-width: 100%;
        overflow: hidden;
    }
    &__list {
        overflow-y: auto;
        max-height: 164px;
        background-color: white;
        width: 100%;
    }
    &__error {
        margin-top: 2px;
        color: $color-red;
        position: absolute;
    }
    &.open {
        input,
        input:focus,
        input:hover {
            background-color: #e8e8e8;
            border-color: #e8e8e8;
            outline: 0;
            .#{$ns}input__group & {
                background-color: transparent;
                border-color: transparent;
            }
        }
        .#{$ns}autocomplete__list-container {
            display: block;
            background-color: white;
        }
    }
    li {
        padding: 5px 10px;
        font-size: 14px;
        font-weight: $font-weight-bold;
        white-space: nowrap;
        &.active {
            background-color: $dropdown-hover-tag-background-color;
            color: white;
        }
        &:hover {
            cursor: pointer;
            background-color: $dropdown-hover-tag-background-color;
            color: white;
        }
        &.withHtml {
            font-weight: $font-weight-normal;
            span {
                font-weight: $font-weight-bold;
                &.removed {
                    color: $color-gray3;
                }
            }
        }
    }
    &__input {
        border: 1px solid #ccc;
        height: 30px;
        z-index: 2;
        position: relative;
        white-space: nowrap;
        text-overflow: ellipsis;
        display: inline-block !important;
        max-width: 80% !important;
        &:focus {
            outline: 0;
        }
    }
    &__with-button {
        max-width: 76% !important;
    }
    .o-btn {
        display: inline-block;
        vertical-align: top;
        float: right;
        position: absolute;
        right: 0;
        &:not(&--link):not(.cm-btn--secondary):not(:disabled):not(.cm-btn--third) {
            color: $body-color;
            &:hover {
                color: $body-color;
            }
        }
    }
    input::-ms-clear {
        display: none;
    }
}
