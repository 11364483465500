.#{$ns}upload {
    &__btn-wrapper {
        position: relative;
        overflow: hidden;
        display: inline-block;
        padding: 10px 1px;
        button {
            cursor: pointer;
            background-color: $icons-with-background-background-color;
            color: $icons-with-background-color;
        }
        input[type='file'] {
            font-size: 0px;
            height: 50px;
            width: 50px;
            position: absolute;
            left: 0;
            top: 0;
            opacity: 0;
        }
        label {
            padding-left: 15px;
            transform: translateY(2px);
        }
    }
    &__list {
        li:not(:first-of-type) {
            border-top: 1px solid #ccc;
        }
        li:only-child:not(.cm-upload__list--review) {
            border-bottom: 1px solid #ccc;
        }
    }
    &__file {
        display: flex;
        justify-content: space-between;
        padding: 15px 0;
    }
    &__actions {
        display: inline-block;
        &-button:hover {
            cursor: pointer;
        }
    }
    &__text {
        width: 60%;
        line-break: anywhere;
        @include respond(medium) {
            width: 54%;
        }
        @include respond(small) {
            max-width: 75%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
    &__error {
        padding-top: 5px;
        @include respond-gt(small) {
            margin-bottom: -5px;
        }
    }
    &__progress {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 5px;
        margin-top: -5px;
    }
    &__progress-bar {
        display: inline-block;
        height: 10px;
        width: 100%;
        background-color: #e8e8e8;
        border-radius: 10px;
        &-fill {
            height: 100%;
            box-sizing: border-box;
            width: 0;
            background: #ffd30f;
            border: 1px solid #ffd30f;
            border-radius: 10px;
            display: flex;
            align-items: center;
            transition: width 0.25s;
        }
    }
    &__manual {
        display: flex;
        align-items: center;
    }
}
