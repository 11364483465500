/*
 * hy.frontend.core
 * 
 * NOTE: this file is not subject to copyright transfer
 * 
 * UWAGA: plik nie objęty przekazaniem praw autorskich
 * 
 * 
 */





/*------------------------------------*\
    #SEPARATORS
\*------------------------------------*/

////
/// @group separators
/// @author sebastian.nowak
////

/// defines separator size
$o-separator-size: $separator-size !default;
/// defines separator spacing
$o-separator-spacing: $separator-spacing !default;
/// defines separator default background color
$o-separator-color: $separator-color !default;
/// defines separator default width
$o-separator-width: 100% !default;

/// defines S separator size
$o-separator-size--xs: $separator-size * 0.25 !default;
/// defines S separator spacing
$o-separator-spacing--xs: ($font-size-base * 0.25) 0 !default;

/// defines S separator size
$o-separator-size--s: $separator-size * 0.5 !default;
/// defines S separator spacing
$o-separator-spacing--s: ($font-size-base * 0.5) 0 !default;

/// defines M separator size
$o-separator-size--m: $separator-size !default;
/// defines M separator spacing
$o-separator-spacing--m: $separator-spacing !default;

/// defines L separator size
$o-separator-size--l: $separator-size * 2 !default;
/// defines L separator spacing
$o-separator-spacing--l: ($font-size-base * 2) 0 !default;

/// defines L separator size
$o-separator-size--xl: $separator-size * 4 !default;
/// defines L separator spacing
$o-separator-spacing--xl: ($font-size-base * 4) 0 !default;

// separator spanning whole width of container
$o-separator-expanded-margin: $page-padding !default;
$o-separator-expanded-margin-medium: $page-padding-medium !default;
$o-separator-expanded-margin-small: $page-padding-small !default;

.o-separator {
    border: none;
    border-bottom: size($o-separator-size) solid $o-separator-color;
    width: $o-separator-width;
    margin: spacing($o-separator-spacing);
    
    
    
    &--xs {
        border-width: size($o-separator-size--xs);
        margin: spacing($o-separator-spacing--xs);
    }
    
    &--s {
        border-width: size($o-separator-size--s);
        margin: spacing($o-separator-spacing--s);
    }

    &--m {
        border-width: size($o-separator-size--m);
        margin: spacing($o-separator-spacing--m);
    }

    &--l {
        border-width: size($o-separator-size--l);
        margin: spacing($o-separator-spacing--l);
    }

    &--xl {
        border-width: size($o-separator-size--xl);
        margin: spacing($o-separator-spacing--xl);
    }



    &.o-separator--expanded {
        width: auto;
        margin-left: get-value-by-position($o-separator-expanded-margin, 2) * -1;
        margin-right: get-value-by-position($o-separator-expanded-margin, 4) * -1;

        @include respond(medium) {
            margin-left: get-value-by-position($o-separator-expanded-margin-medium, 2) * -1;
            margin-right: get-value-by-position($o-separator-expanded-margin-medium, 4) * -1;
        }
        @include respond(small) {
            margin-left: get-value-by-position($o-separator-expanded-margin-small, 2) * -1;
            margin-right: get-value-by-position($o-separator-expanded-margin-small, 4) * -1;
        }
    }

    &.o-separator--full-width {
        &:before {
            content: '';
            background: $o-separator-color;
            display: block;
            position: absolute;
            right: 0;
            width: 100%;
            height: size($o-separator-size);
        }
    }
    
    &--xs {
        &.o-separator--full-width:before {
            height: size($o-separator-size--xs);
        }
    }
    
    &--s {
        &.o-separator--full-width:before {
            height: size($o-separator-size--s);
        }
    }

    &--m {
        &.o-separator--full-width:before {
            height: size($o-separator-size--m);
        }
    }

    &--l {
        &.o-separator--full-width:before {
            height: size($o-separator-size--l);
        }
    }

    &--xl {
        &.o-separator--full-width:before {
            height: size($o-separator-size--xl);
        }
    }
}
